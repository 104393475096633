import { ContractMeta } from "../shared/model/IContract";
import { IContract } from "./../shared/model/IContract";
import { IDownloadLast } from "../shared/model/IDownloadLast";
import { ISystemFilter } from "../shared/types/ISystemFilter";
import { Repository } from "./core/Repository";
import { SessionRepository } from "./session/SessionRepository";

export class ContractApi extends Repository<IContract> {
  /**
   * This class is responsible for handling all {@link IContract} specific REST requests.
   */
  private static ContractApiDefault = new ContractApi();

  private constructor() {
    super(ContractMeta.path);
  }

  findBySystemFilter(systemFilter: ISystemFilter): Promise<IDownloadLast[]> {
    return this.post(`${this.url}/system-filter`, systemFilter);
  }

  static create(contractId: string | undefined): ContractApi {
    SessionRepository.setContractId(contractId);
    return ContractApi.ContractApiDefault;
  }

  findProjectIdById(contractId: string): Promise<string> {
    return this.get(`${this.url}/${contractId}/project-id`);
  }
}
