import { ILandingPageInfoProps } from "./ILandingPageInfoProps";
import { Icon } from "@snpjs/core";
import styles from "./LandingPageInfo.module.scss";

export const LandingPageInfo: React.FC<ILandingPageInfoProps> = (props) => {
  return (
    <div className={styles.landingPageInfo}>
      <div className={styles.title}>
        <h2 className={styles.header}>{props.title}</h2>
        <Icon className={styles.icon} icon={props.icon} size={40} />
      </div>
      {props.subTitle && (
        <div className={styles.subTitle}>{props.subTitle}</div>
      )}
      <div>{props.content}</div>
    </div>
  );
};
