import { EditableSection } from "../../../components/editableSection/EditableSection";
import { ISystem } from "../../../shared/model/ISystem";
import { ISystemSectionProps } from "./ISystemSectionProps";
import { Switch } from "@snpjs/core";
import { SystemApi } from "../../../api/SystemApi";
import { SystemCard } from "../systemCard/SystemCard";
import { SystemDummy } from "../../../model/SystemDummy";
import { checkNotNull } from "../../../utils/checkNotNull";
import { texts } from "../../../i18n/texts";
import { useSystemSectionViewModel } from "./useSystemSectionViewModel";
import { useToggle } from "../../../hooks/useToggle";
import useTranslation from "../../../hooks/useTranslation";
// import { createColumnHelper, Table, useTable } from "@snpjs/table";
// import * as React from "react";
/**
 * This component is responsible for displaying the systems with it details and providing components to add new systems.
 */
export const SystemSection: React.FC<ISystemSectionProps> = (props) => {
  const [showDownloadHistory, toggleShowDownloadHistory] = useToggle(false);
  const viewModel = useSystemSectionViewModel(props);
  const { t } = useTranslation();

  // const columnHelper = createColumnHelper<ISystem>();
  // const SystemTable = Table.ofType<ISystem>();
  // const tableData = props.systems
  // const columns = React.useMemo(
  //   () =>
  //     [
  //       columnHelper.expansion("SYSTEM_ID", {
  //         id: "firstName",
  //       }),
  //       columnHelper.accessor(row => row.SYSTEM_CLIENT, {
  //         cell: info => <i>{info.getValue()}</i>,
  //         header: () => <span>Last Name</span>,
  //         id: "lastName",
  //       }),
  //       columnHelper.accessor("INSTNUMBER", {
  //         cell: info => info.renderValue(),
  //         header: () => "Installation Number",
  //         id: "installationNumber",
  //       }),
  //       columnHelper.accessor("DESCRIPTION", {
  //         header: () => "Description",
  //         id: "description",
  //       }),
  //       columnHelper.accessor("SYSTEM_ROLES", {
  //          size: -1, // funktioniert nicht richtig. Es lässt sich nicht mehr rezisen + es wird der Platz
  //           // für den Rest der Seite verwendet und nicht so viel Platz wie für den Content benötigt wird
  //         cell: info => {
  //           // const transformedRoles = info.getValue().map((role) => {
  //           //   return role.charAt(0) + role.slice(1).toLowerCase();
  //           // });
  //           return info.getValue().join(", ")},
  //         header: () => <span>System Roles</span>,
  //         id: "systemRoles",
  //       }),
  //       // columnHelper.accessor("DESCRIPTION", {
  //       //   header: () => "Description",
  //       //   id: "desc4ription",
  //       // }),
  //       // columnHelper.accessor("DESCRIPTION", {
  //       //   header: () => "Description",
  //       //   id: "descr2iption",
  //       // }),
  //       // columnHelper.display()
  //       // normal tabelle Füllen und dann beim Bearbeiten alles mit die Tabelle kleiner machen und mit Dropdowns ersetzen
  //     ],
  //   [],
  // );

  // const table = useTable({
  //   columns,
  //   enableGlobalFilter: false,
    
  //   data: tableData,
  //   // Example setting initial state
  //   initialState: {
          
  //     pagination: {
  //         pageSize: 5,
  //     },
  //   },
  // });

    // Use this hook to make the table fully controlled
    // const [tableState, setTableState] = useTableState(table);

    // return <SystemTable table={table} />;


  return (
    // <div>
    //   <SystemTable
    //     table={table}
    //   />
    <EditableSection<ISystem>
      createCard={(system, onCopy, onDelete, onSave, _, number) => {        
        return (
        <SystemCard
          collapseSignal={props.collapseSignal}
          onCollapseSingleSystem={props.onCollapseSingleSystem}
          containsSystemVersionInconsistency={
            props.containsSystemVersionInconsistency
          }
          contract={checkNotNull(props.contract)}
          expandSignal={props.expandSignal}
          onExpandSingleSystem={props.onExpandSingleSystem}
          number={number}
          onCopy={onCopy}
          onDelete={onDelete}
          onDownloadSoftware={viewModel.onDownloadSoftware}
          onDownloadLicense={viewModel.onDownloadLicense}
          onDownloadEmergencyLicense={viewModel.onDownloadEmergencyLicense}
          onSave={onSave}
          product={props.product}
          showDownloadHistory={showDownloadHistory}
          system={system}
          systemLandscapeConflicts={props.systemLandscapeConflicts}
          systemRoles={props.systemRoles}
          systemRequirementTypes={props.systemRequirementTypes}
        />
      )}}
      dummyClassType={SystemDummy}
      labelAddButton={t(texts.systemSection.labelAddButton)}
      loadObjects={() => new Promise((resolve, _) => resolve(props.systems))}
      onAdd={viewModel.onAddSystem}
      repository={SystemApi}
      searchSignal={props.searchSignal}
      toolbarContent={
        <Switch
          checked={showDownloadHistory}
          label={t(texts.systemSection.captionDownloadHistory)}
          onChange={() => toggleShowDownloadHistory()}
        />
      }
      onSaveDone={props.onSystemSaveDone}
    />
    // </div>
  );
};
