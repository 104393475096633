import { useNavigate } from "react-router-dom";
import { PublicPage } from "../components/pages/publicPage/PublicPage";
import { Login } from "../features/login/Login";
import { Routes } from "../routes/Routes";
import styles from "./LoginPage.module.scss";

/**
 * This page provides the possibility to login a user.
 */
export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <PublicPage iconName="sign-in" showPageInfo={false}>
      <div className={styles.login}>
        <Login
          onLogin={(contract?: string, product?: string) => {
            if (contract && product) {
              navigate(
                Routes.contractProduct.toPath({
                  contractId: contract,
                  productId: product,
                })
              );
            } else {
              navigate(Routes.contracts.toPath());
            }
          }}
        />
      </div>
    </PublicPage>
  );
};
