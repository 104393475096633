import { IPageInfoProps } from "./IPageInfoProps";
import {  Button, Icon } from "@snpjs/core";
import styles from "./PageInfo.module.scss";
import { useUserProfile } from "../../../hooks/useUserProfile";

export const PageInfo: React.FC<IPageInfoProps> = (props) => {
  const userProfile = useUserProfile();
  const collapsePageInfo = !(
    userProfile.value === undefined ||
    userProfile.value.collapsePageInfo === undefined ||
    userProfile.value.collapsePageInfo === false
  );

  const onToggleDisplayPageInfo = () =>
    userProfile.setValue((previous) => {
      return {
        ...previous,
        collapsePageInfo: !previous.collapsePageInfo,
      };
    });
  return (
    <div className={styles.pageInfo}>
      <div>
        {collapsePageInfo ? (
          <div className={styles.collapseIcon}>
            <Button onClick={onToggleDisplayPageInfo} icon="book-open-text" intent="primary">
            </Button>
          </div>
        ) : (
          <Icon
            className={styles.collapseIcon}
            icon={"caret-right"}
            onClick={onToggleDisplayPageInfo}
          />        
        )}
      </div>
      {!collapsePageInfo && (
        <div className={styles.infoArea}>
          <div className={styles.infoAreaDetails}>
            {props.infoArea && props.infoArea}
          </div>
        </div>
      )}
    </div>
  );
};
