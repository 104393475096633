import { Button, Tooltip } from "@snpjs/core";

import { IDialogFooterProps } from "./IDialogFooterProps";
import { LoadingSpinnerButton } from "../loadingSpinnerButton/LoadingSpinnerButton";
import { texts } from "../../i18n/texts";
import useTranslation from "../../hooks/useTranslation";

/**
 * This component represents a default dialog footer with okay and cancel button
 */
export const DialogFooter: React.FC<IDialogFooterProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
    {props.tooltipConfirmButton ? (
      <Tooltip content={props.tooltipConfirmButton}>
        <LoadingSpinnerButton
          disabled={props.disableConfirmButton}
          onClick={props.onOkay}
          intent="success"
          showSpinner={props.isConfirming ?? false}
        >
          {t(texts.general.okay)}
        </LoadingSpinnerButton>
      </Tooltip>
    ) : (
      <LoadingSpinnerButton
        disabled={props.disableConfirmButton}
        onClick={props.onOkay}
        intent="success"
        showSpinner={props.isConfirming ?? false}
      >
        {t(texts.general.okay)}
      </LoadingSpinnerButton>
    )}
      <Button onClick={props.onCancel} icon="x" intent="danger">
        {t(texts.general.cancel)}
      </Button>
    </>
  );
};
