import { IRecentContracts } from "../model/IRecentContracts";
import { IValue } from "../types/IValue";

import { useLocalStorage } from "./useLocalStorage";

/**
 * This custom hook is responsible for reading and writing recently opened contracts to the local storage.
 */
export const useRecentContractsStorage = (): IValue<IRecentContracts> => {
  const [value, setValue] = useLocalStorage<IRecentContracts>(
    "swpo.recent-contracts",
    {
      contractIds: [],
    }
  );

  return { value, setValue };
};
