import { LanguageType } from "../types/LanguageType";
import { NotSupportedError } from "../errors/NotSupportedError";

/**
 * @param date is the date of format YYYY-MM-DD
 */
export const formatDate = (
  date: string,
  languageType: LanguageType = LanguageType.EN
): string => {
  let locales: Intl.LocalesArgument;
  switch (languageType) {
    case LanguageType.EN:
      locales = "en-US";
      break;
    case LanguageType.DE:
      locales = "de-DE";
      break;
    default:
      throw new NotSupportedError(
        `Error while getting language for date format. Language ${languageType} is not supported.`
      );
  }

  return new Date(date).toLocaleString(locales, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  });
};
