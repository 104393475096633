import { ContractAuthRoleProvider } from "../contractAuthority/ContractAuthRoleProvider";
import { IContractBasedPageProps } from "./IContractBasedPageProps";
import { ProtectedPage } from "../../../components/pages/protectedPage/ProtectedPage";

/**
 * This component is responsible to wrap content,
 * which depends on a contract and therefore implicit from authentication (user must be logged in) and specific contract authorities (authority checks by useAuth).
 */
export const ContractBasedPage: React.FC<IContractBasedPageProps> = (props) => {
  const { children, ...propsWithoutChildren } = props;
  return (
    <ProtectedPage {...propsWithoutChildren}>
      <ContractAuthRoleProvider>{children}</ContractAuthRoleProvider>
    </ProtectedPage>
  );
};
