import "./index.scss";

import { FocusStyleManager, SNPKitProvider } from "@snpjs/core";

import App from "./App";
import ReactDOM from "react-dom/client";

FocusStyleManager.onlyShowFocusOnTabs();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <SNPKitProvider>
    <App />
  </SNPKitProvider>
);
