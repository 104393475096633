import { PathSegment } from "../../components/breadcrumbs/PathSegment";
import { Routes } from "../../routes/Routes";

/**
 * This is a list of all filtered path segments, which should not be part of the breadcrumbs.
 * Add a {@link Routes} or a more explicit route such as e.g. "/contracts/:id"
 */
export const FilteredBreadcrumbPathSegments: PathSegment[] = [
  { rootPath: Routes.contracts.toPath() },
  { rootPath: Routes.excerptSystems.toPath(), param: "systemId" },
];
