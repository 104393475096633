import { IHavePath } from "../types/IHavePath";
import { IHaveSystemActionLog } from "../types/IHaveSystemActionLog";
import { IVersion } from "../types/IVersion";

/**
 * An implementation of this interface represents an alternative download version for a system.
 */
export interface ISystemDownloadVersion extends IHaveSystemActionLog, IVersion {
  SYSTEM_OBJECT_ID: string;
}

export const SystemDownloadVersionMeta: IHavePath = {
  path: "/download-version",
};

export const SystemLastDownloadVersionMeta: IHavePath = {
  path: "/last-download-version",
};
