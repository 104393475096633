import { AuthConfig } from "../types/AuthConfig";

/**
 * Function that is required to get the concrete {@link AuthConfig}, which contains all actions and its assigned roles.
 * To get all concrete properties and its roles, we define type {@link R} which extends {@link AuthConfig} and contains the concrete properties inferred from {@link config}.
 *
 * The given {@link config} is directly returned.
 */
export const configAuth = <R extends AuthConfig>(config: R): R => {
  return config;
};
