import { Label, Switch } from "@snpjs/core";

import { IVersionSelectionProps } from "./IVersionSelectionProps";
import { LabeledSelect } from "../../components/labeledSelect/LabeledSelect";
import styles from "./VersionSelection.module.scss";
import { texts } from "../../i18n/texts";
import useTranslation from "../../hooks/useTranslation";
import { useVersionSelectionViewModel } from "./useVersionSelectionViewModel";

/**
 * This component is responsible for selecting a release, service pack and hotfix
 */
export const VersionSelection: React.FC<IVersionSelectionProps> = (props) => {
  const { t } = useTranslation();
  const viewModel = useVersionSelectionViewModel(props);
  return (
    <div>
      <div className={styles.versionSelection}>
        <div className={styles.isLTS}>
          <Label className={styles.ltsLabel}>
            {t(texts.general.system.isLTS)}
          </Label>
          <Switch
            disabled={viewModel.isOthersReleaseSelected}
            checked={viewModel.isLTS}
            onChange={viewModel.onIsLTSSelected}
          />
        </div>
        <LabeledSelect
          className={styles.labeledSelect}
          disabled={props.displayMode}
          errorMessage={viewModel.releaseErrorMessage}
          items={viewModel.getReleases()}
          label={t(texts.general.system.release)}
          onSelect={viewModel.onReleaseSelected}
          selected={props.release}
          renderOptionTitle={viewModel.getTitleByRelease}
        />
        <LabeledSelect
          className={styles.labeledSelect}
          disabled={props.displayMode || viewModel.isOthersReleaseSelected}
          errorMessage={viewModel.servicePackErrorMessage}
          items={viewModel.selectableServicePacks}
          label={t(texts.general.system.servicePack)}
          onSelect={viewModel.onServicePackSelected}
          selected={props.servicePack}
          renderOptionTitle={(servicePack) =>
            servicePack.SERVICE_PACK.toString()
          }
        />
        {props.displayHotfix === true && (
          <LabeledSelect
            className={styles.labeledSelect}
            disabled={props.displayMode || viewModel.isOthersReleaseSelected}
            errorMessage={viewModel.hotfixErrorMessage}
            items={viewModel.selectableHotfixes}
            label={t(texts.general.system.hotfix)}
            onSelect={viewModel.onHotfixSelected}
            selected={props.hotfix}
            renderOptionTitle={(hotfix) => hotfix.HOTFIX.toString()}
          />
        )}
      </div>
    </div>
  );
};
