import { IContractSearchInputProps } from "./IContractSearchInputProps";
import { InputGroup } from "@snpjs/core";

export const ContractSearchInput: React.FC<IContractSearchInputProps> = (
  props
) => {
  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.setQuery(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onEnter?.();
    }
  };

  return (
    <InputGroup
      autoFocus
      onChange={onQueryChange}
      onKeyDown={onKeyDown}
      type="text"
      value={props.query}
    />
  );
};
