import { IValidator } from "../types/IValidator";
import { ValidationHandler } from "../types/ValidationHandler";

import { useCallback } from "react";
import { useMemo } from "react";

/**
 * This custom hook is responsible for providing a validator of type {@link IValidator}.
 * The validator calls handler {@link validate} for validation.
 */
export const useValidatorProvider = (
  validate: ValidationHandler
): IValidator => {
  const onValidate = useCallback(() => {
    validate();
  }, [validate]);

  /**
   * Create validator instance and cache it to not trigger rerendering all the time in case the object reference change
   */
  const validator: IValidator = useMemo(() => {
    return {
      validate: onValidate,
    };
  }, [onValidate]);

  return validator;
};
