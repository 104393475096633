import { ReactComponent as CircleIcon } from "../../../assets/circle.svg";
import { ICarouselFooterProps } from "./ICarouselFooterProps";
import styles from "./CarouselFooter.module.scss";

export const CarouselFooter: React.FC<ICarouselFooterProps> = (props) => {
  const getItems = () => {
    if (Array.isArray(props.children)) {
      return props.children.map((_, index) => (
        <CircleIcon
          className={
            props.selectedIndex === index ? styles.filledCircle : styles.circle
          }
          onClick={() => props.onSelect?.(index)}
        />
      ));
    } else {
      return (
        <CircleIcon
          className={styles.filledCircle}
          onClick={() => props.onSelect?.(0)}
        />
      );
    }
  };
  return <div className={styles.footer}>{getItems()}</div>;
};
