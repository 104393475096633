import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { ICommand } from "../../../types/ICommand";
import { IEntityDetails } from "../../../shared/types/IEntityDetails";
import { ISystemActionLog } from "../../../shared/model/ISystemActionLog";
import { IUseDeactivateSystemCommandProps } from "./IUseDeactivateSystemCommandProps";
import { SystemActionLogApi } from "../../../api/SystemActionLogApi";
import { SystemActionLogDialog } from "../systemActionLogDialog/SystemActionLogDialog";
import { SystemActionLogType } from "../../../shared/model/SystemActionLogType";
import { SystemApi } from "../../../api/SystemApi";
import { SystemDummy } from "../../../model/SystemDummy";
import { checkNotNull } from "../../../utils/checkNotNull";
import { texts } from "../../../i18n/texts";
import { toABAPDate } from "../../../utils/toABAPDate";
import { toABAPTime } from "../../../utils/toABAPTime";
import { useRequest } from "../../../hooks/useRequest";
import { useSession } from "../../../hooks/useSession";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";

export const useDeactivateSystemCommand = (
  props: IUseDeactivateSystemCommandProps
): ICommand => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const request = useRequest();
  const session = useSession();

  const createSystemActionLog = (
    reason: string
  ): IEntityDetails<ISystemActionLog> => {
    const now = new Date();
    return {
      SYSTEM_OBJECT_ID: props.system.OBJECT_ID,
      SYS_ACT_LOG_REASON: reason,
      SYS_ACT_LOG_TYPE: SystemActionLogType.DEACTIVATE_SYSTEM,
      USER_ID: checkNotNull(session).USER.USER_ID,
      CREATION_DATE: toABAPDate(now),
      CREATION_TIME: toABAPTime(now),
    };
  };

  const onCancel = () => {
    setShow(false);
  };

  const onOkay = async (reason: string) => {
    await request.send(async () => {
      const updatedSystem = SystemDummy.clone(props.system);
      updatedSystem.IS_DEACTIVATED = ABAPBoolean.true;
      const changedSystem = await SystemApi.update(updatedSystem);
      updatedSystem.CONFLICTS = changedSystem.CONFLICTS;
      await SystemActionLogApi.add(createSystemActionLog(reason));
      props.setSystem?.(updatedSystem);
      setShow(false);
      props.onOkay?.();
    });
  };

  const view = show && (
    <SystemActionLogDialog
      isOpen={show}
      isReasonRequired={true}
      onCancel={onCancel}
      onOkay={onOkay}
      system={props.system}
      subTitle={t(texts.systemDeactivateCommand.subTitle)}
      title={t(texts.systemDeactivateCommand.title, {
        SID: props.system.SYSTEM_ID,
        client: props.system.SYSTEM_CLIENT,
        installNumber: props.system.INSTNUMBER,
      })}
    />
  );

  const execute = () => {
    setShow(true);
  };

  return {
    execute,
    view,
  };
};
