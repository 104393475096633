import { Button, Card, Icon, Section, SectionCard } from "@snpjs/core";
import { Link, useNavigate } from "react-router-dom";

import { ContractResponsibles } from "../contractResponsibles/ContractResponsibles";
import { IContractCardProps } from "./IContractCardProps";
import { ProductBundle } from "../productBundle/ProductBundle";
import { ProductExpirationMessages } from "../../productExpiration/productExpirationMessages/ProductExpirationMessages";
import { Routes } from "../../../routes/Routes";
import styles from "./ContractCard.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";

/**
 * This component is responsible for displaying a specific contract.
 */
export const ContractCard: React.FC<IContractCardProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const contractDetails = (title: string, data: string) => (
    <>
      <div className={styles.contractDetailsTitle}>{title}</div>
      <div>{data}</div>
    </>
  );

  const contractDetailsElement = (title: string, data: JSX.Element) => (
    <>
      <div className={styles.contractDetailsTitle}>{title}</div>
      <div>{data}</div>
    </>
  );

  const contractResponsiblesDetails = () => {
    return (
      <>
        <div className={styles.contractDetailsTitle}>
          {t(texts.contracts.responsible)}
        </div>
        <div>
          <ContractResponsibles
            responsibles={props.contract.EMAIL_RESPONSIBLE_PERSONS}
          />
        </div>
      </>
    );
  };

  const removeLeadingZeros = (value: string) => value.replace(/^0+/, "");
  const buttonUsers = (
    <Button
      aria-label="button-user-management"
      onClick={() =>
        navigate(
          Routes.contractUserAuthRole.toPath({
            contractId: props.contract.OBJECT_ID,
          })
        )
      }
      text={t(texts.contracts.userManagement.buttonCaption)}
      icon="users"
    />
  );

  const getMainClassName = () => {
    if (props.singleView) {
      return styles.singleView;
    }
    return styles.multiView;
  };

  return (
    <>
      <div className={getMainClassName()}>
        <Section
          title={props.contract.CUSTOMER_NAME}
          rightElement={buttonUsers}
          icon={<Icon icon="building" />}
        >
          <SectionCard padded={false}>
            <Card compact elevation={2}>
              <div className={styles.contractDetailsList}>
                {contractDetails(
                  t(texts.contracts.customerId),
                  removeLeadingZeros(props.contract.CUSTOMER_ID)
                )}
                {(() => {
                  if (props.hideContractLink) {
                    return contractDetails(
                      t(texts.contracts.contractId),
                      props.contract.PROJECT_ID
                    );
                  } else {
                    return contractDetailsElement(
                      t(texts.contracts.contractId),
                      <Link
                        to={Routes.contract.toPath({
                          contractId: props.contract.OBJECT_ID,
                        })}
                      >
                        {props.contract.PROJECT_ID}
                      </Link>
                    );
                  }
                })()}

                {contractDetails(
                  t(texts.contracts.contractText),
                  props.contract.DESCRIPTION === ""
                    ? "-"
                    : props.contract.DESCRIPTION
                )}

                {contractResponsiblesDetails()}
              </div>
              <ProductExpirationMessages
                products={props.contract.PRODUCTS ?? []}
              />
              <ProductBundle
                contractId={props.contract.OBJECT_ID}
                highlightedProductIds={props.highlightedProductIds}
                productBundles={props.contract.PRODUCT_BUNDLES}
              />
            </Card>
          </SectionCard>
        </Section>
      </div>
    </>
  );
};
