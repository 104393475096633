import { IHavePath } from "../types/IHavePath";

/**
 * An implementation of this interface contains the properties for requesting to share a contract with a user.
 */
export interface IShareContractRequest {
  EMAIL: string;
}

export const ShareContractRequestMeta: IHavePath = { path: "/share" };
