import { LanguageType } from "../../../types/LanguageType";
import { Menu } from "@snpjs/core";
import { UserGuidMenuItem } from "../userGuideMenuItem/UserGuideMenuItem";

/**
 * User Guide Menu.
 */
export const UserGuideMenu: React.FC = () => {
  return (
    <>
      <Menu>
        <UserGuidMenuItem languageType={LanguageType.DE} />
        <UserGuidMenuItem languageType={LanguageType.EN} />
      </Menu>
    </>
  );
};
