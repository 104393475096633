import { InsufficientSystemRequirementsError } from "./InsufficientSystemRequirementsError";
import { InvalidAccessCodeError } from "./InvalidAccessCodeError";
import { LicenseGenerationError } from "./LicenseGenerationError";
import { MaxLoginFailError } from "./MaxLoginFailError";
import { MissingAuthError } from "./MissingAuthorizationError";
import { MissingDocuLinkError } from "./MissingDocuLinkError";
import { MissingInstallGuideError } from "./MissingInstallGuideError";
import { PathNotFoundError } from "./PathNotFoundError";
import { ProductExpiredError } from "./ProductExpiredError";
import { ProductInvalidExpiryDateError } from "./ProductInvalidExpiryDateError";
import { RESTErrorRegistryType } from "../types/RESTErrorRegistryType";
import { SystemConflictError } from "./SystemConflictError";
import { SystemDuplicateError } from "./SystemDuplicateError";
import { SystemLandscapeInUseError } from "./SystemLandscapeInUseError";
import { SystemRequirementsOutOfSyncError } from "./SystemRequirementsOutOfSyncError";
import { TokenExpiredError } from "./TokenExpiredError";
import { TokenInitialError } from "./TokenInitialError";
import { TokenInvalidError } from "./TokenInvalidError";
import { TokenNotFoundError } from "./TokenNotFoundError";
import { UserNotFoundError } from "./UserNotFoundError";

/**
 * Contains all REST error, which might occurs and maps it to typescript specific RESTErrors
 */
const configure = <T extends RESTErrorRegistryType>(config: T) => {
  return config;
};

/**
 * Contains all SAP specific REST errors which might occur mapped to internal TypeScript specific error types.
 */
export const RESTErrorRegistry = configure({
  "/SNP/CX_SWC1_NO_AUTHORIZATION": MissingAuthError,
  "/SNP/CX_SWD1_INST_GUIDE_STORE": MissingInstallGuideError,
  "/SNP/CX_SWD1_MISSING_DOCU_LINK": MissingDocuLinkError,
  "/SNP/CX_SWC1_CODE_NOT_FOUND": InvalidAccessCodeError,
  "/SNP/CX_SWC1_RS_INSUF_SYS_REQ": InsufficientSystemRequirementsError,
  "/SNP/CX_SWC1_RS_SYSTEM_CONFL": SystemConflictError,
  "/SNP/CX_SWC1_RS_SYSTEM_DUPLI": SystemDuplicateError,
  "/SNP/CX_SWC1_RS_SYS_LAND_USED": SystemLandscapeInUseError,
  "/SNP/CX_SWC1_TOKEN_EXPIRED": TokenExpiredError,
  "/SNP/CX_SWC1_TOKEN_INITIAL": TokenInitialError,
  "/SNP/CX_SWC1_TOKEN_NOT_FOUND": TokenNotFoundError,
  "/SNP/CX_SWC1_RS_TOKEN_INVALID": TokenInvalidError,
  "/SNP/CX_SWC1_RS_USER_NOT_FOUND": UserNotFoundError,
  "/SNP/CX_SW01_PATH_NOT_FOUND": PathNotFoundError,
  "/SNP/CX_SWC1_MODULE_NOT_FOUND": LicenseGenerationError,
  "/SNP/CX_SWC1_LICENSE_ERROR": LicenseGenerationError,
  "/SNP/CX_SWC1_USER_NOT_FOUND": UserNotFoundError,
  "/SNP/CX_SWC1_MAX_LOGIN_FAIL": MaxLoginFailError,
  "/SNP/CX_SWC1_RS_PROD_EXPIRED": ProductExpiredError,
  "/SNP/CX_SWC1_RS_INV_EXP_DATE": ProductInvalidExpiryDateError,
  "/SNP/CX_SWC1_RS_INV_SYS_REQMTS": SystemRequirementsOutOfSyncError,
});
