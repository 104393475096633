import { FuzzySearch } from "./../../services/fuzzySearch/FuzzySearch";
import { IEditableSectionProps } from "./IEditableSectionProps";
import { IEntity } from "../../shared/types/IEntity";
import { RESTErrorHandler } from "../../api/types/RESTErrorHandler";

import { useEffect } from "react";
import { useRequest } from "../../hooks/useRequest";
import { useState } from "react";

/**
 * This view model handles states for the EditableSection component.
 */
export const useEditableSectionViewModel = <T extends IEntity>(
  props: IEditableSectionProps<T>
) => {
  const [objects, setObjects] = useState<T[]>([]);
  const request = useRequest();
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (props.searchSignal) {
      setQuery(props.searchSignal.value);
    }
  }, [props.searchSignal]);

  /**
   * Creates a new object, which is not persisted yet
   * and adds it to the object list.
   * Returns if the operation was executed successfully
   */
  const onAdd = async (): Promise<boolean> => {
    setObjects((previous) => {
      const dummy = props.dummyClassType.create();
      return [...previous, dummy];
    });

    return true;
  };

  /**
   * Creates a new object of {@link T}, which is not persisted yet,
   * copies all props if the given {@link object} and adds it to the object list.
   * Returns if the operation was executed successfully   *
   */
  const onCopy = async (object: T): Promise<boolean> => {
    setObjects((previous) => {
      // Copy the origin object by creating a dummy. A dummy is required to identify the object as transient.
      // Even it is a copy, it is a standalone object, so we don't want to copy the OBJECT_ID. Therefore the second parameter "copyTemplateId" is false.
      const index = previous.findIndex((item) => item === object);
      const clone = props.dummyClassType.create(object, false);
      previous.splice(index + 1, 0, clone);
      return [...previous];
    });
    return true;
  };

  /**
   * Deletes an object from the object list. If the object is not persisted yet,
   * only delete it on the client side.
   * Provide an optional {@link errorHandler} to handle specific REST errors.
   * Returns if the operation was executed successfully
   */
  const onDelete = async (
    object: T,
    errorHandler?: RESTErrorHandler
  ): Promise<boolean> => {
    setObjects((previous) => {
      const index = previous.findIndex(
        (item) => item.OBJECT_ID === object.OBJECT_ID
      );
      if (index !== -1) {
        previous.splice(index, 1);
        return [...previous];
      }
      return [...previous];
    });

    let success = true;
    request.send(
      async () => {
        if (props.dummyClassType.isPersistent(object)) {
          if (props.onDelete) {
            await props.onDelete(object);
          } else {
            await props.repository.deleteById(object.OBJECT_ID);
          }
        }
      },
      (error) => {
        success = false;
        return errorHandler?.(error) ?? false;
      }
    );
    return success;
  };

  /**
   * Updates the given {@link updatedObject}.
   * Provide an optional {@link errorHandler} to handle specific REST errors.
   */
  const onUpdate = async (
    updatedObject: T,
    errorHandler?: RESTErrorHandler
  ) => {
    let success = true;
    await request.send(
      async () => {
        if (props.onUpdate) {
          await props.onUpdate(updatedObject);
        } else {
          await props.repository.update(updatedObject);
        }
      },
      (error) => {
        success = false;
        return errorHandler?.(error) ?? false;
      }
    );
    return success;
  };

  /**
   * Saves the given {@link updatedObject}.
   * If {@link updatedObject} is not persisted yet, it must be added instead.
   * Provide an optional {@link errorHandler} to handle specific REST errors.
   */
  const onSave = async (
    updatedObject: T,
    errorHandler?: RESTErrorHandler
  ): Promise<boolean> => {
    let success = true;
    // cache the object id, as it will change, if the object has to be created in the backend.
    const originObjectId = updatedObject.OBJECT_ID;
    if (props.dummyClassType.isTransient(updatedObject)) {
      await request.send(
        async () => {
          if (props.onAdd) {
            updatedObject = await props.onAdd!(updatedObject);
          } else {
            updatedObject = await props.repository.add(updatedObject);
          }
        },
        (error) => {
          success = false;
          return errorHandler?.(error) ?? false;
        }
      );
    } else {
      success = await onUpdate(updatedObject, errorHandler);
    }
    if(props.onSaveDone){
      props.onSaveDone();
    }
    setObjects((previous) => {
      const index = previous.findIndex(
        (item) => item.OBJECT_ID === originObjectId
      );
      if (index !== -1) {
        previous.splice(index, 1, updatedObject);
      }
      // Creates new instances of all objects. This also includes dummy objects,
      // which means they are no longer dummies, but persistent objects.
      return [...previous];
    });
    return success;
  };

  const canAdd = (): boolean => {
    const canAdd = props.canAdd ? props.canAdd() : true;
    return canAdd;
  };

  const filterObjects = (): T[] => {
    if (query.length === 0) {
      return objects;
    }

    const fuzzySearch = new FuzzySearch<T>();
    return fuzzySearch.search(query, objects);
  };

  return {
    filterObjects,
    canAdd,
    onAdd,
    onCopy,
    onDelete,
    onSave,
    onUpdate,
    setObjects,
  };
};
