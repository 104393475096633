import { AppContext } from "../context/AppContext";

import { formatDate } from "../utils/formatDate";
import { texts } from "../i18n/texts";
import { useContext } from "react";

import useTranslation from "./useTranslation";

/**
 * This custom hook is responsible for formatting a date depended on the selected language
 */
export const useFormatDate = () => {
  const context = useContext(AppContext);
  const { t } = useTranslation();

  const format = (date: string): string => {
    if (date === "0000-00-00") {
      return t(texts.general.noDate);
    }

    return formatDate(date, context.language.value);
  };

  return format;
};
