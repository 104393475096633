// import { Icon, IconSize } from "@snpjs/core";

import { IConflictSectionProps } from "./IConflictSectionProps";
import styles from "./ConflictSection.module.scss";

export const ConflictSection: React.FC<IConflictSectionProps> = (props) => {
  return (
    <>
      <div className={styles.conflictSection}>
        {/* <Icon
          icon={props.iconName}
          size={IconSize.LARGE}
          className={styles.icon}
          intent={props.intent}
        /> */}
        <div>
          {/* <h1 className={styles.title}>{props.title}</h1> */}
          {props.children}
        </div>
      </div>
    </>
  );
};
