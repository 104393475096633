import { ConfigMeta } from "../shared/model/IConfig";
import { IConfig } from "../shared/model/IConfig";
import { Repository } from "./core/Repository";

class ConfigApiDefault extends Repository<IConfig> {
  constructor() {
    super(ConfigMeta.path);
  }

  find(): Promise<IConfig> {
    return this.get(this.url);
  }
}
export const ConfigApi = new ConfigApiDefault();
