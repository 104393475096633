import { useEffect, useMemo, useState } from "react";

import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { IMessage } from "../../../model/message/IMessage";
import { ISystemLandscapeProps } from "./ISystemLandscapeProps";
import { Link } from "react-router-dom";
import { SystemVersionsInconsistentError } from "../../../api/errors/SystemVersionsInconsistentError";
import { compareVersion } from "../../../utils/version/compareVersion";
import { texts } from "../../../i18n/texts";
import { useAuth } from "../../../hooks/useAuth";
import { useConfig } from "../../../hooks/useConfig";
import { useRequest } from "../../../hooks/useRequest";
import { useSetSystemLandscapeVersionCommand } from "../systemCommands/useSetSystemLandscapeVersionCommand";
import useTranslation from "../../../hooks/useTranslation";

const useSystemLandscapeViewModel = (props: ISystemLandscapeProps) => {
  const [systemLandscape, setSystemLandscape] = useState(props.systemLandscape);
  const [message, setMessage] = useState<IMessage>();
  const request = useRequest();
  const { t } = useTranslation();
  const config = useConfig();
  const auth = useAuth();

  const versionCommand = useSetSystemLandscapeVersionCommand({
    productId: props.product.OBJECT_ID,
    isVersionChangeable: true,
    systemLandscape: props.systemLandscape,
    release: props.systemLandscape.RELEASE,
    isLTS: props.systemLandscape.IS_LTS,
    servicePack: props.systemLandscape.SERVICE_PACK,
    hotfix: props.systemLandscape.HOTFIX,
    onOkay: async () => {
      await props.onSetAlternativeDownloadVersion?.();
      // reset messages to recalculate it
      setMessage(undefined);
    },
  });

  const containsSystemVersionInconsistency = useMemo(
    () =>
      props.systemLandscapeConflicts.findIndex(
        (systemLandscapeConflict) =>
          systemLandscapeConflict.CLASSNAME ===
          SystemVersionsInconsistentError.origin
      ) !== -1,
    [props.systemLandscapeConflicts]
  );

  const messageSystemVersionInconsistent: IMessage = useMemo(
    () => ({
      severity: "error",
      text: t(texts.systemLandscape.errorSystemVersionInconsistent, {
        supportPortal: (
          <Link to={config.SUPPORT_PORTAL_LINK}>
            {config.SUPPORT_PORTAL_LINK}
          </Link>
        ),
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const messageSystemVersionInconsistentSupport: IMessage = useMemo(
    () => ({
      severity: "error",
      text: t(texts.systemLandscape.errorSystemVersionInconsistentSupport, {
        setAlternativeDownloadVersion: (
          <button onClick={() => versionCommand.execute()}>
            {t(texts.systemLandscape.captionSetAlternativeDownloadVersion)}
          </button>
        ),
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (containsSystemVersionInconsistency) {
      if (auth.isSuperUser()) {
        setMessage(messageSystemVersionInconsistentSupport);
      } else {
        setMessage(messageSystemVersionInconsistent);
      }
    }
  }, [
    auth,
    containsSystemVersionInconsistency,
    messageSystemVersionInconsistent,
    messageSystemVersionInconsistentSupport,
  ]);

  const deleteLandscape = () => {
    props.onDelete?.(props.systemLandscape);
  };

  const saveSystemLandscapeDescription = async (description: string) => {
    await request.send(async () => {
      const newSystemLandscape = {
        ...systemLandscape,
        DESCRIPTION: description,
      };
      await props.onSave?.(newSystemLandscape);
      setSystemLandscape(newSystemLandscape);
    });
  };

  const showDeleteButton =
    props.systems !== undefined && props.systems?.length === 0;

  /**
   * Returns if the underlying system landscape can be upgraded. Which means there is a newer version.
   */
  const canBeUpgraded =
    compareVersion(props.systemLandscape, props.lastProductVersion) < 0;

  /**
   * Returns if the version of the underlying system landscape can be changed
   */
  const canVersionBeAdjusted =
    props.systemLandscape.HAS_DOWNLOADS !== ABAPBoolean.true;

  return {
    canBeUpgraded,
    canVersionBeAdjusted,
    containsSystemVersionInconsistency,
    deleteLandscape,
    message,
    saveSystemLandscapeDescription,
    showDeleteButtonLoadingSpinner: props.isLandscapeBeingDeleted,
    showDeleteButton,
    systemLandscape,
    versionCommand,
  };
};
export default useSystemLandscapeViewModel;
