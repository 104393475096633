import { Divider, Icon, Menu, MenuItem, Popover, Tooltip } from "@snpjs/core";

import { ConflictList } from "../../conflictManagement/conflictList/conflictList/ConflictList";
import { EditableCard } from "../../../components/editableCard/EditableCard";
import { IMenuItem } from "./IMenuItem";
import { ISystemCardProps } from "./ISystemCardProps";
import { LoadingSpinnerButton } from "../../../components/loadingSpinnerButton/LoadingSpinnerButton";
import { MessageArea } from "../../../components/messageArea/MessageArea";
import { MultiSelection } from "../../../components/selection/multiSelection/MultiSelection";
import { ReactElement } from "react";
import { SystemDetailsOverviewPopover } from "../systemDetailsOverviewPopover/SystemDetailsOverviewPopover";
import { SystemDownloadHistory } from "../systemDownloadHistory/SystemDownloadHistory";
import { SystemDummy } from "../../../model/SystemDummy";
import { SystemHeaderFields } from "../systemHeaderFields/SystemHeaderFields";
import { SystemRequirementTypeList } from "../systemRequirementTypeList/SystemRequirementTypeList";
import { TagElement } from "../../../components/tagElement/TagElement";
import styles from "./SystemCard.module.scss";
import { texts } from "../../../i18n/texts";
import { useConfig } from "../../../hooks/useConfig";
import { useRequestEmergencyLicenseCommand } from "../systemCommands/useRequestEmergencyLicenseCommand";
import { useSystemCardViewModel } from "./useSystemCardViewModel";
import useTranslation from "../../../hooks/useTranslation";
import { MultiSelectionDropDown } from "../../../components/selection/multiSelectionDropDown/MultiSelectionDropDown";

export const SystemCard: React.FC<ISystemCardProps> = (props) => {
  const viewModel = useSystemCardViewModel(props);
  const { t } = useTranslation();
  const config = useConfig();

  const messageAreaContent = viewModel.message && (
    <div>
      <MessageArea message={viewModel.message} />
    </div>
  );

  const onRequestEmergencyLicense = async (reason: string) => {
    try {
      await viewModel.onDownloadEmergencyLicense(reason);
      return true;
    } catch (error) {
      return false;
    }
  };

  const EmergencyLicenseCommand = useRequestEmergencyLicenseCommand({
    //Nachdem einmal requested wurde, kann man sie einfach herunterladen --> das Popup soll also nicht mehr geladen werden!
    onOkay: onRequestEmergencyLicense,
    productId: props.product.PRODUCT_ID,
    getNewEmergencyExpirationDate: viewModel.getNewEmergencyExpirationDate,
    emergencyLicenseDuration: config.EMERGENCY_EXT_DAYS,
  });

  const downloadMenu = (
    <Menu>
      {viewModel.isLicenseActive ? (
        <MenuItem
          icon="package"
          disabled={false}
          onClick={viewModel.onDownloadSoftware}
          text={t(texts.systemCard.downloadSoftwareButton)}
        />
      ) : (
        <Tooltip
          className={styles.elementWithTooltip}
          content={t(texts.systemCard.tooltipDownloadExpired)}
        >
          <MenuItem
            icon="package"
            disabled={true}
            onClick={viewModel.onDownloadSoftware}
            text={t(texts.systemCard.downloadSoftwareButton)}
          />
        </Tooltip>
      )}

      {!viewModel.isLicenseRequired() ? (
        <Tooltip
          className={styles.elementWithTooltip}
          content={t(texts.systemCard.tooltipNotLicensable)}
        >
          <MenuItem
            icon="identification-card"
            disabled={true}
            onClick={viewModel.onDownloadLicense}
            text={t(texts.systemCard.downloadLicenseButton)}
          />
        </Tooltip>
      ) : !viewModel.isLicenseActive ? (
        <Tooltip
          className={styles.elementWithTooltip}
          content={t(texts.systemCard.tooltipDownloadExpired)}
        >
          <MenuItem
            icon="identification-card"
            disabled={true}
            onClick={viewModel.onDownloadLicense}
            text={t(texts.systemCard.downloadLicenseButton)}
          />
        </Tooltip>
      ) : (
        <MenuItem
          icon="identification-card"
          disabled={false}
          onClick={viewModel.onDownloadLicense}
          text={t(texts.systemCard.downloadLicenseButton)}
        />
      )}
      {(viewModel.isExpired() || viewModel.isAboutToExpire()) && (
        <div>
          <Divider className={styles.divider} />
          {viewModel.isEmergencyLicenseRequestable() ? (
            <MenuItem
              icon={<Icon icon="first-aid-kit" intent="danger" />}
              disabled={false}
              onClick={() => {
                EmergencyLicenseCommand.execute();
              }}
              text={t(texts.systemCard.downloadEmergencyLicenseButton)}
            />
          ) : (
            <Tooltip
              className={styles.elementWithTooltip}
              content={t(texts.systemCard.alreadyRunningEmergencyLicense)}
            >
              <MenuItem
                icon={<Icon icon="first-aid-kit" intent="danger" />}
                disabled={true}
                onClick={() => {
                  EmergencyLicenseCommand.execute();
                }}
                text={t(texts.systemCard.downloadEmergencyLicenseButton)}
              />
            </Tooltip>
          )}
        </div>
      )}
    </Menu>
  );

  const buildMenuItems = (menuItem: IMenuItem, index: number): ReactElement => {
    return (
      <MenuItem
        key={index}
        text={menuItem.text}
        onClick={menuItem.onClick}
        children={menuItem.children?.map((child, childIndex) =>
          buildMenuItems(child, childIndex)
        )}
      ></MenuItem>
    );
  };

  const helpMenu = (
    <Menu>
      {viewModel.installationHelpMenuEntries.map((entry, index) =>
        buildMenuItems(entry, index)
      )}
    </Menu>
  );

  const showSystemRequirements = props.systemRequirementTypes.length > 0;
  return (
    <EditableCard
      actionsDisplayMode={
        <>
          {EmergencyLicenseCommand.view}
          <Tooltip
            content={
              viewModel.isDownloading
                ? t(texts.systemCard.tooltipDownloadBundling)
                : t(texts.systemCard.tooltipDownload)
            }
          >
            <Popover content={downloadMenu} placement="bottom-start">
              <LoadingSpinnerButton
                disabled={
                  viewModel.isSystemDeactivated ||
                  viewModel.hasSystemConflicts ||
                  viewModel.isSystemRoleSelectionInvalid() ||
                  viewModel.isSystemRequirementSelectionInvalid() ||
                  props.containsSystemVersionInconsistency
                }
                icon={<Icon icon={"download"} />}
                outlined
                showSpinner={viewModel.isDownloading}
                spinnerClassName={styles.spinner}
              />
            </Popover>
          </Tooltip>

          <Tooltip content={t(texts.systemCard.tooltipInstallHelp)}>
            <Popover content={helpMenu} placement="bottom-start">
              <LoadingSpinnerButton
                showSpinner={viewModel.isLoadingDocu}
                outlined
                icon={<Icon icon={"book-info"} />}
                spinnerClassName={styles.spinner}
                onClick={viewModel.onLoadInstallationHelpMenu}
              />
            </Popover>
          </Tooltip>
        </>
      }
      actionsChangeMode={
        <MenuItem
          disabled={viewModel.isSystemDeactivated}
          text={t(texts.systemCard.labelMarkAsUnused)}
          icon="envelope-simple-open"
          onClick={viewModel.systemDeactivateCommand.execute}
        />
      }
      deleteText={(system) => {
        return {
          text: texts.systemCard.deleteText,
          placeholders: {
            installNumber: system.INSTNUMBER,
          },
        };
      }}
      deleteTitle={texts.systemCard.deleteTitle}
      dummyClassType={SystemDummy}
      editMode={viewModel.editMode}
      hideActions={viewModel.needsCollapse}
      initialObject={props.system}
      onRestore={viewModel.onRestore}
      onCopy={viewModel.onCopy}
      onDelete={viewModel.onDelete}
      onSave={viewModel.onSave}
      onValidate={viewModel.onValidate}
      setEditMode={viewModel.setEditMode}
      setObject={viewModel.setSystem}
    >
      {viewModel.systemDeactivateCommand.view}
      <div className={styles.systemCard}>
        {messageAreaContent}
        <div className={styles.detailsHeader}>
          <div className={styles.systemHeaderFields}>
            {!viewModel.editMode && (
              <div className={styles.collapseIcon}>
                <Icon
                  className={styles.collapseIcon}
                  icon={viewModel.needsCollapse ? "caret-down" : "caret-up"}
                  onClick={viewModel.toggleIsCollapsed}
                />
              </div>
            )}
            <SystemHeaderFields
              inputFieldErrorClient={viewModel.inputFieldErrorClient}
              inputFieldErrorInstallNumber={
                viewModel.inputFieldErrorInstallNumber
              }
              inputFieldErrorSid={viewModel.inputFieldErrorSid}
              inputFieldErrorDescription={viewModel.inputFieldErrorDescription}
              editMode={viewModel.editMode}
              onSetSid={viewModel.onSetSid}
              system={viewModel.system}
              onSetClient={viewModel.onSetClient}
              onSetInstallNumber={viewModel.onSetInstallNumber}
              onSetDescription={viewModel.onSetDescription}
            />
            <div className={styles.systemDetails}>
              <TagElement text={props.number.toString()} />
              {SystemDummy.isPersistent(props.system) && (
                <SystemDetailsOverviewPopover
                  systemObjectId={viewModel.system.OBJECT_ID}
                />
              )}
            </div>
            {viewModel.needsCollapse &&
              props.systemRoles
                .map((role) => viewModel.isSystemRoleSelected(role))
                .includes(true) && (
                <div className={styles.systemRoleInfo}>
                  <h4 className={styles.collapsedRoleHeader}>
                    {t(texts.systemCard.systemRoles)}
                  </h4>
                  <MultiSelection
                    editMode={viewModel.editMode}
                    getName={(systemRole) => systemRole.DESCRIPTION}
                    isSelected={viewModel.isSystemRoleSelected}
                    objects={props.systemRoles}
                    onActivate={viewModel.onSystemRoleActivated}
                    onDeactivate={viewModel.onSystemRoleDeactivated}
                  />
                </div>
              )}
          </div>
          {props.showDownloadHistory && !viewModel.needsCollapse && (
            <SystemDownloadHistory
              systemDownloadHistory={viewModel.system.DOWNLOAD_HISTORY}
            />
          )}
        </div>
        {!viewModel.needsCollapse && (
          <>
            <div className={styles.systemRolesSection}>
              <h4 className={styles.headline}>
                {t(texts.systemCard.systemRoles)}
              </h4>
              <div className={styles.systemRoles}>
                <MultiSelectionDropDown
                  editMode={viewModel.editMode}
                  getName={(systemRole) => systemRole.DESCRIPTION}
                  getInternalId={(systemRole) => systemRole.SYSTEM_ROLE_ID}
                  getObjectId={(systemRole) => systemRole.OBJECT_ID}
                  objects={props.systemRoles}
                  onUpdate={viewModel.onSystemRolesUpdate}
                  isSelected={viewModel.isSystemRoleSelected}
                />
              </div>
            </div>
            {showSystemRequirements && (
              <>
                <h4 className={styles.headline}>
                  {t(texts.systemCard.systemRequirements)}
                </h4>
                <div>
                <SystemRequirementTypeList
                    editMode={viewModel.editMode}
                    hasSelectedSystemRequirement={
                      viewModel.hasSelectedSystemRequirement
                    }
                    isSystemRequirementSelected={
                      viewModel.isSystemRequirementSelected
                    }
                    systemRequirementTypes={props.systemRequirementTypes}
                    onSystemRequirementChanged={
                      viewModel.onSystemRequirementChanged
                    }
                    activeRequirements={viewModel.system.SYSTEM_REQMTS}
                  />
                </div>
              </>
            )}
            {viewModel.displayConflicts && (
              <>
                <h4>{t(texts.systemCard.conflicts)}</h4>
                <div className={styles.conflictSection}>
                  <ConflictList conflicts={viewModel.system.CONFLICTS} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </EditableCard>
  );
};
