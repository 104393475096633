import { ContentType } from "../../types/ContentType";
import { IHeaderInfo } from "./IHeaderInfo";

export class ResponseHeaderInfo implements IHeaderInfo {
  constructor(private response: Response) {}
  getContentType(): ContentType | undefined {
    const contentTypeHeaderEntry = this.response.headers.get("content-type");
    if (contentTypeHeaderEntry) {
      const contentTypeKey = Object.keys(ContentType).find((contentType) =>
        contentTypeHeaderEntry.includes((ContentType as any)[contentType])
      );

      if (contentTypeKey) {
        return (ContentType as any)[contentTypeKey];
      }

      if (contentTypeHeaderEntry === ContentType.TEXT) {
        return ContentType.TEXT;
      }
    }
  }

  getFilename(): string {
    const contentDisposition = this.response.headers.get("content-disposition");
    const filename = contentDisposition?.split(`filename="`)?.[1].slice(0, -1);
    return filename ?? "";
  }
}
