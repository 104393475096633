import { Message } from "../services/Message";
import { MissingAuthError } from "../api/errors/MissingAuthorizationError";
import { RESTError } from "../api/core/RESTError";
import { RESTErrorHandler } from "../api/types/RESTErrorHandler";
import { TokenExpiredError } from "../api/errors/TokenExpiredError";
import { TokenInitialError } from "../api/errors/TokenInitialError";
import { TokenInvalidError } from "../api/errors/TokenInvalidError";
import { TokenNotFoundError } from "../api/errors/TokenNotFoundError";

import { isRESTError } from "../api/utils/isRESTError";
import { requestLogout } from "./useLogout";
import { texts } from "../i18n/texts";
import { useHandleUnexpectedError } from "./useHandleUnexpectedError";
import { useLogout } from "./useLogout";

import useTranslation from "./useTranslation";

/**
 * This hook is responsible for executing REST requests and to handle errors in a standardized way.
 */
export const useRequest = () => {
  const { t } = useTranslation();
  const logout = useLogout();
  const unexpectedErrorHandler = useHandleUnexpectedError();

  const handleTokenError = () => {
    logout();
    Message.warning(t(texts.login.errorTokenExpired));
  };

  const handleMissingAuthorizationError = () => {
    Message.error(t(texts.errors.missingAuthority));
  };

  const handleDefaultRESTError = (error: RESTError) => {
    if (error.originError.LONG_TEXT.length > 0) {
      return Message.error(error.originError.LONG_TEXT);
    } else if (error.originError.SHORT_TEXT.length > 0) {
      return Message.error(error.originError.SHORT_TEXT);
    }
    unexpectedErrorHandler.handle(error);
  };

  /**
   * Is responsible to handle REST errors
   */
  const handleRESTError = (
    error: RESTError,
    errorHandler?: RESTErrorHandler
  ) => {
    // if no errorHandler was provided or errorHandler hasn't handled the error,
    // the system will do
    if (!errorHandler || errorHandler(error) === false) {
      switch (error.constructor) {
        case TokenExpiredError: {
          return handleTokenError();
        }
        case TokenInvalidError: {
          return handleTokenError();
        }
        case TokenInitialError: {
          return handleTokenError();
        }
        case TokenNotFoundError: {
          return handleTokenError();
        }
        case MissingAuthError: {
          return handleMissingAuthorizationError();
        }
        default:
          return handleDefaultRESTError(error);
      }
    }
  };

  /**
   * Sends the given {@link request}.
   * By providing an {@link errorHandler} it is possible to handle an {@link RESTError} separately, otherwise the framework will handle it.
   */
  const send = async <T>(
    request: () => Promise<T>,
    errorHandler?: RESTErrorHandler
  ) => {
    try {
      // If the user is currently logged out, stop request
      if (!requestLogout) {
        return await request();
      }
    } catch (error) {
      if (isRESTError(error)) {
        handleRESTError(error, errorHandler);
      } else {
        unexpectedErrorHandler.handle(error);
      }
    }
  };

  return { send };
};
