import { Button } from "@snpjs/core";
import { Dialog } from "../dialog/Dialog";
import { IConfirmDialogProps } from "./IConfirmDialogProps";
import { texts } from "../../i18n/texts";
import useTranslation from "../../hooks/useTranslation";

/**
 * This component is responsible for displaying a confirm dialog with text and buttons to confirm or cancel.
 */
export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={props.isOpen}
      title={props.title}
      onCancel={props.onCancel}
      footer={
        <>
          <Button onClick={props.onOkay} icon="check" intent="success">
            {t(texts.general.okay)}
          </Button>
          <Button onClick={props.onCancel} icon="x" intent="danger">
            {t(texts.general.cancel)}
          </Button>
        </>
      }
    >
      {props.text}
    </Dialog>
  );
};
