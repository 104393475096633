import { AsyncLoad } from "../../../components/asyncLoad/AsyncLoad";
import { Detail } from "../../../components/detail/Detail";
import { IContract } from "../../../shared/model/IContract";
import { ISystem } from "../../../shared/model/ISystem";
import { ISystemDetailsOverviewProps } from "./ISystemDetailsOverviewProps";
import { ISystemIntendedVersion } from "../../../shared/model/ISystemIntendedVersion";
import { ISystemVersion } from "../../../shared/types/ISystemVersion";
import { Link } from "react-router-dom";
import { Routes } from "../../../routes/Routes";
import { SystemApi } from "../../../api/SystemApi";
import { VersionDetails } from "../../versionDetails/versionDetails/VersionDetails";
import { checkNotNull } from "../../../utils/checkNotNull";
import { isVersionInitial } from "../../../utils/version/isVersionInitial";
import styles from "./SystemDetailsOverview.module.scss";
import { texts } from "../../../i18n/texts";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";

export const SystemDetailsOverview: React.FC<ISystemDetailsOverviewProps> = (
  props
) => {
  const { t } = useTranslation();
  const [system, setSystem] = useState<ISystem | undefined>(undefined);
  const [intendedVersion, setIntendedVersion] = useState<
    ISystemIntendedVersion | undefined
  >(undefined);
  const [lastDownloadVersion, setLastDownloadVersion] = useState<
    ISystemVersion | undefined
  >(undefined);

  const [contract, setContract] = useState<IContract | undefined>(undefined);
  const auth = useAuth();

  return (
    <AsyncLoad
      load={async () => {
        const system = await SystemApi.findById(props.systemObjectId);
        setSystem(system);
        const systemObjectId = checkNotNull(system).OBJECT_ID;
        const contract = await SystemApi.getContractById(systemObjectId);
        setContract(contract);
        const intendedVersion = await SystemApi.getIntendedVersion(
          systemObjectId
        );
        setIntendedVersion(intendedVersion);
        const lastDownloadVersion = await SystemApi.getLastDownloadVersion(
          systemObjectId
        );
        setLastDownloadVersion(lastDownloadVersion);
      }}
    >
      <div className={styles.systemDetailsOverview}>
        {auth.canDisplaySystemDetails() && (
          <>
            <div className={styles.block}>
              <h4>{t(texts.systemDetailsOverview.header)}</h4>
              <div className={styles.blockDetails}>
                <Detail
                  title={t(texts.general.objectId)}
                  value={system?.OBJECT_ID}
                />
                <Detail
                  title={t(texts.general.description)}
                  value={system?.DESCRIPTION}
                />
                <Detail
                  title={t(texts.general.deactivated)}
                  value={system?.IS_DEACTIVATED}
                />
              </div>
            </div>

            <div className={styles.block}>
              <h4>{t(texts.systemDetailsOverview.projectDetails)}</h4>
              <div className={styles.blockDetails}>
                <Detail
                  title={t(texts.general.system.systemId)}
                  value={system?.SYSTEM_ID}
                />
                <Detail
                  title={t(texts.general.system.systemClient)}
                  value={system?.SYSTEM_CLIENT}
                />
                <Detail
                  title={t(texts.general.system.installNumber)}
                  value={system?.INSTNUMBER}
                />
              </div>
            </div>

            <div className={styles.block}>
              <h4>{t(texts.systemDetailsOverview.SAPSystemDetails)}</h4>
              <div className={styles.blockDetails}>
                <Detail
                  title={t(texts.general.system.systemId)}
                  value={system?.SYSTEM_ID}
                />
                <Detail
                  title={t(texts.general.system.systemClient)}
                  value={system?.SYSTEM_CLIENT}
                />
                <Detail
                  title={t(texts.general.system.installNumber)}
                  value={system?.INSTNUMBER}
                />
              </div>
            </div>
          </>
        )}

        <div className={styles.block}>
          <h4>{t(texts.systemDetailsOverview.intendedVersion)}</h4>
          <div className={styles.blockDetails}>
            {intendedVersion &&
            !isVersionInitial(intendedVersion.INTENDED_VERSION) ? (
              <VersionDetails version={intendedVersion.INTENDED_VERSION} />
            ) : (
              t(texts.systemDetailsOverview.errorSystemVersionNotFound)
            )}
          </div>
        </div>

        <div className={styles.block}>
          <h4>{t(texts.systemDetailsOverview.lastDownloadedVersion)}</h4>
          {lastDownloadVersion && !isVersionInitial(lastDownloadVersion) ? (
            <div className={styles.blockDetails}>
              <VersionDetails version={lastDownloadVersion} />
            </div>
          ) : (
            t(texts.systemDownloadHistory.messageNotDownloadedYet)
          )}
        </div>

        <div className={styles.block}>
          <h4>{t(texts.systemDetailsOverview.highestAvailableVersion)}</h4>
          {intendedVersion &&
          !isVersionInitial(intendedVersion.LAST_PRODUCT_VERSION) ? (
            <div className={styles.blockDetails}>
              <VersionDetails version={intendedVersion.LAST_PRODUCT_VERSION} />
            </div>
          ) : (
            t(texts.systemDetailsOverview.errorSystemVersionNotFound)
          )}
        </div>
        <Link
          to={Routes.contractProduct.toPath({
            contractId: contract?.OBJECT_ID ?? "",
            productId: contract?.PRODUCTS?.[0].OBJECT_ID ?? "",
          })}
          target="_blank"
        >
          {t(texts.general.openContract)}
        </Link>
      </div>
    </AsyncLoad>
  );
};
