import { Checkbox } from "@snpjs/core";
import { ILabeledCheckboxProps } from "./ILabeledCheckboxProps";
import { LabeledElement } from "../labeledElement/LabeledElement";
import { useValidatorProvider } from "../../hooks/useValidatorProvider";
import { useCallback, useEffect, useState } from "react";
import { ValidationHandler } from "../../types/ValidationHandler";
import { ValidationError } from "../../errors/ValidationError";
import useTranslation from "../../hooks/useTranslation";
import { texts } from "../../i18n/texts";
import styles from "./labeledCheckbox.module.scss";

export const LabeledCheckbox: React.FC<ILabeledCheckboxProps> = (props) => {
  const { className, errorMessage, label, placeholder, setIsChecked, isChecked, requestValidator, ...checkboxProps } = props;

  const [displayError, setDisplayError] = useState(false);
  const { t } = useTranslation();

  const onValidate: ValidationHandler = useCallback(() => {

    if (!props.isChecked) {
      setDisplayError(true);
      throw new ValidationError();
    }
  }, [props.isChecked]);
  const validator = useValidatorProvider(onValidate);

  useEffect(() => {
    props.requestValidator?.(validator);

  }, [props.requestValidator, validator])

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setIsChecked(event.target.checked);
    setDisplayError(false);
  };
  return (
    <LabeledElement {...props}>
      <Checkbox {...checkboxProps}
        onChange={onCheckboxChange}
      />
      <div className={styles.errorMessage}>
        {displayError && t(texts.SystemGrantEmergencLicense.errorMissingCheck)}
      </div>
    </LabeledElement>
  );
};
