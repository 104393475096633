import { IDetailListProps } from "./IDetailListProps";
import { style } from "../../utils/style";
import styles from "./DetailList.module.scss";

export const DetailList: React.FC<IDetailListProps> = (props) => {
  return (
    <div className={style(styles.detailList, props.className)}>
      {props.children}
    </div>
  );
};
