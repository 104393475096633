import { IHavePath } from "../types/IHavePath";

/**
 * An implementation of this interface contains the properties for requesting an access code to log in a user.
 */
export interface IAccessCodeRequest {
  EMAIL: string;
}

export const AccessCodeRequestMeta: IHavePath = { path: "/access-code" };
