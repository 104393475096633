import "@snpjs/crystalbridge/lib/css/snpkit-crystalbridge.css";

import { BreadcrumbsProvider } from "../../breadcrumbs/breadcrumbsProvider/BreadcrumbsProvider";
import { ConfigContextProvider } from "../../configContextProvider/ConfigContextProvider";
import { Footer } from "../../../features/footer/Footer";
import { Header } from "../../../features/header/Header";
import { IPageProps } from "./IPageProps";
import { PageInfo } from "../pageInfo/PageInfo";
import { Search } from "../../search/Search";
import { style } from "../../../utils/style";
import styles from "./Page.module.scss";

/**
 * This is an abstract page, that is the base for each other concrete page.
 * It provides a general structure of a page like defining header or footer.
 */
export const Page: React.FC<IPageProps> = (props) => {
  return (
    <ConfigContextProvider>
      <Header />
      <div className={styles.content}>
        <main className={styles.main}>
          <div className={styles.header}>
            <div>{props.showBreadcrumbs && <BreadcrumbsProvider />}</div>
            {props.search && (
              <div className={styles.search}>
                <Search {...props.search} />
              </div>
            )}
          </div>
          <div className={style(styles.pageContent, props.className)}>
            {props.children}
          </div>
          <div className={styles.pageInfo}>
            {(props.showPageInfo === undefined ||
              props.showPageInfo === true) && 
              props.infoArea &&(
              <div className={styles.infoArea}>
                <PageInfo
                  iconName={props.iconName ?? "snp"}
                  infoArea={props.infoArea}
                />
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </ConfigContextProvider>
  );
};
