import { AuthRoleMeta } from "../shared/model/IAuthRole";
import { IAuthRole } from "../shared/model/IAuthRole";
import { Repository } from "./core/Repository";

class AuthRoleApiDefault extends Repository<IAuthRole> {
  constructor() {
    super(AuthRoleMeta.path);
  }
}

/**
 * This class is responsible for handling all {@link IAuthRole} specific REST requests.
 */
export const AuthRoleApi = new AuthRoleApiDefault();
