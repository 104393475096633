import { ILabeledInputProps } from "./ILabeledInputProps";
import { InputGroup } from "@snpjs/core";
import { LabeledElement } from "../labeledElement/LabeledElement";
import styles from "./LabeledInput.module.scss";
import { useId } from "react";

/**
 * This component is responsible for displaying an input with label
 */
export const LabeledInput: React.FC<ILabeledInputProps> = (props) => {
  const inputId = useId();
  const { errorMessage, label, placeholder, ...inputProps } = props;

  return (
    <LabeledElement {...props}>
      <div className={styles.input}>
        <InputGroup {...inputProps} id={inputId} className={styles.input} />
      </div>
    </LabeledElement>
  );
};
