import { Button, Colors, Tooltip } from "@snpjs/core";

import { ISelectionButtonProps } from "./ISelectionButtonProps";
import { style } from "../../../utils/style";
import styles from "./SelectionButton.module.scss";

export const SelectionButton: React.FC<ISelectionButtonProps> = (props) => {
  const commonStyle = {
    backgroundColor: Colors.MONOCHROME_GRAY3,
    color: Colors.BLACK,
  };

  const disabledStyle = !props.editMode
    ? {
        cursor: "auto",
        ...commonStyle,
      }
    : props.isImmutable && props.active && props.editMode
    ? commonStyle
    : {};

    if(props.isImmutable === false || !props.editMode){
      return (
        <>
          {props.objectTooltip ? (
            <Tooltip content={props.objectTooltip}>
              <Button
                active={props.active}
                disabled={props.disabled === true || !props.editMode}
                style={disabledStyle}
                key={props.objectName}
                onClick={props.onClick}
                outlined
                text={props.objectName}
                className={style(styles.button)}
              />
            </Tooltip>
          ) : (
            <Button
              active={props.active}
              disabled={props.disabled === true || !props.editMode}
              style={disabledStyle}
              key={props.objectName}
              onClick={props.onClick}
              outlined
              text={props.objectName}
              className={style(styles.button)}
            />
          )}
        </>
      );
    }else{
      return (<></>)
    }

};
