import { ISignal } from "../services/ISignal";
import { Signal } from "../services/Signal";
import { TriggerFunction } from "./../types/TriggerFunc";

import { useState } from "react";

/**
 * This custom hook is responsible for providing and triggering a system
 */
export const useSignal = <T = void>(): [
  signal: ISignal<T> | undefined,
  trigger: TriggerFunction<T>
] => {
  const [signal, setSignal] = useState<ISignal<T> | undefined>(undefined);

  const trigger: TriggerFunction<T> = ((value?: any) => {
    setSignal(new Signal(value));
  }) as TriggerFunction<T>;

  return [signal, trigger];
};
