import { IProtectedPageProps } from "./IProtectedPageProps";
import { Navigate } from "react-router-dom";
import { Page } from "../page/Page";
import { Routes } from "../../../routes/Routes";
import { useSession } from "../../../hooks/useSession";

/**
 * This page is responsible to ensure that the current user is authenticated for that page.
 * If no user is logged in or the user is not authorized the login page is displayed
 */
export const ProtectedPage: React.FC<IProtectedPageProps> = (props) => {
  const { children, ...propsWithoutChildren } = props;
  const session = useSession();

  if (!session) {
    return <Navigate to={Routes.login.toPath()} />;
  }

  return <Page {...propsWithoutChildren}>{children}</Page>;
};
