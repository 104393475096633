import { Button, Icon, Section, SectionCard } from "@snpjs/core";

import { DetailList } from "../../../components/detailList/DetailList";
import { ISystemLandscapeProps } from "./ISystemLandscapeProps";
import { InputWithEditButton } from "../../../components/inputWithEditButton/InputWithEditButton";
import { MessageArea } from "../../../components/messageArea/MessageArea";
import { ServicePackVersionDetails } from "../../versionDetails/servicePackVersionDetails/ServicePackVersionDetails";
import { Spinner } from "../../../components/spinner/Spinner";
import { SystemSection } from "../systemSection/SystemSection";
import styles from "./SystemLandscape.module.scss";
import { texts } from "../../../i18n/texts";
import { useSetSystemLandscapeDownloadVersionCommand } from "../systemCommands/useSetSystemLandscapeDownloadVersionCommand";
import { useSetSystemLandscapeUpgradeVersionCommand } from "../systemCommands/useSetSystemLandscapeUpgradeVersionCommand";
import useSystemLandscapeViewModel from "./useSystemLandscapeViewModel";
import useTranslation from "../../../hooks/useTranslation";
import { TagElement } from "../../../components/tagElement/TagElement";

export const SystemLandscape: React.FC<ISystemLandscapeProps> = (props) => {
  const viewModel = useSystemLandscapeViewModel(props);
  const { t } = useTranslation();
  const downloadVersionCommand = useSetSystemLandscapeDownloadVersionCommand({
    productId: props.product.OBJECT_ID,
    isVersionChangeable: true,
    systemLandscape: props.systemLandscape,
  });

  const upgradeVersionCommand = useSetSystemLandscapeUpgradeVersionCommand({
    productId: props.product.OBJECT_ID,
    isVersionChangeable: true,
    systemLandscape: props.systemLandscape,
    release: props.systemLandscape.RELEASE,
    isLTS: props.systemLandscape.IS_LTS,
    servicePack: props.systemLandscape.SERVICE_PACK,
    hotfix: props.systemLandscape.HOTFIX,
  });

  const title = (
    <div className={styles.title}>
      <InputWithEditButton
        initialValue={
          viewModel.systemLandscape.DESCRIPTION ??
          t(texts.systemLandscape.defaultSystemLandscapeName)
        }
        onEdit={async (description) =>
          await viewModel.saveSystemLandscapeDescription(description)
        }
        widthInRem={15}
      />
      {viewModel.showDeleteButton &&
        (viewModel.showDeleteButtonLoadingSpinner ? (
          <Spinner />
        ) : (
          <Icon
            icon={"trash"}
            className={styles.deleteIcon}
            onClick={viewModel.deleteLandscape}
          />
        ))}
    </div>
  );

  return (
    <Section
      title={title}
      icon="hard-drives"
      className={styles.systemLandscape}
      rightElement={
        <TagElement
          text={
            t(texts.systemLandscapeHeader.systems) + ": " + props.systems.length
          }
        />
      }
    >
      <SectionCard>
        <div>
          {downloadVersionCommand.view}
          {upgradeVersionCommand.view}
          {viewModel.versionCommand.view}
          {viewModel.message && (
            <MessageArea
              className={styles.messageArea}
              message={viewModel.message}
            />
          )}
          <div className={styles.detailList}>
            <DetailList>
              <ServicePackVersionDetails
                servicePackVersion={props.systemLandscape}
              />
            </DetailList>
            {(viewModel.canVersionBeAdjusted && (props.systemLandscape.RELEASE.toString() !== "0000")) && (
              <Button
                text={t(texts.systemLandscape.captionAdjustVersion)}
                onClick={() => downloadVersionCommand.execute()}
                className={styles.systemLandscapeButton}
              />
            )}

            {!viewModel.canVersionBeAdjusted && viewModel.canBeUpgraded && (
              <Button
                text={t(texts.systemLandscape.captionUpgradeVersion)}
                onClick={() => upgradeVersionCommand.execute()}
                className={styles.systemLandscapeButton}
              />
            )}
          </div>
        </div>
      </SectionCard>
      <SectionCard>
        <SystemSection
          onSystemSaveDone={props.onSystemSaveDone}
          collapseSignal={props.collapseSignal}
          expandSignal={props.expandSignal}
          onCollapseSingleSystem={props.onCollapseSingleSystem}
          onExpandSingleSystem={props.onExpandSingleSystem}
          containsSystemVersionInconsistency={
            viewModel.containsSystemVersionInconsistency
          }
          contract={props.contract}
          product={props.product}
          searchSignal={props.searchSignal}
          systemLandscapeId={viewModel.systemLandscape.OBJECT_ID}
          systems={props.systems ?? []}
          systemLandscapeConflicts={props.systemLandscapeConflicts}
          systemRequirementTypes={props.systemRequirementTypes}
          systemRoles={props.systemRoles}
        />
      </SectionCard>
    </Section>
  );
};
