import { ErrorReason } from "../../types/ErrorReason";

import { createError } from "./createError";

/**
 * This function is responsible for throwing an error.
 * It can be used in combination with the nullish coalescing operator.
 * An error can be provided as text of type string or by providing a separate instance of type Error.
 * The following example would throw an exception, if the requested and required value is not available. There is no need to check if the value is defined and to throw an error:
 *
 * @example
 * const requiredValue = getRequiredValue() ?? error(`Error while reading required value`);
 *
 * const requiredValue = getRequiredValue() ?? error(new NotSupportedError());
 */
export const error = (reason?: ErrorReason): never => {
  throw createError(reason);
};
