import { Routes } from "../routes/Routes";

import { useNavigate } from "react-router-dom";

/**
 * This custom hook is responsible for logging in a user, which means it navigates to the login page.
 */
export const useLogin = () => {
  const navigate = useNavigate();

  const login = () => {
    navigate(Routes.login.toPath());
  };

  return login;
};
