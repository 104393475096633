/**
 * An error that occurs if a specific app environment parameter is missing.
 */
export class NoSuchAppParameterError extends Error {
  constructor(parameterName: string) {
    super(
      `Error while getting app parameter '${parameterName}'. Parameter not found. Add parameter '${parameterName}' to config file '.env'. Restart server to apply parameter.`
    );
  }
}
