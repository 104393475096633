import { ISession } from "../shared/types/ISession";
import { IValue } from "../types/IValue";
import { SessionRepository } from "./../api/session/SessionRepository";

import { deleteLocalStorage } from "../utils/deleteLocalStorage";
import { readLocalStorage } from "../utils/readLocalStorage";
import { useState } from "react";
import { writeLocalStorage } from "../utils/writeLocalStorage";

const LOCAL_STORAGE_SESSION_KEY = "swpo.session";

/**
 * Loads the last user session from the local storage and provides it to the {@link SessionRepository}
 */
const initializeSession = () => {
  const session = readLocalStorage<ISession | undefined>(
    LOCAL_STORAGE_SESSION_KEY
  );
  SessionRepository.setSession(session);
  return session;
};

/**
 * This custom hook is responsible for providing and updating the session in the local storage.
 */
export const useSessionStorage = (): IValue<ISession | undefined> => {
  const [session, setSession] = useState<ISession | undefined>(
    initializeSession()
  );

  const updateSession = (newSession: ISession | undefined) => {
    if (newSession) {
      writeLocalStorage<ISession>(LOCAL_STORAGE_SESSION_KEY, newSession);
    } else {
      deleteLocalStorage(LOCAL_STORAGE_SESSION_KEY);
    }

    SessionRepository.setSession(newSession);
    setSession(newSession);
  };

  return { value: session, setValue: updateSession };
};
