import { IContract } from "./IContract";
import { IHavePath } from "../types/IHavePath";
import { ILicenseOption } from "./ILicenseOption";
import { IProduct } from "./IProduct";
import { IProductVersion } from "../types/IProductVersion";
import { ISystemLandscapeBundle } from "./ISystemLandscapeBundle";

export interface ISystemLandscapeSection {
  CONTRACT: IContract;
  PRODUCT: IProduct;
  LIC_OPTS: ILicenseOption[];
  LAST_PRODUCT_VERSION: IProductVersion;
  IS_LIC_OPTS_REQUIRED: boolean;
  SYSTEM_LANDSCAPE_BUNDLES: ISystemLandscapeBundle[];
}

export const SystemLandscapeSectionMeta: IHavePath = {
  path: "/system-landscape-sections",
};
