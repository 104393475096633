import { IProductExpirationMessagesProps } from "./IProductExpirationMessagesProps";
import { MessageArea } from "../../../components/messageArea/MessageArea";
import styles from "./ProductExpirationMessages.module.scss";
import { texts } from "../../../i18n/texts";
import { useProductInfo } from "../../../hooks/useProductInfo";
import useTranslation from "../../../hooks/useTranslation";

/**
 * This component is responsible for displaying messages for each given product
 * which is expired or about to expire
 */
export const ProductExpirationMessages: React.FC<
  IProductExpirationMessagesProps
> = (props) => {
  const { t } = useTranslation();
  const productInfo = useProductInfo();

  const expiredProducts = props.products.filter((product) =>
    productInfo.isExpired(product)
  );
  const aboutToExpireProducts = props.products.filter((product) =>
    productInfo.isAboutToExpire(product)
  );

  const expiredMessageText =
    expiredProducts.length === 1
      ? t(texts.productExpirationMessage.textIsExpired, {
          product: expiredProducts[0].DESCRIPTION,
        })
      : t(texts.productExpirationMessage.textMultipleExpired, {
          count: expiredProducts.length,
        });

  const aboutToExpireMessageText =
    aboutToExpireProducts.length === 1
      ? t(texts.productExpirationMessage.textIsAboutToExpire, {
          product: aboutToExpireProducts[0].DESCRIPTION,
        })
      : t(texts.productExpirationMessage.textMultipleAboutToExpire, {
          count: aboutToExpireProducts.length,
        });

  return (
    <>
      {expiredProducts.length > 0 && (
        <MessageArea
          className={styles.expireMessageArea}
          message={{
            text: expiredMessageText,
            severity: "error",
          }}
        />
      )}
      {aboutToExpireProducts.length > 0 && (
        <MessageArea
          className={styles.expireMessageArea}
          message={{
            text: aboutToExpireMessageText,
            severity: "warning",
          }}
        />
      )}
    </>
  );

  // const items = props.products.map((product, index) => {
  //   if (productInfo.isExpired(product)) {
  //     return (
  //       <MessageArea
  //         key={index}
  //         className={styles.expireMessageArea}
  //         message={{
  //           text: t(texts.productExpirationMessage.textIsExpired, {
  //             product: product.DESCRIPTION,
  //           }),
  //           severity: "warning",
  //         }}
  //       />
  //     );
  //   }

  //   if (productInfo.isAboutToExpire(product)) {
  //     return (
  //       <MessageArea
  //         key={index}
  //         className={styles.expireMessageArea}
  //         message={{
  //           text: t(texts.productExpirationMessage.textIsAboutToExpire, {
  //             product: product.DESCRIPTION,
  //           }),
  //           severity: "neutral",
  //         }}
  //       />
  //     );
  //   }

  //   return "";
  // });

  // return <>{items}</>;
};
