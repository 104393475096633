import { BreadcrumbLoader } from "../../../../decorators/BreadcrumbLoader";
import { BreadcrumbNameLoader } from "../../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoader";
import { ProductApi } from "../../../../api/ProductApi";
import { Routes } from "../../../../routes/Routes";

import { checkNotNull } from "../../../../utils/checkNotNull";

/**
 * This class is responsible for providing a name for the product breadcrumb.
 * It loads the name of the product by its id.
 */
@BreadcrumbLoader(Routes.products.toPath())
export class ProductBreadcrumbNameLoader extends BreadcrumbNameLoader {
  name = new Promise<string>(async (resolve) => {
    const id = checkNotNull(
      this.id,
      "No id provided. The loader needs an id provided in the constructor"
    );
    const description = await ProductApi.findDescriptionById(id);
    resolve(description ?? "");
  });
}
