import { ContractBasedPage } from "../features/contract/contractBasedPage/ContractBasedPage";
import { MissingURLParameterError } from "../errors/MissingURLParameterError";
import { SystemLandscapeSection } from "../features/system/systemLandscapeSection/SystemLandscapeSection";
import { checkNotNull } from "../utils/checkNotNull";
import { texts } from "../i18n/texts";
import { useParams } from "react-router-dom";
import { useSignal } from "../hooks/useSignal";
import useTranslation from "../hooks/useTranslation";
import { useState } from "react";
import { H4 } from "@snpjs/core";

/**
 * This page is responsible for displaying a product of a contract.
 */
export const ProductPage: React.FC = () => {
  const params = useParams<{ contractId: string; productId: string }>();
  const contractId = checkNotNull(
    params.contractId,
    new MissingURLParameterError("contract id")
  );
  const productId = checkNotNull(
    params.productId,
    new MissingURLParameterError("product id")
  );
  const { t } = useTranslation();
  const [searchSignal, triggerSearchSignal] = useSignal<string>();
  const [showHowToInformation, setShowHowToInformation] = useState<boolean>(false);

  const setShowInfoForCBT = (productId: String) => {
    if(productId === "CBT" || productId === "TDO") {
      setShowHowToInformation(true);
    }
  };

  const infoArea = showHowToInformation ? (
    <>
      <br />
      <br />
        <H4>{t(texts.product.infoArea.howToSetupTitle)}</H4>
        <br />
        
        <p>{t(texts.product.infoArea.howToSetup)}</p>

        <br />
        <p>
          <strong> {t(texts.product.infoArea.migrateSystemsTitle)} &lt;landscape name&gt;</strong>
          <br />
          {t(texts.product.infoArea.migrateSystemsText)}
        </p>
        <br />
        <p>
          <strong> {t(texts.product.infoArea.functionTestTitle)} &lt;landscape name&gt;</strong>
          <br />
          {t(texts.product.infoArea.functionTestText)}
        </p>
        <br />
        <p>
          <strong> {t(texts.product.infoArea.transportTrackTitle)} &lt;landscape name&gt;</strong>
          <br />
          {t(texts.product.infoArea.TransportTrackText)}
        </p>
    </>
  ) : (
    undefined
  );

  const onSearch = (query: string) => triggerSearchSignal(query);

  return (
    <ContractBasedPage
      iconName="external"
      showBreadcrumbs
      infoArea={infoArea}
      search={{
        onSearch,
        searchOnEnter: true,
      }}
    >
      <SystemLandscapeSection
        setShowInfoForCBT={setShowInfoForCBT}
        contractId={contractId}
        productObjectId={productId}
        searchSignal={searchSignal}
      />
    </ContractBasedPage>
  );
};
