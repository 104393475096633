import { ProductApi } from "../../../api/ProductApi";
import { SystemLandscapeApi } from "../../../api/SystemLandscapeApi";
import { SystemLandscapeInUseError } from "../../../api/errors/SystemLandscapeInUseError";
import { Message } from "../../../services/Message";
import { FuzzySearch } from "../../../services/fuzzySearch/FuzzySearch";
import { ISystem } from "../../../shared/model/ISystem";
import { ISystemLandscape } from "../../../shared/model/ISystemLandscape";
import { ISystemLandscapeBundle } from "../../../shared/model/ISystemLandscapeBundle";
import { ISystemLandscapeSection } from "../../../shared/model/ISystemLandscapeSection";
import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { ISystemLandscapeSectionProps } from "./ISystemLandscapeSectionProps";

import { useEffect, useState } from "react";
import { useRequest } from "../../../hooks/useRequest";
import { useSignal } from "../../../hooks/useSignal";
import { texts } from "../../../i18n/texts";
import { checkNotNull } from "../../../utils/checkNotNull";
import { clone } from "../../../utils/clone";

import useTranslation from "../../../hooks/useTranslation";

export const useSystemLandscapeSectionViewModel = (
  props: ISystemLandscapeSectionProps
) => {
  const [systemLandscapeSection, setSystemLandscapeSection] = useState<
    ISystemLandscapeSection | undefined
  >(undefined);

  const [systemLandscapeUnderDeletion, setSystemLandscapeUnderDeletion] =
    useState<ISystemLandscape | undefined>(undefined);
  const [collapseSignal, triggerCollapseSignal] = useSignal();
  const [expandSignal, triggerExpandSignal] = useSignal();
  const request = useRequest();
  const [addSystemLandscape, setAddSystemLandscape] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [systemsCollapsed, setSystemsCollapsed] = useState(0);
  const [systemsExpandible, setSystemsExpandible] = useState(false);

  useEffect(() => {
    if (props.searchSignal) {
      setQuery(props.searchSignal.value);
    }
  }, [props.searchSignal]);

  const loadSystemLandscapeSection = async () => {
    await request.send(async () => {
      setIsLoading(true);
      const systemLandscapeSection =
        await ProductApi.findSystemLandscapeSection(props.productObjectId);
      props.setShowInfoForCBT(systemLandscapeSection.PRODUCT.PRODUCT_ID);
      setSystemLandscapeSection(systemLandscapeSection);
      setIsLoading(false);
    });
  };

  const getDefaultSystemLandscapeDescription = () =>
    `${t(texts.systemLandscape.defaultSystemLandscapeName)}${
      checkNotNull(systemLandscapeSection).SYSTEM_LANDSCAPE_BUNDLES.length > 0
        ? ` ${
            checkNotNull(systemLandscapeSection).SYSTEM_LANDSCAPE_BUNDLES
              .length + 1
          }`
        : ""
    }`;

  const onAdd = async () => {
    request.send(async () => {
      setAddSystemLandscape(true);
      let systemLandscape: ISystemLandscape = {
        OBJECT_ID: "",
        DESCRIPTION: getDefaultSystemLandscapeDescription(),
        PRODUCT_ID: "",
        RELEASE: 0,
        IS_LTS: ABAPBoolean.false,
        IS_PRE_RELEASE: ABAPBoolean.false,
        SERVICE_PACK: 0,
        HOTFIX: 0,
        HAS_DOWNLOADS: ABAPBoolean.false,
        SYSTEM_ROLES: [],
        SYSTEM_REQUIREMENT_TYPES: [],
      };
      systemLandscape = await SystemLandscapeApi.addByProductId(
        props.productObjectId,
        systemLandscape
      );
      setSystemLandscapeSection((previous) => {
        const systemLandscapeSection = checkNotNull(previous);
        systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES.push({
          SYS_LANDSCAPE: systemLandscape,
          SYSTEMS: [],
          CONFLICTS: [],
        });
        return clone(systemLandscapeSection);
      });
      setAddSystemLandscape(false);
    });
  };

  const onDelete = async (systemLandscape: ISystemLandscape) => {
    setSystemLandscapeUnderDeletion(systemLandscape);
    request.send(
      async () => {
        await SystemLandscapeApi.deleteEmpty(systemLandscape);
        setSystemLandscapeSection((previous) => {
          const systemLandscapeSection = checkNotNull(previous);
          const index =
            systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES.findIndex(
              (systemLandscapeBundle) =>
                systemLandscapeBundle.SYS_LANDSCAPE === systemLandscape
            );
          if (index !== -1) {
            systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES.splice(index, 1);
          }
          return clone(systemLandscapeSection);
        });
        setSystemLandscapeUnderDeletion(undefined);
      },
      (error) => {
        setSystemLandscapeUnderDeletion(undefined);
        if (error instanceof SystemLandscapeInUseError) {
          Message.error(t(texts.systemLandscape.errorSystemLandscapeInUse));
          return true;
        }
        return false;
      }
    );
  };

  const onSave = async (systemLandscape: ISystemLandscape) => {
    let success = true;
    await request.send(
      async () => {
        await SystemLandscapeApi.update(systemLandscape);
      },
      () => {
        success = false;
        return false;
      }
    );
    return success;
  };

  /**
   * Is called when support employees toggle to unlock all licenses.
   * In that case, a rest call should update the HAS_FULL_LICENSE flag of the corresponding product
   */
  const onSetAllLicensesFlag = async (
    isFullLicense: boolean,
    reason: string
  ) => {
    try {
      const response = await ProductApi.setAllLicense(
        props.productObjectId,
        isFullLicense,
        reason
      );
      return response === ABAPBoolean.true;
    } catch {
      Message.error(t(texts.systemCard.errorAllLicensesCouldNotBeGranted));
      return !isFullLicense;
    }
  };

  /**
   * Is called when an alternative download version was set.
   * In that case, the system bundle conflicts have to be updated
   */
  const onSetAlternativeDownloadVersion = async () => {
    const systemLandscapeSection = await ProductApi.findSystemLandscapeSection(
      props.productObjectId
    );
    setSystemLandscapeSection((previous) => {
      if (previous === undefined) {
        return;
      }

      previous.SYSTEM_LANDSCAPE_BUNDLES.forEach((systemLandscapeBundle) => {
        const systemLandscapeBundleTemp =
          systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES.find(
            (item) =>
              item.SYS_LANDSCAPE.OBJECT_ID ===
              systemLandscapeBundle.SYS_LANDSCAPE.OBJECT_ID
          );
        if (systemLandscapeBundleTemp) {
          systemLandscapeBundle.CONFLICTS = systemLandscapeBundleTemp.CONFLICTS;
        }
      });

      return clone(previous);
    });
  };

  const sortSystemLandscapes = (
    systemlandscapes: ISystemLandscapeBundle[]
  ): ISystemLandscapeBundle[] => {
    return systemlandscapes;
    //think about this later
    // setSystemLandscapeSection((prevState) => {
    //   if (!prevState) {
    //     return prevState; // or handle the undefined case appropriately
    //   }
    //   return {
    //     ...prevState,
    //     SYSTEM_LANDSCAPE_BUNDLES: systemlandscapes,
    //   };
    // });
    /*
    let sortedSystemLandscapes: ISystemLandscapeBundle[] = [];
    let sorted:{[key: string]: ISystemLandscapeBundle} = {};
    let sortedNames: string[] = [];
    for(let i = 1; i < systemlandscapes.length; i++) {
      sorted[systemlandscapes[i].SYS_LANDSCAPE.DESCRIPTION] = systemlandscapes[i];
      sortedNames.push(systemlandscapes[i].SYS_LANDSCAPE.DESCRIPTION);
    }
    sortedNames.sort();
    for(let i = 0; i < sortedNames.length; i++) {
      sortedSystemLandscapes[i] = sorted[sortedNames[i]];
    }
    return sortedSystemLandscapes;
    */
  };

  const filterSystemLandscapeBundles = (): ISystemLandscapeBundle[] => {
    if (!systemLandscapeSection?.SYSTEM_LANDSCAPE_BUNDLES) {
      return [];
    }

    // If no search query was provided, return current bundles
    if (query.length === 0) {
      return sortSystemLandscapes(
        systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES
      );
    }

    // filter bundles
    const systemLandscapeBundles: ISystemLandscapeBundle[] = [];
    systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES.forEach(
      (systemLandscapeBundle) => {
        const fuzzySearch = new FuzzySearch<ISystem>();
        const systems = fuzzySearch.search(
          query,
          systemLandscapeBundle.SYSTEMS
        );
        if (systems.length > 0) {
          systemLandscapeBundles.push({
            CONFLICTS: systemLandscapeBundle.CONFLICTS,
            SYS_LANDSCAPE: systemLandscapeBundle.SYS_LANDSCAPE,
            SYSTEMS: systems,
          });
        }
      }
    );
    return sortSystemLandscapes(systemLandscapeBundles);
  };

  const onCollapseAllSystems = () => {
    if (systemLandscapeSection) {
      const totalSystemsCount =
        systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES.reduce(
          (count, bundle) => count + bundle.SYSTEMS.length,
          0
        );
      setSystemsCollapsed(totalSystemsCount);
    }
    triggerCollapseSignal();
  };

  const onExpandAllSystems = () => {
    setSystemsCollapsed(0);
    triggerExpandSignal();
  };

  const onCollapseSingleSystem = () => {
    setSystemsCollapsed((prevValue) => prevValue + 1);
  };

  const onExpandSingleSystem = () => {
    setSystemsCollapsed((prevValue) => prevValue - 1);
  };

  useEffect(() => {
    if (systemLandscapeSection) {
      const totalSystemsCount =
        systemLandscapeSection.SYSTEM_LANDSCAPE_BUNDLES.reduce(
          (count, bundle) => count + bundle.SYSTEMS.length,
          0
        );
      if (systemsCollapsed === 0) {
        setSystemsExpandible(false);
      } else if (systemsCollapsed === totalSystemsCount) {
        setSystemsExpandible(true);
      }
    }
  }, [systemsCollapsed]);

  return {
    systemsExpandible,
    addSystemLandscape,
    collapseSignal,
    expandSignal,
    filterSystemLandscapeBundles,
    loadSystemLandscapeSection,
    isLoading,
    onAdd,
    onDelete,
    onSave,
    onSetAllLicensesFlag,
    onSetAlternativeDownloadVersion,
    systemLandscapeSection,
    systemLandscapeUnderDeletion,
    onCollapseAllSystems,
    onExpandAllSystems,
    onCollapseSingleSystem,
    onExpandSingleSystem,
  };
};
