import { Detail } from "../../../components/detail/Detail";
import { ISystemDownloadHistory } from "../../../shared/model/ISystemDownloadHistory";
import { ISystemDownloadHistoryProps } from "./ISystemDownloadHistoryProps";
import { VersionDetails } from "../../versionDetails/versionDetails/VersionDetails";
import { isVersionInitial } from "../../../utils/version/isVersionInitial";
import styles from "./SystemDownloadHistory.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";

/**
 * This component is responsible for displaying information about the last download for a system.
 * If no software was downloaded for a system yet, a default message is displayed.
 */
export const SystemDownloadHistory: React.FC<ISystemDownloadHistoryProps> = (
  props
) => {
  const { t } = useTranslation();

  const renderContent = (systemDownloadHistory: ISystemDownloadHistory) => (
    <div className={styles.details}>
      <VersionDetails version={systemDownloadHistory} />
      <Detail
        title={t(texts.systemDownloadHistory.titleDownloadedAt)}
        value={systemDownloadHistory.CREATION_DATE}
      />
    </div>
  );

  return (
    <div className={styles.systemDownloadHistory}>
      <h4 className={styles.headline}>
        {t(texts.systemDownloadHistory.downloadHistoryTitle)}
      </h4>
      {props.systemDownloadHistory &&
      !isVersionInitial(props.systemDownloadHistory)
        ? renderContent(props.systemDownloadHistory)
        : t(texts.systemDownloadHistory.messageNotDownloadedYet)}
    </div>
  );
};
