import { ClassType } from "../types/ClassType";
import { DecoratorInfo } from "../services/DecoratorInfo";

/**
 * This decorator is responsible for providing a path information of a breadcrumb loader.
 * To get the path from the decorated class use the {@link DecoratorInfo}.
 *
 * @example
 *
 * /@BreadcrumbLoader("/users")
 * export class Test {}
 *
 * const path = DecoratorInfo.find(Test, BreadcrumbLoader)
 */
export const BreadcrumbLoader = <T extends ClassType<any>>(path: string) => {
  return (target: T) => {
    DecoratorInfo.introduce(target, BreadcrumbLoader, path);
  };
};
