import { ReactNode } from "react";

/**
 * This function is responsible to split the given {@link text} at the given {@link separator} and to separate each text element as separate line.
 */
export const splitTextToNewLine = (
  text: string,
  separator: string = "\n"
): ReactNode => {
  return (
    <>
      {text.split(separator).map((text, index) => (
        <div key ={index}>{text}</div>
      ))}
    </>
  );
};
