import { ReactNode, useCallback, useEffect, useState } from "react";

import { IBreadcrumbProps } from "./IBreadcrumbProps";
import { Icon } from "@snpjs/core";
import { NotSupportedError } from "../../../errors/NotSupportedError";
import { request } from "../../../utils/request";
import { style } from "../../../utils/style";
import styles from "./Breadcrumb.module.scss";
import { useNavigate } from "react-router-dom";

/**
 * This component is a single breadcrumb for the {@link Breadcrumb} component.
 */
export const Breadcrumb: React.FC<IBreadcrumbProps> = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string | ReactNode>();

  const loadName = useCallback(
    () =>
      request(async () => {
        if (props.breadcrumb.name === undefined) {
          return;
        }
        switch (typeof props.breadcrumb.name) {
          case "object": {
            const name = await props.breadcrumb.name;
            setName(name);
            break;
          }
          case "string": {
            setName(props.breadcrumb.name as string);
            break;
          }
          default:
            new NotSupportedError();
        }
      }),
    [props.breadcrumb.name]
  );

  useEffect(() => {
    loadName();
  }, [loadName]);

  return (
    <li
      className={style(
        styles.breadcrumb,
        props.isLastBreadcrumb ? styles.lastBreadcrumb : ""
      )}
      onClick={() =>
        !props.isLastBreadcrumb && navigate(props.breadcrumb.route)
      }
    >
      <div className={styles.breadcrumbWrapper}>
        {props.breadcrumb.iconName && <Icon icon={props.breadcrumb.iconName} />}
        {name}
      </div>
    </li>
  );
};
