import { Hint } from "../../components/hint/Hint";
import styles from "./SupportTicketInfo.module.scss";
import { texts } from "../../i18n/texts";
import { useConfig } from "../../hooks/useConfig";
import useTranslation from "../../hooks/useTranslation";

export const SupportTicketInfo = () => {
  const { t } = useTranslation();
  const config = useConfig();

  return (
    <>
      <div className={styles.hintList}>
        <Hint
          text={t(texts.supportTicketInfo.explanation, {
            link: config.SUPPORT_PORTAL_LINK,
          })}
        />
        <Hint
          title={t(texts.supportTicketInfo.customerActivitySection.title)}
          text={t(texts.supportTicketInfo.customerActivitySection.text)}
        />
        <Hint
          title={t(texts.supportTicketInfo.usedSoftware.title)}
          text={t(texts.supportTicketInfo.usedSoftware.text)}
        />
        <Hint
          title={t(texts.supportTicketInfo.yourQuestion.title)}
          text={t(texts.supportTicketInfo.yourQuestion.text)}
        />
        <Hint
          title={t(texts.supportTicketInfo.description.title)}
          text={t(texts.supportTicketInfo.description.text)}
        />
      </div>
    </>
  );
};
