import { ISingleSelectionDropDownProps } from "./ISingleSelectionDropDownProps";
import { ItemPredicate, ItemRenderer, Select } from "@snpjs/select";
import { Button, Colors, MenuItem } from "@snpjs/core";
import React, { useEffect, useMemo, useRef } from "react";
import styles from "./SingleSelectionDropDown.module.scss";

export function SingleSelectionDropDown<T>(props: ISingleSelectionDropDownProps<T>) {

  const findActive = (): T | undefined => {
    for (const object of props.objects) {
      if (props.isSelected(object)) {
        return object;
      }
    }
    return undefined;
  };
  

  useEffect(() => {
    if(props.editMode === true && findActive()){
      setSelectedObject(findActive());
    }
  }, [props.editMode])

  const [selectedObject, setSelectedObject] = React.useState<T | undefined>(findActive());
  const previousObjectRef = useRef<T | undefined>(findActive());

  const disabledStyle = {
    backgroundColor: Colors.MONOCHROME_GRAY3,
    color: Colors.BLACK,
    cursor: "auto"
  };

  const filter: ItemPredicate<T> = (query, object, _index, exactMatch) => {
    const normalizedTitle = props.getName(object).toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${props.getName(object).toLowerCase()}. ${props.getObjectId(object).toLowerCase()} 
              ${props.getInternalId(object).toLowerCase()}`.indexOf(normalizedQuery) >= 0;
    }
  };

  const setObject = (object: T | undefined) => {
    setSelectedObject(object);
    props.onSelectionChanged(object, previousObjectRef.current);
  }

  const render: ItemRenderer<T> = (object, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={props.getObjectId(object)}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={`${props.getName(object)}`}
      />
    );
};
  const buttonText = selectedObject ? props.getName(selectedObject) : "No selection";
  const buttonTextActive = useMemo(() => findActive() ? props.getName(findActive()!) : "No selection", [findActive, props.objects]);
    return (
      <div className={styles.button}>
        <Select<T>
          filterable={true}
          items={props.objects}
          itemPredicate={filter}
          itemRenderer={render}
          noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
          onItemSelect={setObject}
        >
        {props.editMode ?
        (
          <Button text={buttonText} fill={true} rightIcon="caret-down" />
        ) : (
          <Button style={disabledStyle} disabled={!props.editMode} text={buttonTextActive} fill={true} />
        )}
        </Select>
      </div>
    );
}
