import { DownloadVersionDialog } from "../downloadVersionDialog/DownloadVersionDialog";
import { ICommand } from "../../../types/ICommand";
import { IDownloadVersion } from "../../../shared/model/IDownloadVersion";
import { IHotfix } from "../../../shared/model/IHotfix";
import { IUseSetSystemLandscapeVersionCommandProps } from "./IUseSetSystemLandscapeVersionCommandProps";
import { SystemLandscapeApi } from "../../../api/SystemLandscapeApi";
import { texts } from "../../../i18n/texts";
import { useRequest } from "../../../hooks/useRequest";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";

/**
 * A special support command, to set the version for all systems of a system landscape.
 */
export const useSetSystemLandscapeVersionCommand = (
  props: IUseSetSystemLandscapeVersionCommandProps
): ICommand => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const request = useRequest();

  const onCancel = () => {
    setShow(false);
  };

  const onOkay = async (downloadVersion: IDownloadVersion) => {
    await request.send(async () => {
      props.systemLandscape.RELEASE = downloadVersion.RELEASE;
      props.systemLandscape.SERVICE_PACK = downloadVersion.SERVICE_PACK;
      props.systemLandscape.IS_LTS = downloadVersion.IS_LTS;
      await SystemLandscapeApi.setSystemsDownloadVersion(downloadVersion);
      await props.onOkay?.();
      setShow(false);
    });
  };

  const createHotfix = (): IHotfix | undefined =>
    props.hotfix
      ? {
          HOTFIX: props.hotfix,
        }
      : undefined;

  const view = show && (
    <DownloadVersionDialog
      hotfix={createHotfix()}
      isVersionChangeable={props.isVersionChangeable ?? false}
      isOpen={show}
      OBJECT_ID={props.systemLandscape.OBJECT_ID}
      onCancel={onCancel}
      onOkay={onOkay}
      productId={props.productId}
      subTextTranslation={{
        text: texts.setSystemLandscapeVersionCommand.subTitle,
      }}
      title={t(texts.systemSetDownloadVersionCommand.title)}
    />
  );

  const execute = () => {
    setShow(true);
  };

  return {
    execute,
    view,
  };
};
