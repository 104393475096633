import { BreadcrumbLoader } from "../../../../decorators/BreadcrumbLoader";
import { BreadcrumbNameLoader } from "../../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoader";
import { Routes } from "../../../../routes/Routes";

import { texts } from "../../../../i18n/texts";

@BreadcrumbLoader(Routes.excerptConflictManagement.toPath())
export class ConflictManagementBreadcrumbNameLoader extends BreadcrumbNameLoader {
  name = texts.conflictManagement.breadcrumb;
}
