import { IConflictingSystemRequirements } from "../../shared/types/IConflictingSystemRequirements";
import { IHaveResponseEnvelope } from "../types/IHaveResponseEnvelope";
import { IResponseEnvelope } from "../../shared/types/IResponseEnvelope";
import { ResponseEnvelopeFactory } from "../services/ResponseEnvelopeFactory";
import { RESTError } from "../core/RESTError";

/**
 * An error that occurs when saving system requirements for a system,
 * while the system requirements are not in sync with the system requirements of systems with the same install number
 */
export class SystemRequirementsOutOfSyncError
  extends RESTError
  implements IHaveResponseEnvelope<IConflictingSystemRequirements>
{
  get responseEnvelope(): IResponseEnvelope<IConflictingSystemRequirements> {
    const responseEnvelopeFactory = new ResponseEnvelopeFactory();
    return responseEnvelopeFactory.createFromReason(this.originError.REASON);
  }
}
