import { useCallback, useEffect } from "react"; 
import { IAllLicenseProps } from "./IAllLicenseProps";
import { SystemActionLog } from "../systemActionLog/SystemActionLog";
import { ValidationError } from "../../../errors/ValidationError";
import { ValidationHandler } from "../../../types/ValidationHandler";
import styles from "./AllLicense.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";
import { useValidatorConsumer } from "../../../hooks/useValidatorConsumer";
import { useValidatorProvider } from "../../../hooks/useValidatorProvider";

/**
 * This component is responsible for displaying release, service pack and hotfix to set an alternative download version.
 * And addition the user has to provide add a reason for changing the version.
 */
export const AllLicense: React.FC<IAllLicenseProps> = (props) => {
  const { t } = useTranslation();
  const actionLogValidator = useValidatorConsumer();

  const onValidate: ValidationHandler = useCallback(() => {
    // Call both validators for printing error messages
    const actionLogValid = actionLogValidator.isValid();
    if (!actionLogValid) {
      throw new ValidationError();
    }
  }, [actionLogValidator]);
  
  const validator = useValidatorProvider(onValidate);
  useEffect(() => {
    props.requestValidator?.(validator);
  }, [props.requestValidator, validator]);

  return (
      <div className={styles.allLicense}>
        {props.subTextTranslation && <div>{t(props.subTextTranslation)}</div>}
        <div>
          <SystemActionLog
            isReasonRequired={true}
            label={t(texts.systemDownloadVersion.labelTextArea)}
            requestValidator={actionLogValidator.onRequestValidator}
            setText={props.setReason}
            text={props.reason}
          />
        </div>
      </div>
  );
};
