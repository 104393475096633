import { Dialog } from "../../../components/dialog/Dialog";
import { DialogFooter } from "../../../components/dialogFooter/DialogFooter";
import { useState } from "react";
import { useValidatorConsumer } from "../../../hooks/useValidatorConsumer";
import { IRequestEmergencyDialogProps } from "./IRequestEmergencyLicenseDialogProps";
import { EmergencyLicense } from "../EmergencyLicense/EmergencyLicense";

export const RequestEmergencyLicenseDialog: React.FC<IRequestEmergencyDialogProps> = (
  props
) => {
  const validator = useValidatorConsumer();
  const [isConfirming, setIsConfirming] = useState(false);
  const [reason, setReason] = useState("");

  const onOkay = async () => {
    setIsConfirming(true);
    if (!validator.isValid()) {
      setIsConfirming(false);
      return;
    }
    const success =  await props.onOkay(reason)
    setIsConfirming(false);
    return success
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      title={props.title}
      footer={
        <DialogFooter
          isConfirming={isConfirming}
          onCancel={props.onCancel}
          onOkay={onOkay}
        />
      }
    >
      <EmergencyLicense
        reason={reason}
        setReason={setReason}
        requestValidator={validator.onRequestValidator}
        subTextTranslation={props.subTextTranslation}

      />
    </Dialog>
  );
};
