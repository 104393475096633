import { ContractBasedPage } from "../features/contract/contractBasedPage/ContractBasedPage";
import { UserAuthRoleSection } from "../features/userAuthRole/userAuthRoleSection/UserAuthRoleSection";
import { texts } from "../i18n/texts";
import { useRouteParam } from "../hooks/useRouteParam";
import useTranslation from "../hooks/useTranslation";

/**
 * This page is responsible for displaying the assigned users with its assigned authority roles of a contract.
 */
export const UserAuthRolePage: React.FC = () => {
  const contractId = useRouteParam<string>("contractId");
  const { t } = useTranslation();

  const infoArea = (
    <>
      <br />
      <br />
      <p>{t(texts.userManagement.infoArea.description)}</p>
      <p>{t(texts.userManagement.infoArea.infoMessage)}</p>
    </>
  );

  return (
    <ContractBasedPage iconName="user" showBreadcrumbs infoArea={infoArea}>
      <UserAuthRoleSection contractId={contractId} />
    </ContractBasedPage>
  );
};
