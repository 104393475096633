import { IRelease } from "../../shared/model/IRelease";
import { IVersion } from "../../shared/types/IVersion";

import { filterLowerServicePacks } from "./filterLowerServicePacks";

export const filterLowerReleases = (
  version: IVersion,
  releases: IRelease[]
): IRelease[] => {
  const resultList: IRelease[] = [];

  for (const release of releases) {
    // skip releases, which are lower than my
    if (release.RELEASE < version.RELEASE) {
      continue;
    }

    // add releases, which are higher than my
    if (release.RELEASE > version.RELEASE) {
      resultList.push(release);
      continue;
    }

    // release is equal, filter service packs, which are lower than my
    const servicePacks = filterLowerServicePacks(
      version,
      release.SERVICE_PACKS
    );
    if (servicePacks.length === 0) {
      continue;
    }
    release.SERVICE_PACKS = servicePacks;
    resultList.push(release);
  }

  return resultList;
};
