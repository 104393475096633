import { AuthRole } from "./types/AuthRole";

import { configAuth } from "./utils/configAuth";

/**
 * Configure the authorities by providing the actions and by assigning the required auth roles.
 */
export const AuthConfig = configAuth({
  canAddUser: [
    AuthRole.ADMIN,
    AuthRole.SUPPORT,
    AuthRole.PRINCIPAL,
    AuthRole.CONTRACT_OWNER,
    AuthRole.PROJECT_LEAD,
    AuthRole.ACCOUNT_MANAGER,
  ],
  canEditUserAuthRoles: [
    AuthRole.ADMIN,
    AuthRole.SUPPORT,
    AuthRole.PRINCIPAL,
    AuthRole.CONTRACT_OWNER,
    AuthRole.PROJECT_LEAD,
    AuthRole.ACCOUNT_MANAGER,
  ],
  canSetAlternativeSoftwareVersion: [AuthRole.ADMIN],
  canShareContract: [
    AuthRole.ADMIN,
    AuthRole.SUPPORT,
    AuthRole.PRINCIPAL,
    AuthRole.CONTRACT_OWNER,
    AuthRole.PROJECT_LEAD,
    AuthRole.ACCOUNT_MANAGER,
  ],
  canEditCOAndSO: [
    AuthRole.ADMIN,
    AuthRole.SUPPORT,
    AuthRole.PRINCIPAL,
    AuthRole.PROJECT_LEAD,
    AuthRole.ACCOUNT_MANAGER,
  ],
  //systemDetails are a techy-view, only intended for internal use
  canDisplaySystemDetails: [],
  canFixMultipleSystemConflictErrors: [],
});
