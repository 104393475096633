import { ISession } from "../../shared/types/ISession";

/**
 * This implementation is responsible for providing access to the user session specific properties.
 */
class SessionRepositoryDefault {
  private _contractId: string | undefined = undefined;
  private _session: ISession | undefined = undefined;

  get contractId(): string | undefined {
    return this._contractId;
  }

  get session(): ISession | undefined {
    return this._session;
  }

  setContractId(contractId: string | undefined) {
    this._contractId = contractId;
  }

  setSession(session: ISession | undefined) {
    this._session = session;
  }
}

export const SessionRepository = new SessionRepositoryDefault();
