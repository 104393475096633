import { AuthRole } from "../../auth/types/AuthRole";
import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";

export interface IUser extends IEntity {
  USER_ID: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  EMAIL_ADDRESS: string;
  INT_AUTH_ROLE_IDS: AuthRole[];
}

export const UserMeta: IEntityMeta<IUser> = { path: "/users" };
