import { AuthActionPool } from "../types/AuthActionPool";
import { AuthRoles } from "../types/AuthRoles";
import { SuperUserRoles } from "../AuthConfigSuperUser";
import { ThisAuthConfig } from "../types/ThisAuthConfig";

/**
 * Function that checks, if the given {@link authRolesOfUser} containing a super user role.
 */
const isSuperUser = (authRolesOfUser: AuthRoles): boolean => {
  for (const authRole of authRolesOfUser) {
    for (const superAuthRole of SuperUserRoles) {
      if (authRole === superAuthRole) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Function that creates an object of type {@link AuthActionPool}.
 * It contains functions to check authorities of the contained actions.
 * The actions and the roles to be checked base on the given {@link config}.
 *
 * To directly access the properties of {@link config}, we introduce the generics type {@link T} which extends {@link ThisAuthConfig}.
 * This means {@link T} contains a concrete variant of {@link ThisAuthConfig}, so there is no need to cast it to type `any` within the function implementation.
 *
 * The parameter {@link authRolesOfUser} contains all roles, that are assigned to the current user. Which means, an authority check will verify that the current user has at least one of a role that is required for an action.
 *
 * @example
 * // 1. the user could create a system, as auth role SUPPORT is required
 * // 2. the user couldn't delete a system, as auth role ADMIN is required
 * // 2. the user could update a system, as auth role ADMIN or SUPPORT is required
 *
 * export const test = createAuthChecks(
 *   {
 *     canCreateSystem: [AuthRoleType.SUPPORT],
 *     canDeleteSystem: [AuthRoleType.ADMIN],
 *     canUpdateSystem: [AuthRoleType.ADMIN, AuthRoleType.SUPPORT],
 *   },
 *   [AuthRoleType.SUPPORT]
 * );
 */
export const createAuthChecks = <T extends ThisAuthConfig>(
  config: T,
  authRolesOfUser: AuthRoles
): AuthActionPool => {
  // Iterate over the config properties and create functions instead
  const authCheck = {} as AuthActionPool;
  for (const propName in config) {
    // provide the authority check function, which can be called from outside
    // Inside we define the auth roles, which must be checked and which are derived from the given config.
    (authCheck as any)[propName] = () => {
      if (isSuperUser(authRolesOfUser)) {
        return true;
      }

      const authRoles = config[propName] as AuthRoles;
      for (const authRole of authRoles) {
        // check if user has authRole
        const containsAuthRole = authRolesOfUser.findIndex(
          (authRoleOfUser) => authRoleOfUser === authRole
        );

        // if user has at least one role, the user is authorized for this action
        if (containsAuthRole !== -1) {
          return true;
        }
      }
      return false;
    };
  }
  return authCheck;
};
