import { ABAPBoolean } from "../shared/types/ABAPBoolean";
import { IRelease } from "../shared/model/IRelease";
import { IServicePack } from "../shared/model/IServicePack";

/**
 * Others Release, if nothing else matches
 * in case of customer wants an EOL release version
 */
export class OthersRelease implements IRelease {
  readonly SERVICE_PACKS: IServicePack[] = [];
  readonly RELEASE: number = 0;
  readonly IS_LTS: ABAPBoolean = ABAPBoolean.false;
  readonly IS_PRE_RELEASE: ABAPBoolean = ABAPBoolean.false;
}
