import { AccessCodeRequestMeta } from "../shared/model/IAccessCodeRequest";
import { AuthRole } from "../auth/types/AuthRole";
import { ContractMeta } from "../shared/model/IContract";
import { IAccessCodeRequest } from "../shared/model/IAccessCodeRequest";
import { IContract } from "../shared/model/IContract";
import { ICredentials } from "../shared/types/ICredentials";
import { ISession } from "../shared/types/ISession";
import { IShareContractRequest } from "../shared/model/IShareContractRequest";
import { IUser } from "./../shared/model/IUser";
import { LoginMeta } from "../shared/model/ILogin";
import { LogoutMeta } from "./../shared/model/ILogout";
import { Repository } from "./core/Repository";
import { ShareContractRequestMeta } from "../shared/model/IShareContractRequest";
import { UserMeta } from "../shared/model/IUser";

class UserApiDefault extends Repository<IUser> {
  constructor() {
    super(UserMeta.path);
    this.TEST_MODE = true;
  }

  /**
   * Returns the users authority roles for a specific contract.
   */
  getAuthRolesByContractId(
    session: ISession,
    contractId: string
  ): Promise<AuthRole[]> {
    return this.get(
      `${this.url}/${session.USER.USER_ID}${ContractMeta.path}/${contractId}`
    );
  }

  /**
   * Returns contracts which are assigned to the current user
   */
  getContracts(session: ISession): Promise<IContract[]> {
    return this.get(`${this.url}/${session.USER.USER_ID}${ContractMeta.path}`);
  }

  /**
   * Login the user by the given {@link email} and {@link accessCode} and return a {@link ISession}.
   */
  login(email: string, accessCode: string): Promise<ISession> {
    const credentials: ICredentials = { EMAIL: email, AUTH_CODE: accessCode };
    return this.post(`${this.url}${LoginMeta.path}`, credentials);
  }

  /**
   * Request to logout the current user.
   * The current user is identified via its token, which is send with each request
   */
  logout(): Promise<boolean> {
    return this.post(`${this.url}${LogoutMeta.path}`, undefined);
  }

  /**
   * Request a new access code for the user with the given {@link email}.
   */
  requestAccessCode(email: string): Promise<boolean> {
    const accessCodeRequest: IAccessCodeRequest = { EMAIL: email };
    return this.post(
      `${this.url}${AccessCodeRequestMeta.path}`,
      accessCodeRequest
    );
  }

  /**
   * Shares the contract of {@link contractId} with a user with the given {@link email}.
   */
  share(contractId: string, email: string): Promise<boolean> {
    const shareContractRequest: IShareContractRequest = { EMAIL: email };
    return this.post(
      `${this.host}${ContractMeta.path}/${contractId}${ShareContractRequestMeta.path}`,
      shareContractRequest
    );
  }
}

/**
 * This class is responsible for handling all {@link IUser} specific REST requests.
 */
export const UserApi = new UserApiDefault();
