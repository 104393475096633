import { IHaveDescription } from "../../types/have/IHaveDescription";
import { ABAPBoolean } from "../types/ABAPBoolean";
import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";
import { IVersion } from "../types/IVersion";
import { ISystemRequirementType } from "./ISystemRequirementType";
import { ISystemRole } from "./ISystemRole";

export interface ISystemLandscape extends IEntity, IHaveDescription, IVersion {
  PRODUCT_ID: string;
  HAS_DOWNLOADS: ABAPBoolean;
  SYSTEM_ROLES: ISystemRole[];
  SYSTEM_REQUIREMENT_TYPES: ISystemRequirementType[];
}

export const SystemLandscapeMeta: IEntityMeta<ISystemLandscape> = {
  path: "/systemLandscapes",
};
