import { Alignment, Button, Divider } from "@snpjs/core";

import { IProductsProps } from "./IProductsProps";
import { ProductExpirationOverviewPopover } from "../../productExpiration/productExpirationOverviewPopover/ProductExpirationOverviewPopover";
import styles from "./Products.module.scss";
import { useProductInfo } from "../../../hooks/useProductInfo";

/**
 * This component is responsible for displaying all purchased products of a customer.
 */
export const Products: React.FC<IProductsProps> = (props) => {
  const productInfo = useProductInfo();

  const items = props.products?.map((product) => (
    <Button
      color="black"
      key={product.OBJECT_ID}
      fill={true}
      intent={
        props.highlightedProductIds?.get(product.PRODUCT_ID)
          ? "success"
          : productInfo.isExpired(product)
          ? "danger"
          : productInfo.isAboutToExpire(product)
          ? "warning"
          : "primary"
      }
      alignText={Alignment.LEFT}
      onClick={() => props.onProductSelect?.(product.OBJECT_ID)}
    >
      {product.DESCRIPTION}
    </Button>
  ));

  return (
    <>
      <Divider />
      <div className={styles.titleContainer}>
        <h4 className={styles.title}>{props.description}</h4>
        <ProductExpirationOverviewPopover products={props.products ?? []} />
      </div>
      <div className={styles.gridContainer}>{items}</div>
    </>
  );
};
