import { AuthRoleApi } from "../../../api/AuthRoleApi";
import { IAuthRole } from "../../../shared/model/IAuthRole";
import { IEntityDetails } from "../../../shared/types/IEntityDetails";
import { IUserAuthRole } from "../../../shared/model/IUserAuthRole";
import { IUserAuthRoleSectionProps } from "./IUserAuthRoleSectionProps";
import { UserAuthRoleApi } from "../../../api/UserAuthRoleApi";

import { useAuth } from "../../../hooks/useAuth";
import { useRequest } from "../../../hooks/useRequest";
import { useState } from "react";
import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";

export const useUserAuthRoleSectionViewModel = (
  props: IUserAuthRoleSectionProps
) => {
  const [displayedAuthRoles, setDisplayedAuthRoles] = useState<IAuthRole[]>([]);
  const request = useRequest();
  const auth = useAuth();

  const findByContractId = () => {
   return UserAuthRoleApi.findByContractId(props.contractId);
  }

  const initialLoad = async () => {
    await request.send(async () => {
      let authRoles = await AuthRoleApi.findAll();
      // filters the displayed roles for non-global users.
      if(!isGlobalUser()){
        authRoles = authRoles.filter((role) =>
          role.USER_ROLE_INTERNAL === ABAPBoolean.false)
      }
      setDisplayedAuthRoles(authRoles);
    });
  };

  const onSave = (entity: IEntityDetails<IUserAuthRole>) => {
    return UserAuthRoleApi.updateByContractId(props.contractId, entity);
  };

  const onDelete = (entity: IEntityDetails<IUserAuthRole>) =>
    UserAuthRoleApi.deleteByContractIdAndEmail(
      props.contractId,
      entity.EMAIL_ADDRESS
    );

  const onUpdate = onSave;

  const canAddUser = () => {
    return auth.canAddUser();
  };
  
  const isGlobalUser = () => {
    return auth.isGlobalUser();
  };

  return {
    displayedAuthRoles,
    canAddUser,
    findByContractId,
    initialLoad,
    onDelete,
    onSave,
    onUpdate,
    isGlobalUser
  };
};
