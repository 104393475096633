import { InputGroup, Popover } from "@snpjs/core";

import { ISearchProps } from "./ISearchProps";
import { LoadingSpinnerButton } from "../loadingSpinnerButton/LoadingSpinnerButton";
import styles from "./Search.module.scss";
import { texts } from "../../i18n/texts";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";

export const Search: React.FC<ISearchProps> = (props) => {
  const [query, setQuery] = useState("");
  const { t } = useTranslation();

  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && props.searchOnEnter === true) {
      onSearch();
    }
  };

  const onSearch = () => {
    props.onSearch?.(query);
  };

  return (
    <div className={styles.search}>
      <Popover>
        <InputGroup
          onChange={onQueryChange}
          onKeyDown={onKeyDown}
          type="text"
          placeholder={t(texts.search.searchPlaceholder)}
          value={query}
        />
      </Popover>
      <LoadingSpinnerButton
        className={styles.searchButton}
        buttonClassNames={styles.searchButton}
        icon="magnifying-glass"
        onClick={onSearch}
        outlined
        showSpinner={props.displaySearchSpinner ?? false}
      />
    </div>
  );
};
