import { ISolution } from "../../../../components/questionWizard/ISolution";

import { texts } from "../../../../i18n/texts";

export class OpenSupportTicketSolution implements ISolution {
  // TODO: reaplace openSupportTicketMock with openSupportTicket as soon as Conflict Management is completely implemented 
  title: string = texts.conflictWizard.solutions.openSupportTicketMock.title;
  effect: string = texts.conflictWizard.solutions.openSupportTicketMock.effect;
  isExpertSolution: boolean = false;
}
