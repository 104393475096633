import { Dialog } from "../../../components/dialog/Dialog";
import { DialogFooter } from "../../../components/dialogFooter/DialogFooter";
import { useState } from "react";
import { useValidatorConsumer } from "../../../hooks/useValidatorConsumer";
import { ISetAllLicenseDialogProps } from "./ISetAllLicenseDialog";
import { AllLicense } from "../AllLicense/AllLicense";

export const SetAllLicenseDialog: React.FC<ISetAllLicenseDialogProps> = (
  props
) => {
  const validator = useValidatorConsumer();
  const [isConfirming, setIsConfirming] = useState(false);

  const [reason, setReason] = useState("");
  // const [isReasonMissing, setIsReasonMissing] =
  //   useState<boolean>(false);

  const onOkay = async () => {
    setIsConfirming(true);
    if (!validator.isValid()) {
      setIsConfirming(false);
      return;
    }
    // const downloadVersion = createDownloadVersion();
    // await props.onOkay(downloadVersion);
    // props.setReason(reason)
    props.reasonRef.current = reason;
    const success =  await props.onOkay()
    setIsConfirming(false);
    return success
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      title={props.title}
      footer={
        <DialogFooter
          // disableConfirmButton={isReasonMissing}
          isConfirming={isConfirming}
          onCancel={props.onCancel}
          onOkay={onOkay}
        />
      }
    >
      <AllLicense
        reason={reason}
        requestValidator={validator.onRequestValidator}
        setReason={setReason}
        subTextTranslation={props.subTextTranslation}

      />
    </Dialog>
  );
};
