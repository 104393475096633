import { AsyncLoad } from "../asyncLoad/AsyncLoad";
import { AuthRole } from "../../auth/types/AuthRole";
import { AuthRoleContext } from "../../context/AuthRoleContext";
import { IAuthRoleProviderProps } from "./IAuthRoleProviderProps";
import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars

/**
 * This component is responsible for providing a frame to load authority roles,
 * which can be checked via hook {@link useAuth}.
 */
export const AuthRoleProvider: React.FC<IAuthRoleProviderProps> = (props) => {
  const [authRoles, setAuthRoles] = useState<AuthRole[]>([]);

  return (
    <AuthRoleContext.Provider value={{ authRoles, setAuthRoles }}>
      <AsyncLoad
        load={async () => {
          const authRoles = await props.loadAuthRoles();
          setAuthRoles(authRoles);
        }}
      >
        {props.children}
      </AsyncLoad>
    </AuthRoleContext.Provider>
  );
};
