import { Message } from "../services/Message";

import { texts } from "../i18n/texts";

import useTranslation from "./useTranslation";

/**
 * This hook is responsible to handle unexpected errors in a standardized way.
 */
export const useHandleUnexpectedError = () => {
  const { t } = useTranslation();

  const handle = (error: any) => {
    console.log(`Error while sending REST request.`);
    console.log(error);
    if(!error.message){
      Message.error(t(texts.general.errorUnexpectedError));
    }
    else{
      Message.error(error.message);
    }
  };

  return { handle };
};
