import { AsyncLoad } from "../../../components/asyncLoad/AsyncLoad";
import { LoadingSpinnerButton } from "../../../components/loadingSpinnerButton/LoadingSpinnerButton";
import useTranslation from "../../../hooks/useTranslation";
import { texts } from "../../../i18n/texts";
import { checkNotNull } from "../../../utils/checkNotNull";
import { SystemLandscape } from "../systemLandscape/SystemLandscape";
import { SystemLandscapeHeader } from "../systemLandscapeHeader/SystemLandscapeHeader";
import { ISystemLandscapeSectionProps } from "./ISystemLandscapeSectionProps";
import styles from "./SystemLandscapeSection.module.scss";
import { useSystemLandscapeSectionViewModel } from "./useSystemLandscapeSectionViewModel";

export const SystemLandscapeSection: React.FC<ISystemLandscapeSectionProps> = (
  props
) => {
  const { t } = useTranslation();
  const viewModel = useSystemLandscapeSectionViewModel(props);
  return (
    <>
      <AsyncLoad load={viewModel.loadSystemLandscapeSection}>
        {viewModel.systemLandscapeSection?.SYSTEM_LANDSCAPE_BUNDLES && (
          <>
            <div className={styles.systemLandscapeHeader}>
              <SystemLandscapeHeader
                product={viewModel.systemLandscapeSection.PRODUCT}
                licenseOptions={viewModel.systemLandscapeSection.LIC_OPTS}
                isLicenseOptionsRequired={
                  viewModel.systemLandscapeSection.IS_LIC_OPTS_REQUIRED
                }
                onCollapseSystems={viewModel.onCollapseAllSystems}
                onExpandSystems={viewModel.onExpandAllSystems}
                systemsExpandible={viewModel.systemsExpandible}
                onSetAllLicensesFlag={viewModel.onSetAllLicensesFlag}
              />
            </div>
            {viewModel
              .filterSystemLandscapeBundles()
              .map((systemLandscapeBundle) => {
                return (
                  <SystemLandscape
                    onSystemSaveDone={() => {
                      viewModel.loadSystemLandscapeSection();
                    }}
                    key={systemLandscapeBundle.SYS_LANDSCAPE.OBJECT_ID}
                    collapseSignal={viewModel.collapseSignal}
                    onCollapseSingleSystem={viewModel.onCollapseSingleSystem}
                    onExpandSingleSystem={viewModel.onExpandSingleSystem}
                    contract={
                      checkNotNull(viewModel.systemLandscapeSection).CONTRACT
                    }
                    expandSignal={viewModel.expandSignal}
                    isLandscapeBeingDeleted={
                      systemLandscapeBundle.SYS_LANDSCAPE ===
                      viewModel.systemLandscapeUnderDeletion
                    }
                    lastProductVersion={
                      checkNotNull(viewModel.systemLandscapeSection)
                        .LAST_PRODUCT_VERSION
                    }
                    onSetAlternativeDownloadVersion={
                      viewModel.onSetAlternativeDownloadVersion
                    }
                    product={
                      checkNotNull(viewModel.systemLandscapeSection).PRODUCT
                    }
                    searchSignal={props.searchSignal}
                    systemLandscape={systemLandscapeBundle.SYS_LANDSCAPE}
                    systems={systemLandscapeBundle.SYSTEMS}
                    systemLandscapeConflicts={systemLandscapeBundle.CONFLICTS}
                    systemRequirementTypes={
                      systemLandscapeBundle.SYS_LANDSCAPE
                        .SYSTEM_REQUIREMENT_TYPES
                    }
                    systemRoles={
                      systemLandscapeBundle.SYS_LANDSCAPE.SYSTEM_ROLES
                    }
                    onSave={viewModel.onSave}
                    onDelete={viewModel.onDelete}
                  />
                );
              })}
          </>
        )}
      </AsyncLoad>
      {!viewModel.isLoading && (
        <LoadingSpinnerButton
          showSpinner={viewModel.addSystemLandscape}
          icon="plus"
          text={t(texts.systemLandscape.labelAddButton)}
          onClick={viewModel.onAdd}
        />
      )}
    </>
  );
};
