import { IValue } from "./../types/IValue";

import { useLocalStorage } from "./useLocalStorage";

/**
 * This custom hook is responsible for providing read and write access to the local storage for a specific {@link key}, while the value is represented by type {@link IValue}.
 * It converts the {@link useLocalStorage} format from tuple to an object type.
 */
export const useLocalStorageValue = <T>(
  key: string,
  fallbackValue: T
): IValue<T> => {
  const [value, setValue] = useLocalStorage(key, fallbackValue);
  return { value, setValue };
};
