import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";
import { IHaveSystemActionLog } from "../types/IHaveSystemActionLog";

export interface ISystemActionLog extends IEntity, IHaveSystemActionLog {
  SYSTEM_OBJECT_ID: string;
  CREATION_DATE: string;
  CREATION_TIME: string;
}

export const SystemActionLogMeta: IEntityMeta<ISystemActionLog> = {
  path: "/system-action-logs",
};
