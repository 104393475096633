import { PublicPage } from "../components/pages/publicPage/PublicPage";
import { useSearchParams } from "react-router-dom";
import { AsyncLoad } from "../components/asyncLoad/AsyncLoad";
import { ProductApi } from "../api/ProductApi";
import { useState } from "react";
import { IVersion } from "../shared/types/IVersion";
import { useRequest } from "../hooks/useRequest";
import { Card } from "@snpjs/core";
import styles from "./UpdateCheckPage.module.scss";

export const UpdateCheck: React.FC = () => {
  const [queryParameters] = useSearchParams();

  const [currentVersion, setCurrentVersion] = useState<IVersion | undefined>();
  const [error, setError] = useState<string | undefined>();
  const request = useRequest();

  const productId = queryParameters.get("productId") || "";
  const release = queryParameters.get("release") || "";
  const servicePack = queryParameters.get("servicePack") || "";
  const hotfix = queryParameters.get("hotfix") || "";
  const lts = queryParameters.get("lts") || "";
  const customerCode = queryParameters.get("customerCode") || "";
  const instnumber = queryParameters.get("instnumber") || "";
  const sysid = queryParameters.get("sysid") || "";
  const client = queryParameters.get("client") || "";
  const language = queryParameters.get("language") || "";

  const checkAndDisplayVersion = (
    currentVersion: IVersion | undefined
  ): JSX.Element | null => {
    if (!currentVersion) {
      return null;
    }
    const currentRelease = currentVersion.RELEASE?.toString() || "";
    const currentServicePack = currentVersion.SERVICE_PACK?.toString() || "";
    const currentHotfix = currentVersion.HOTFIX?.toString() || "";
    if (
      currentRelease > release ||
      (currentRelease === release && currentServicePack > servicePack) ||
      (currentRelease === release &&
        currentServicePack === servicePack &&
        currentHotfix > hotfix)
    ) {
      return (
        <div className={styles.updateCheckPage}>
          <div>
            <Card>
              <h1 style={{ textAlign: "center", color: "red" }}>
                Update available
              </h1>
              <table style={{ margin: "auto" }}>
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Release</th>
                    <th>Service Pack</th>
                    <th>Hotfix</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{productId}</td>
                    <td>{currentRelease}</td>
                    <td>{currentServicePack}</td>
                    <td>{currentHotfix}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.updateCheckPage}>
          <div>
            <Card>
              <div>
                <h1 style={{ textAlign: "center", color: "green" }}>
                  Software is up to date
                </h1>
              </div>
              <table style={{ margin: "auto" }}>
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Release</th>
                    <th>Service Pack</th>
                    <th>Hotfix</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{productId}</td>
                    <td>{release}</td>
                    <td>{servicePack}</td>
                    <td>{hotfix}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <PublicPage showPageInfo={false}>
        <AsyncLoad
          positionCentered
          load={async () => {
            try {
              await request.send(async () => {
                const version = await ProductApi.checkForUpdates(
                  productId,
                  release,
                  servicePack,
                  hotfix,
                  lts,
                  customerCode,
                  instnumber,
                  sysid,
                  client,
                  language
                );
                setCurrentVersion(version);
              });
            } catch (error) {
              setError((error as Error).message);
            }
          }}
        >
          {error ? <p>{error}</p> : checkAndDisplayVersion(currentVersion)}
        </AsyncLoad>
      </PublicPage>
    </>
  );
};
