import { ButtonGroup, ControlGroup } from "@snpjs/core";
import { useEffect, useState } from "react";

import { ContractSearchFilterHint } from "../contractSearchFilterHint/ContractSearchFilterHint";
import { IContractSearchFilterProps } from "./ContractSearchFilterProps";
import { LabeledInput } from "../../../../components/labeledInput/LabeledInput";
import { LabeledSelect } from "../../../../components/labeledSelect/LabeledSelect";
import { isNotEmpty } from "../../../../utils/isNotEmpty";
import { style } from "../../../../utils/style";
import styles from "./ContractSearchFilter.module.scss";
import { texts } from "../../../../i18n/texts";
import { toABAPBoolean } from "../../../../utils/toABAPBoolean";
import { useInputBinding } from "../../../../hooks/useInputBinding";
import useTranslation from "../../../../hooks/useTranslation";
import { useUserProfile } from "../../../../hooks/useUserProfile";

const SESSION_STORAGE_SYSTEMFILTER = "systemFilter";

export const ContractSearchFilter: React.FC<IContractSearchFilterProps> = (
  props
) => {
  const { t } = useTranslation();
  const userProfile = useUserProfile();
  const [displaySystemFilter, setDisplaySystemFilter] = useState(
    userProfile.value?.displaySystemFilter ?? false
  );
  const sid = useInputBinding("");
  const client = useInputBinding("");
  const installNumber = useInputBinding("", "number");
  const release = useInputBinding("", "number");
  const [isLTS, setIsLTS] = useState(true);
  const servicePack = useInputBinding("", "number");
  const hotfix = useInputBinding("", "number");
  const description = useInputBinding("");

  // set initial search filter parameters from session storage if possible
  useEffect(() => {
    try {
      const filterParams = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_SYSTEMFILTER)!
      );
      if (filterParams) {
        sid.setValue(filterParams["SYSTEM_ID"] || "");
        client.setValue(filterParams["SYSTEM_CLIENT"] || "");
        installNumber.setValue(filterParams["INSTNUMBER"] || "");
        release.setValue(filterParams["RELEASE"]?.toString() || "");
        setIsLTS(filterParams["IS_LTS"] || false);
        servicePack.setValue(filterParams["SERVICE_PACK"]?.toString() || "");
        hotfix.setValue(filterParams["HOTFIX"]?.toString() || "");
        description.setValue(filterParams["DESCRIPTION"] || "");
      }
    } catch (error) {
      console.error(
        "Filter parameters could not be initialized from the browser's session storage",
        error
      );
    }
  }, []);

  // destructure the following function as props will be recreated with each render cycle
  // and triggers the useEffect for calling this function
  const { onSystemFilterPropsChange } = { ...props };

  // check if any property changed and raise event
  useEffect(() => {
    onSystemFilterPropsChange?.({
      SYSTEM_ID: sid.value,
      SYSTEM_CLIENT: client.value,
      INSTNUMBER: installNumber.value,
      RELEASE: isNotEmpty(release.value) ? parseInt(release.value) : undefined,
      IS_LTS: toABAPBoolean(isLTS),
      SERVICE_PACK: isNotEmpty(servicePack.value)
        ? parseInt(servicePack.value)
        : undefined,
      HOTFIX: isNotEmpty(hotfix.value) ? parseInt(hotfix.value) : undefined,
      DESCRIPTION: description.value,
    });
  }, [
    sid.value,
    client.value,
    installNumber.value,
    release.value,
    isLTS,
    servicePack.value,
    hotfix.value,
    description.value,
    onSystemFilterPropsChange,
  ]);

  const onReset = () => {
    sid.setValue("");
    client.setValue("");
    installNumber.setValue("");
    release.setValue("");
    setIsLTS(false);
    servicePack.setValue("");
    hotfix.setValue("");
    description.setValue("");
  };

  const onDisplayCriterias = () => {
    setDisplaySystemFilter((previous) => {
      previous = !previous;
      userProfile.setValue((userProfile) => {
        return { ...userProfile, displaySystemFilter: previous };
      });
      if (!previous) {
        onReset();
      }
      return previous;
    });
  };

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onEnter?.();
    }
  };

  return (
    <div className={styles.contractSearchFilter}>
      <div className={styles.inputList}>
        {displaySystemFilter && (
          <>
            <ControlGroup>
              <LabeledInput
                className={styles.sid}
                label={t(texts.general.system.systemId)}
                maxLength={3}
                onChange={sid.onChangeValue}
                onKeyDown={onEnter}
                value={sid.value}
              />
              <LabeledInput
                className={styles.client}
                label={t(texts.general.system.systemClient)}
                maxLength={3}
                onChange={client.onChangeValue}
                onKeyDown={onEnter}
                value={client.value}
              />
              <LabeledInput
                className={styles.installNumber}
                label={t(texts.general.system.installNumber)}
                maxLength={10}
                onChange={installNumber.onChangeValue}
                onKeyDown={onEnter}
                value={installNumber.value}
              />
              <LabeledInput
                className={styles.release}
                label={t(texts.general.system.release)}
                maxLength={4}
                onChange={release.onChangeValue}
                onKeyDown={onEnter}
                value={release.value}
              />
              <LabeledSelect
                className={styles.isLTS}
                label={t(texts.general.system.isLTSShort)}
                items={[false, true]}
                onSelect={(item) => {
                  if (item !== undefined) {
                    setIsLTS(item);
                  }
                }}
                selected={isLTS}
                renderOptionTitle={(item) => {
                  if (item === true) {
                    return "Yes";
                  } else {
                    return "No";
                  }
                }}
              />
              <LabeledInput
                className={styles.servicePack}
                label={t(texts.general.system.servicePack)}
                maxLength={2}
                onChange={servicePack.onChangeValue}
                onKeyDown={onEnter}
                value={servicePack.value}
              />
              <LabeledInput
                className={styles.hotfix}
                label={t(texts.general.system.hotfix)}
                maxLength={2}
                onChange={hotfix.onChangeValue}
                onKeyDown={onEnter}
                value={hotfix.value}
              />
              <LabeledInput
                className={styles.description}
                label={t(texts.general.description)}
                maxLength={60}
                onChange={description.onChangeValue}
                onKeyDown={onEnter}
                value={description.value}
              />
            </ControlGroup>
          </>
        )}
      </div>
      <div className={styles.systemFilterButtonSection}>
        <ButtonGroup>
          {displaySystemFilter && (
            <button className={styles.button} onClick={onReset}>
              {t(texts.contractSearchFilter.resetFilter)}
            </button>
          )}
          <button
            className={style(
              styles.button,
              displaySystemFilter ? styles.systemFilterSelected : ""
            )}
            onClick={onDisplayCriterias}
          >
            {t(texts.contractSearchFilter.systemFilter)}
          </button>
        </ButtonGroup>
        <ContractSearchFilterHint />
      </div>
    </div>
  );
};
