import { IProductInfo } from "../services/IProductInfo";
import { ProductInfo } from "../services/ProductInfo";

import { useConfig } from "./useConfig";
import { useMemo } from "react";

export const useProductInfo = (): IProductInfo => {
  const config = useConfig();
  const productInfo = useMemo(() => new ProductInfo(config), [config]);
  return productInfo;
};
