import { ICommand } from "../../../types/ICommand";
import { texts } from "../../../i18n/texts";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { IUseRequestEmergencyLicenseCommandProps } from "./IUseRequestEmergencyLicenseProps";
import { RequestEmergencyLicenseDialog } from "../requestEmergencyLicenseDialog/RequestEmergencyLicenseDialog";

/**
 * This command handles the selection of a version of a system landscape,
 * which is empty or its systems are initial or have no downloaded version yet.
 */
export const useRequestEmergencyLicenseCommand = (
  props: IUseRequestEmergencyLicenseCommandProps
): ICommand => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const onCancel = () => {
    setShow(false);
  };

  const onOkay = async (reason: string) => {
    const success = await props.onOkay(reason);
    if(success === true){
      setShow(false);
    }
    return success? true : false
  };

  const view = show && (
    <RequestEmergencyLicenseDialog
      isOpen={show}
      onCancel={onCancel}
      onOkay={onOkay}
      subTextTranslation={{
        text: texts.SystemGrantEmergencLicense.infoText,
        placeholders: {
          newExpirationDate: props.getNewEmergencyExpirationDate()?.toDateString(),
          duration: props.emergencyLicenseDuration.toString(),
        }
      }}
      title={t(texts.SystemGrantEmergencLicense.title)}
    />
  );

  const execute = () => {
    setShow(true);
  };

  return {
    execute,
    view,
  };
};
