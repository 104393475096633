import { IHavePath } from "../types/IHavePath";
import { IHaveSystemActionLog } from "../types/IHaveSystemActionLog";
import { IVersion } from "../types/IVersion";

/**
 * An implementation of this interface represents an alternative download version for e.g. a system or system landscape.
 */
export interface IDownloadVersion extends IHaveSystemActionLog, IVersion {
  OBJECT_ID: string;
}

export const DownloadVersionMeta: IHavePath = {
  path: "/download-version",
};
