import { ContractMeta } from "../shared/model/IContract";
import { IEntityDetails } from "../shared/types/IEntityDetails";
import { IUserAuthRole } from "../shared/model/IUserAuthRole";
import { Repository } from "./core/Repository";
import { UserAuthRoleMeta } from "../shared/model/IUserAuthRole";

class UserAuthRoleApiDefault extends Repository<IUserAuthRole> {
  constructor() {
    super(UserAuthRoleMeta.path);
  }

  deleteByContractIdAndEmail(
    contractId: string,
    userEmailAddress: string
  ): Promise<boolean> {
    // return this.delete(`${this.buildPath(contractId)}/${userEmailAddress}`);
    return this.post(`${this.buildPath(contractId)}/${userEmailAddress}`, null);
  }

  findByContractId(contractId: string): Promise<IUserAuthRole[]> {
    return this.get(this.buildPath(contractId));
  }

  async getIsUserAssigned(
    contractId: string,
    userEmailAddress: string
  ): Promise<boolean> {
    return this.get(`${this.buildPath(contractId)}/${userEmailAddress}`);
  }

  updateByContractId(
    contractId: string,
    item: IEntityDetails<IUserAuthRole>
  ): Promise<IUserAuthRole> {
    return this.post(this.buildPath(contractId), item);
  }

  private buildPath(contractId: string): string {
    return `${this.host}${ContractMeta.path}/${contractId}${UserAuthRoleMeta.path}`;
  }
}

/**
 * This class is responsible for handling all {@link IUserAuthRole} specific REST requests.
 */
export const UserAuthRoleApi = new UserAuthRoleApiDefault();
