import { ErrorReason } from "../../types/ErrorReason";

/**
 * This function is responsible for creating an {@link Error}. There are the following possibilities:
 *  1. A default reason is generated, if no {@link reason} was provided.
 *  2. A {@link reason} of type string is wrapped and returned by an instance of {@link Error}.
 *  3. A {@link reason} of type {@link Error} is returned directly.
 */
export const createError = (reason?: ErrorReason): Error => {
  if (!reason) {
    return new Error(`An unexpected error occurred.`);
  }

  if (typeof reason === "string") {
    return new Error(reason);
  }

  return reason;
};
