import { IEntityDetails } from "../../../shared/types/IEntityDetails";
import { ISystem } from "../../../shared/model/ISystem";
import { ISystemSectionProps } from "./ISystemSectionProps";
import { InsufficientSystemRequirementsError } from "../../../api/errors/InsufficientSystemRequirementsError";
import { LicenseGenerationError } from "../../../api/errors/LicenseGenerationError";
import { Message } from "../../../services/Message";
import { PathNotFoundError } from "../../../api/errors/PathNotFoundError";
import { ProductExpiredError } from "../../../api/errors/ProductExpiredError";
import { ProductInvalidExpiryDateError } from "../../../api/errors/ProductInvalidExpiryDateError";
import { RESTError } from "../../../api/core/RESTError";
import { SystemApi } from "../../../api/SystemApi";
import { SystemConflictError } from "../../../api/errors/SystemConflictError";
import { texts } from "../../../i18n/texts";
import { useConfig } from "../../../hooks/useConfig";
import { useRequest } from "../../../hooks/useRequest";
import useTranslation from "../../../hooks/useTranslation";

export const useSystemSectionViewModel = (props: ISystemSectionProps) => {
  const { t } = useTranslation();
  const request = useRequest();
  const config = useConfig();

  const onAddSystem = (system: IEntityDetails<ISystem>) =>
    SystemApi.addBySystemLandscapeId(props.systemLandscapeId, system);

  const handleDefaultErrors = (error: RESTError) => {
    switch (error.constructor) {
      case ProductExpiredError: {
        Message.error(t(texts.systemSection.errorProductExpired));
        return true;
      }
      case ProductInvalidExpiryDateError: {
        Message.error(
          t(texts.systemSection.errorProductInvalidExpiryDate, {
            supportPortal: config.SUPPORT_PORTAL_LINK,
          })
        );
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const handleSoftwareDownloadErrors = (error: RESTError, system: ISystem) => {
    switch (error.constructor) {
      case InsufficientSystemRequirementsError: {
        Message.error(
          t(texts.systemSection.errorInsufficientSystemRequirements, {
            SID: system.SYSTEM_ID,
            client: system.SYSTEM_CLIENT,
            installNumber: system.INSTNUMBER,
            supportPortal: config.SUPPORT_PORTAL_LINK,
          })
        );
        return true;
      }
      case SystemConflictError: {
        Message.error(t(texts.systemSection.errorDownloadWithSystemConflict));
        return true;
      }
      case PathNotFoundError: {
        Message.error(t(texts.systemSection.errorDownloadPathError));
        return true;
      }
      default: {
        return handleDefaultErrors(error);
      }
    }
  };

  const handleLicenseDownloadErrors = (error: RESTError): boolean => {
    switch (error.constructor) {
      case LicenseGenerationError: {
        Message.error(
          t(texts.systemSection.errorLicenseGeneration, {
            supportPortal: config.SUPPORT_PORTAL_LINK,
          })
        );
        return true;
      }
      case InsufficientSystemRequirementsError: {
        Message.error(
          t(
            texts.systemSection
              .errorLicenseGenerationWithInsufficientSystemRequirements,
            { supportPortal: config.SUPPORT_PORTAL_LINK }
          )
        );
        return true;
      }
      default: {
        return handleDefaultErrors(error);
      }
    }
  };

  const onDownloadSoftware = async (system: ISystem): Promise<boolean> => {
    let success = true;
    await request.send(
      async () => {
        await SystemApi.downloadTransportFile(system.OBJECT_ID);
      },
      (error) => {
        success = false;
        return handleSoftwareDownloadErrors(error, system);
      }
    );
    return success;
  };

  const onDownloadLicense = async (system: ISystem): Promise<boolean> => {
    let success = true;
    await request.send(
      async () => {
        await SystemApi.downloadLicenseFile(system.OBJECT_ID);
      },
      (error) => {
        success = false;
        return handleLicenseDownloadErrors(error);
      }
    );
    return success;
  };

  const onDownloadEmergencyLicense = async (system: ISystem, reason: string): Promise<boolean> => {
    let success = true;
    await request.send(
      async () => {
        await SystemApi.downloadEmergencyLicenseFile(system.OBJECT_ID, reason);
      },
      (error) => {
        success = false;
        return handleSoftwareDownloadErrors(error, system);
      }
    );
    return success;
  };

  return {
    onAddSystem,
    onDownloadLicense,
    onDownloadSoftware,
    onDownloadEmergencyLicense,
  };
};
