import { Button, Icon, Menu, MenuItem, Popover } from "@snpjs/core";

import { Color } from "../../api/types/Color";
import { IUser } from "../../shared/model/IUser";
import { texts } from "../../i18n/texts";
import { useLogin } from "../../hooks/useLogin";
import { useLogout } from "../../hooks/useLogout";
import { useSession } from "../../hooks/useSession";
import useTranslation from "../../hooks/useTranslation";

/**
 * This component is responsible for displaying an account button which provides options to login and logout a user.
 */
export const AccountButton: React.FC = () => {
  const session = useSession();
  const login = useLogin();
  const logout = useLogout();
  const { t } = useTranslation();

  const menu = (
    <Menu>
      {session ? (
        <MenuItem
          text={t(texts.general.logout)}
          onClick={logout}
          icon={<Icon icon={"sign-out"} intent="danger" />}
        />
      ) : (
        <MenuItem
          text={t(texts.general.login)}
          onClick={login}
          icon={<Icon icon={"sign-in"} intent="success" />}
        />
      )}
    </Menu>
  );

  const caption = session ? getName(session.USER) : "";

  return (
    <>
      <Popover content={menu} placement="bottom-start">
        <Button
          text={caption}
          icon={<Icon icon={"user"} color={Color.SNP_BLUE} />}
          rightIcon="caret-down"
          minimal={true}
        />
      </Popover>
    </>
  );
};

function getName(user: IUser) {
  if (user.FIRST_NAME && user.LAST_NAME) {
    return user.FIRST_NAME + " " + user.LAST_NAME;
  }
  return user.EMAIL_ADDRESS;
}
