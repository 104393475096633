import { IEditableCardProps } from "./IEditableCardProps";
import { ValidationError } from "../../errors/ValidationError";

import { useEffect, useState } from "react";

export function useEditableCardViewModel<T extends object>(
  props: IEditableCardProps<T>
) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState(false);

  // If props.object is not yet persisted, it should be displayed in edit mode.
  // Or in short: new added objects are displayed directly in editMode
  useEffect(() => {     
    const isTransient = props.dummyClassType.isTransient(props.initialObject);
    props.setEditMode(isTransient);
  }, []);
  
  const onCancelDelete = () => {
    setShowConfirm(false);
  };

  const onConfirmDelete = () => {
    setShowConfirm(false);
    props.onDelete?.(props.initialObject);
  };

  const onCopy = () => {
    props.onCopy?.(props.initialObject);
  };

  const onDelete = () => {
    setShowConfirm(true);
  };

  /**
   * Rollback changes and restore object.
   * If the current object is not persisted yet, delete it from the object list,
   * if the changes should be withdrawn.
   */
  const onRestore = () => {
    if (props.dummyClassType.isTransient(props.initialObject)) {
      props.onDelete?.(props.initialObject);
    } else {
      props.setObject(props.initialObject);
      props.setEditMode(false);
    }
    props.onRestore?.(props.initialObject);
  };

  const onSave = async () => {
    setSaving(true);
    try {
      await props.onValidate?.(props.initialObject);
      const success = (await props.onSave?.(props.initialObject)) ?? true;
      if (success) {
        props.setEditMode(false);
      }
    } catch (error) {
      if (!(error instanceof ValidationError)) {
        setSaving(false);
        throw error;
      }
    }
    setSaving(false);
  };

  const canEdit = (): boolean => {
    const canEdit = props.canEdit ? props.canEdit() : true;
    return canEdit;
  };

  const isEditable = (): boolean => {
    const isEditable = props.isEditable ? props.isEditable() : true;
    return isEditable;
  };

  const canDelete = (): boolean => {
    const canDelete = props.canDelete ? props.canDelete() : true;
    return canDelete;
  };

  return {
    canDelete,
    canEdit,
    onCancelDelete,
    onConfirmDelete,
    onCopy,
    onDelete,
    onRestore,
    onSave,
    saving,
    setSaving,
    showConfirm,
    isEditable
  };
}
