import { Icon, Popover } from "@snpjs/core";

import { splitTextToNewLine } from "../../../../utils/splitTextToNewLine";
import styles from "./ContractSearchFilterHint.module.scss";
import { texts } from "../../../../i18n/texts";
import useTranslation from "../../../../hooks/useTranslation";

export const ContractSearchFilterHint: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Popover
      content={
        <div className={styles.info}>
          {splitTextToNewLine(t(texts.contractSearchFilter.explanation))}
        </div>
      }
    >
      <Icon icon={"info"} />
    </Popover>
  );
};
