import { IEditableListProps } from "./IEditableListProps";
import { IEntity } from "../../shared/types/IEntity";
import { List } from "../list/List";

/**
 * This component is responsible for displaying objects in a list.
 */
export function EditableList<T extends IEntity>(props: IEditableListProps<T>) {
  const items = props.objects.map((object, index) => {
    return (
      <div key={object.OBJECT_ID}>
        {props.createCard(
          object,
          props.onCopy,
          props.onDelete,
          props.onSave,
          props.onUpdate ?? props.onSave,
          index + 1
        )}
      </div>
    );
  });

  return <List numberOfColumns={props.numberOfColumns}>{items}</List>;
}
