import { Release } from "../../shared/types/Release";

export const releaseToVersion = (release: Release): string => {
  const releaseString = release.toString();
  if (releaseString.length < 4) {
    return releaseString;
  }

  return `${releaseString.substring(0, 2)}.${releaseString.substring(2, 4)}`;
};
