import { ConfigContext } from "../context/ConfigContext";
import { ConfigContextProvider } from "../components/configContextProvider/ConfigContextProvider";
import { IConfig } from "./../shared/model/IConfig";
import { LoadContextError } from "../errors/LoadContextError";

import { checkNotNull } from "../utils/checkNotNull";
import { useContext } from "react";

/**
 * This custom hook is responsible for providing access to the {@link IConfig} of the backend system
 */
export const useConfig = (): IConfig => {
  const configContext = useContext(ConfigContext);

  checkNotNull(
    configContext,
    new LoadContextError(
      "ConfigContext",
      useConfig.name,
      ConfigContextProvider.name
    )
  );

  return configContext.config;
};
