import { configureRoutes } from "./core/configureRoutes";
import { route } from "./core/route";

/**
 * This constant contains the routes of this app.
 * Routes, which are no valid path, because they are not directly callable (e.g. for Breadcrumbs) are starting with excerpt*
 */
export const Routes = configureRoutes({
  excerptConflictManagement: route("/conflictManagement"),
  excerptInstallGuide: route("/install-checklist"),
  excerptSystems: route("/systems"),
  excerptUserAuthRoles: route("/user-auth-roles"),

  contract: route(`/contracts/:contractId`),
  contracts: route("/contracts"),
  contractProduct: route(`/contracts/:contractId/products/:productId`),
  contractUserAuthRole: route(`/contracts/:contractId/user-auth-roles`),
  homePage: route("/"),
  welcome: route("/welcome"),
  login: route("/login"),
  product: route("/products/:productId"),
  products: route("/products"),
  system: route("/systems/:systemId"),
  systemConflictManagement: route(
    `/contracts/:contractId/products/:productId/systems/:systemId/conflictManagement`
  ),
  systemInstallChecklist: route(
    `/contracts/:contractId/products/:productId/systems/:systemId/install-checklist`
  ),
  updateCheck: route(`/updateCheck`),
});
