import { ReactNode } from "react";

/**
 * This function is responsible to split the given {@link text} at the given {@link separator} and to separate each text element as paragraph.
 */
export const splitTextToParagraph = (
  text: string,
  separator: string = "\n"
): ReactNode => {
  return (
    <>
      {text.split(separator).map((text) => (
        <p>{text}</p>
      ))}
    </>
  );
};
