import { BreadcrumbLoader } from "../../../decorators/BreadcrumbLoader";
import { BreadcrumbLoaderType } from "./BreadcrumbLoaderType";
import { DecoratorInfo } from "../../DecoratorInfo";
import { IBreadcrumbLoaderRepo } from "./IBreadcrumbLoaderRepo";
import { PathSegment } from "../../../components/breadcrumbs/PathSegment";

import { checkNotNull } from "../../../utils/checkNotNull";

export class BreadcrumbLoaderRepo<T extends BreadcrumbLoaderType>
  implements IBreadcrumbLoaderRepo<T>
{
  private breadcrumbLoaderMap = new Map<string, T>();

  constructor(...breadcrumbLoaders: T[]) {
    breadcrumbLoaders.forEach((breadcrumbLoader) => {
      const path = checkNotNull(
        DecoratorInfo.find<string>(breadcrumbLoader, BreadcrumbLoader),
        `Error when getting path from breadcrumb loader. No decorator found for breadcrumb loader '${breadcrumbLoader.name}'. Decorate '${breadcrumbLoader.name}' with a 'BreadcrumbLoader' decorator.`
      );
      this.breadcrumbLoaderMap.set(path, breadcrumbLoader);
    });
  }

  find(pathSegment: PathSegment): T | undefined {
    return this.breadcrumbLoaderMap.get(pathSegment.rootPath);
  }
}
