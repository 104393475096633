import { useCallback, useEffect, useState } from "react"; 

import { ISystemActionLogProps } from "./ISystemActionLogProps";
import { TextArea } from "@snpjs/core";
import { ValidationError } from "../../../errors/ValidationError";
import { ValidationHandler } from "../../../types/ValidationHandler";
import styles from "./SystemActionLog.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";
import { useValidatorProvider } from "../../../hooks/useValidatorProvider";

export const SystemActionLog: React.FC<ISystemActionLogProps> = (props) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState(false);

  const onValidate: ValidationHandler = useCallback(() => {
    if (props.isReasonRequired && props.text.length === 0) {
      setDisplayError(true);
      throw new ValidationError();
    }
  }, [props.isReasonRequired, props.text]);

  const validator = useValidatorProvider(onValidate);

  useEffect(() => {
    props.requestValidator?.(validator);
  }, [props.requestValidator, validator])

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setText(event.target.value);
    setDisplayError(false);
  };

  return (
    <>
      {props.label && <div className={styles.subTitle}>{props.label}</div>}
      <TextArea
        fill={true}
        onChange={onChangeText}
        value={props.text}
        intent={displayError ? "danger" : "none"}
      />
      {
        <div className={styles.errorMessage}>
          {displayError && t(texts.systemActionLogDialog.errorMissingReason)}
        </div>
      }
    </>
  );
};
