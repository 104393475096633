import { AnchorButton } from "@snpjs/core";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";

/**
 * Support Button.
 */
export const SupportButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <AnchorButton
        text={t(texts.supportPortalTicketInfo.supportPortal)}
        icon="chats-circle"
        minimal={true}
        href="https://support.snpgroup.com/"
        target="_blank"
      />
    </>
  );
};
