import { ABAPBoolean } from "../types/ABAPBoolean";
import { Environment } from "../types/Environment";
import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";

export interface IConfig extends IEntity {
  /**
   * Returns the link to the SNP E-Campus (Learning portal).
   */
  ECAMPUS_LINK: string;

  /**
   * Link to SNP support portal.
   */
  SUPPORT_PORTAL_LINK: string;

  /**
   * The contract expire warning in days defines when the user is informed when displaying the contracts about the expiration.
   * E.g. Your contract expires in 12 days.
   */
  CONTRACT_EXPIRE_WARN_IN_DAYS: number;

  /**
   * Edit days for emergency license. In this period the user can already request the emergency license.
   */
  EMERGENCY_EDIT_DAYS: number;

  /**
   * Emergency license extension days.
   */
  EMERGENCY_EXT_DAYS: number;

  /**
   * Returns the environment type of the system.
   */
  SYSTEM_ENVIRONMENT: Environment;

  /**
   * Flag for extended user management.
   */
  EXTENDED_SNP_USER_MANAGEMENT: ABAPBoolean;

  /**
   * Show search bar if more than X contracts.
   */
  SHOW_SEARCH_CONTRACTS: number;
}

export const ConfigMeta: IEntityMeta<IConfig> = {
  path: "/config",
};
