import { IAuthRole } from "../../../shared/model/IAuthRole";
import { IUserAuthRole } from "../../../shared/model/IUserAuthRole";
import { IUserAuthRoleCardProps } from "./IUserAuthRoleCardProps";
import { Message } from "../../../services/Message";
import { RESTError } from "../../../api/core/RESTError";
import { UserApi } from "../../../api/UserApi";
import { UserAuthRoleApi } from "../../../api/UserAuthRoleApi";
import { UserAuthRoleDummy } from "../../../model/UserAuthRoleDummy";
import { UserNotFoundError } from "../../../api/errors/UserNotFoundError";
import { ValidationError } from "../../../errors/ValidationError";

import { isEmailValid } from "../../../utils/isEmailValid";
import { texts } from "../../../i18n/texts";
import { useAuth } from "../../../hooks/useAuth";
import { useRequest } from "../../../hooks/useRequest";
import { useState } from "react";

import useTranslation from "./../../../hooks/useTranslation";
import { AuthRole } from "../../../auth/types/AuthRole";
import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { useSession } from "../../../hooks/useSession";
import { useConfig } from "../../../hooks/useConfig";

// eslint-disable-next-line @typescript-eslint/no-unused-vars

/**
 * This view model handles states for {@link UserAuthRoleCard}.
 */
export const useUserAuthRoleCardViewModel = (props: IUserAuthRoleCardProps) => {
  const [userAuthRole, setUserAuthRole] = useState(props.userAuthRole);
  const [editMode, setEditMode] = useState(false);
  const [inputFieldError, setInputFieldError] = useState("");
  const { t } = useTranslation();
  const auth = useAuth();
  const request = useRequest();
    /* start backdoor */
  const config = useConfig();  
  const session = useSession();
    /* end backdoor */
  const handleUserNotFoundError = (error: RESTError) => {
    if (error instanceof UserNotFoundError) {
      Message.warning(t(texts.userAuthRoleCard.errorSharedUserNotFound));
      return true;
    }
    return false;
  };

  const isUserAlreadyAssignedToContract = async () => {
    return await request.send(async () => {
      const isUserAssigned = await UserAuthRoleApi.getIsUserAssigned(
        props.contractId,
        userAuthRole.EMAIL_ADDRESS
      );
      return isUserAssigned.toString() === "true";
    });
  };

  /**
   * Creates a deep clone copy of that object, that also clones arrays and objects which are assigned as properties
   */
  const clone = (userAuthRole: IUserAuthRole): IUserAuthRole => {
    return UserAuthRoleDummy.clone(userAuthRole);
  };

  const onAuthRoleActivated = (authRole: IAuthRole) => {
    setUserAuthRole((previous) => {
      const updatedUserAuthRole = clone(previous);
      updatedUserAuthRole.USER_AUTH_ROLES.push(authRole.USER_ROLE_ID);
      return updatedUserAuthRole;
    });
  };

  const onAuthRoleDeactivated = (authRole: IAuthRole) => {
    setUserAuthRole((previous) => {
      const index = previous.USER_AUTH_ROLES.findIndex(
        (authRoleName) => authRoleName === authRole.USER_ROLE_ID
      );
      if (index !== -1) {
        const updatedUserAuthRole = clone(previous);
        updatedUserAuthRole.USER_AUTH_ROLES.splice(index, 1);
        return updatedUserAuthRole;
      }
      return previous;
    });
  };

  const onValidate = async () => {
    if (userAuthRole.USER_AUTH_ROLES.length === 0) {
      Message.error(t(texts.userAuthRoleCard.errorNoUserRoleSelected));
      throw new ValidationError();
    }
    if (!UserAuthRoleDummy.isTransient(userAuthRole)) {
      return true;
    }
    if (!isEmailValid(userAuthRole.EMAIL_ADDRESS)) {
      setInputFieldError(t(texts.userAuthRoleCard.errorInvalidEmail));
      throw new ValidationError();
    }
    const userExists = await isUserAlreadyAssignedToContract();

    if (userExists) {
      Message.error(
        t(texts.userAuthRoleCard.errorAlreadyAssignedToContract, {
          user: userAuthRole.EMAIL_ADDRESS,
        })
      );
      throw new ValidationError();
    }

    return true;
  };

  const onSave = () => {
    props.onSave?.(userAuthRole);
  };

  const setEmail = (email: string) => {
    setInputFieldError("");
    setUserAuthRole((previous) => {
      const updatedUserAuthRole = clone(previous);
      updatedUserAuthRole.EMAIL_ADDRESS = email;
      return updatedUserAuthRole;
    });
  };

  const share = async () => {
    if (!canShareContract()) {
      Message.warning(`You are not allowed to share this contract.`);
      return
    }

    request.send(async () => {
      await UserApi.share(props.contractId, props.userAuthRole.EMAIL_ADDRESS);
      Message.info(
        t(texts.userAuthRoleCard.infoEmailSendAfterSharing, {
          email: props.userAuthRole.EMAIL_ADDRESS,
        })
      );
    }, handleUserNotFoundError);
  };

  const canDeleteUser = (): boolean => {
    // users which have internal roles assigned are not allowed to be deleted
    return props.userAuthRole.INTERNAL_AUTH_ROLES.length === 0;
  };

  const canEditUser = (): boolean => {    
    return auth.canEditUserAuthRoles();
  };

  const userIsEditable = (): boolean => {
    return userAuthRole.IS_SYNCHRONIZED === ABAPBoolean.false;
  };

  const canShareContract = (): boolean => {
    return auth.canShareContract();
  };

  /* start backdoor */
  // TODO: remove backdoor when transition of intern users is done!
  const getBackdoorForInterns = (): boolean => {
    if (
      auth.isContractOwner() &&
      config.EXTENDED_SNP_USER_MANAGEMENT === ABAPBoolean.true &&
      session?.USER.EMAIL_ADDRESS === userAuthRole.EMAIL_ADDRESS &&
      (session?.USER.EMAIL_ADDRESS.includes("@snpgroup.com") ||
        session?.USER.EMAIL_ADDRESS.includes("@snp-ag.com") ||
        session?.USER.EMAIL_ADDRESS.includes("@external.snpgroup.com"))
    ) {
      return true;
    }
    return false;
  }
  /* end backdoor */

  const getImmutableRoles = (): AuthRole[] => {
    let immutableRoles: AuthRole[] = [AuthRole.ADMIN, AuthRole.SUPPORT, AuthRole.PRINCIPAL, AuthRole.TESTER];
    /* start backdoor */
    if(getBackdoorForInterns()){
      return immutableRoles;
    }
    /* end backdoor */
    if(auth.canEditCOAndSO()){
      return immutableRoles;
    }
    else {
      return [...immutableRoles, AuthRole.ACCOUNT_MANAGER, AuthRole.PROJECT_LEAD];
    }
  };

  return {
    canDeleteUser,
    canEditUser,
    editMode,
    inputFieldError,
    onAuthRoleActivated,
    onAuthRoleDeactivated,
    onValidate,
    onSave,
    setEditMode,
    setEmail,
    setUserAuthRole,
    share,
    canShareContract,
    userAuthRole,
    getImmutableRoles,
    userIsEditable
  };
};
