import { AppConfig } from "../../AppConfig";
import { IEntity } from "../../shared/types/IEntity";
import { IEntityDetails } from "../../shared/types/IEntityDetails";
import { IFile } from "../../shared/model/IFile";
import { IRepository } from "./IRepository";
import { RESTApi } from "./RESTApi";

export abstract class Repository<T extends IEntity>
  extends RESTApi
  implements IRepository<T>
{
  // Demo data Todo -> remove Delay just for testing reasons
  protected DELAY = 0;
  protected TEST_MODE = false;

  constructor(
    /**
     * Contains the path of this repository.
     * @example
     *    /persons
     */
    protected readonly path: string,
    protected readonly data: T[] = []
  ) {
    super();
  }

  add(entity: IEntityDetails<T>): Promise<T> {
    return this.post(this.url, entity);
  }

  deleteById(id: string): Promise<boolean> {
    if (this.isProdMode) {
      return this.delete(`${this.url}/${id}`);
    }

    return new Promise(async (resolve) => {
      const index = this.data.findIndex((item) => item.OBJECT_ID === id);
      if (index !== -1) {
        this.data.splice(index, 1);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  getFile(url: string): Promise<IFile> {
    return this.get<IFile>(url);
  }

  findAll(): Promise<T[]> {
    if (this.isProdMode) {
      return this.get(this.url);
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.data);
      }, this.DELAY);
    });
  }

  findById(id: string): Promise<T | undefined> {
    if (this.isProdMode) {
      // return this.get(`${this.url}/${id}`);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const item:Promise<T | undefined> = this.get(`${this.url}/${id}`);
          if (item) {
            resolve(item);
          } else {
            reject(`Entity with id "${id}" does not exist`);
          }
        }, 0);
      });
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const item = this.data.find((item) => item.OBJECT_ID === id);
        if (item) {
          resolve(item);
        } else {
          reject(`Entity with id "${id}" does not exist`);
        }
      }, this.DELAY);
    });
  }

  update(entity: T): Promise<T> {
    if (this.isProdMode) {
      return this.put(`${this.url}/${entity.OBJECT_ID}`, entity);
    }

    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({} as T);
      }, this.DELAY);
    });
  }

  /**
   * Contains the url for this repository, a combination of host and path.
   * @example
   *    http://localhost:5000/persons
   */
  protected get url() {
    return `${this.host}${this.path}`;
  }

  /**
   * Contains the host name for this repository.
   * @example
   *    http://localhost:5000
   */
  protected get host() {
    return AppConfig.BACKEND_HOST;
  }

  protected get isProdMode() {
    return !this.TEST_MODE;
  }
}
