import { CSSProperties } from "react";
import { CarouselBody } from "../carouselBody/CarouselBody";
import { CarouselFooter } from "../carouselFooter/CarouselFooter";
import { ICarouselProps } from "./ICarouselProps";
import styles from "./Carousel.module.scss";
import { useCarouselViewModel } from "./useCarouselViewModel";

export const Carousel: React.FC<ICarouselProps> = (props) => {
  const viewModel = useCarouselViewModel(props);

  const style = {
    "--width": props.width,
    "--height": props.height,
  } as CSSProperties;

  return (
    <div className={styles.carousel} style={style}>
      <div className={styles.body}>
        <CarouselBody
          children={props.children}
          isFirstItem={viewModel.isFirstItem}
          isLastItem={viewModel.isLastItem}
          onNext={viewModel.onNext}
          onPrevious={viewModel.onPrevious}
          selectedIndex={viewModel.selectedIndex}
        />
      </div>
      <CarouselFooter
        children={props.children}
        selectedIndex={viewModel.selectedIndex}
        onSelect={viewModel.onSelect}
      />
    </div>
  );
};
