import { ABAPBoolean } from "../shared/types/ABAPBoolean";
import { IProduct } from "./../shared/model/IProduct";
import { IRelease } from "../shared/model/IRelease";
import { ISystemLandscapeSection } from "../shared/model/ISystemLandscapeSection";
import { IVersion } from "../shared/types/IVersion";
import { ProductMeta } from "../shared/model/IProduct";
import { ReleaseMeta } from "../shared/model/IRelease";
import { Repository } from "./core/Repository";
import { SystemLandscapeSectionMeta } from "../shared/model/ISystemLandscapeSection";

class ProductApiDefault extends Repository<IProduct> {
  constructor() {
    super(ProductMeta.path);
  }

  findReleases(productId: string): Promise<IRelease[]> {
    return this.get(`${this.url}/${productId}${ReleaseMeta.path}`);
  }

  findSystemLandscapeSection(
    productObjectId: string
  ): Promise<ISystemLandscapeSection> {
    return this.get(
      `${this.url}/${productObjectId}${SystemLandscapeSectionMeta.path}`
    );
  }

  checkForUpdates(
    productId: string,
    release: string,
    servicePack: string,
    hotfix: string,
    lts: string,
    customerCode: string,
    instnumber: string,
    sysid: string,
    client: string,
    language: string
  ): Promise<IVersion> {
    return this.get(
      `${this.host}/updateCheck?productId=${productId}&release=${release}&servicePack=${servicePack}&hotfix=${hotfix}&lts=${lts}&customerCode=${customerCode}&instnumber=${instnumber}&sysid=${sysid}&client=${client}&language=${language}`
    );
  }

  setAllLicense(
    productObjectId: string,
    is_full_license: boolean,
    REASON: string
  ): Promise<ABAPBoolean> {
    const IS_FULL_LICENSE: ABAPBoolean = is_full_license
      ? ABAPBoolean.true
      : ABAPBoolean.false;
    return this.post(`${this.url}/${productObjectId}/set-all-licenses`, {
      IS_FULL_LICENSE,
      REASON,
    });
  }

  findDescriptionById(productObjectId: string): Promise<string> {
    return this.get(`${this.url}/${productObjectId}/description`);
  }
}

/**
 * This class is responsible for handling all {@link IProduct} specific REST requests.
 */
export const ProductApi = new ProductApiDefault();
