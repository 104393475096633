import { ISystemRole } from "../shared/model/ISystemRole";
import { ISystemRoleInfo } from "./ISystemRoleInfo";

class SystemRoleInfoDefault implements ISystemRoleInfo {
  isLicenseRequired(
    systemRoles: ISystemRole[],
    selectedSystemRoleIds: string[]
  ): boolean {
    for (const selectedSystemRoleId of selectedSystemRoleIds) {
      const systemRole = systemRoles.find(
        (systemRole) => systemRole.SYSTEM_ROLE_ID === selectedSystemRoleId
      );

      if (systemRole && systemRole.IS_LICENSING_REQUIRED) {
        return true;
      }
    }

    return false;
  }
}

export const SystemRoleInfo = new SystemRoleInfoDefault();
