export class LoadContextError extends Error {
  constructor(
    contextName: string,
    hookName: string,
    contextProviderName: string
  ) {
    super(
      `Error while loading '${contextName}'. '${contextName}' is null. Custom hook '${hookName}' must only be called within a component which is a child of an <${contextProviderName} />.`
    );
  }
}
