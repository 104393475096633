import { IHavePath } from "../types/IHavePath";
import { IVersion } from "../types/IVersion";

/**
 * An implementation of this interface represents an entry which contains information about the download,
 * which was provided to the customer for a specific system
 */
export interface ISystemDownloadHistory extends IVersion {
  CREATION_DATE: string;
  CREATION_TIME: string;
}

export const SystemDownloadHistoryMeta: IHavePath = {
  path: "/download-history",
};
