import { AuthRoleProvider } from "../../../components/authRole/AuthRoleProvider";
import { IContractAuthRoleProviderProps } from "./IContractAuthRoleProviderProps";
import { SessionRepository } from "../../../api/session/SessionRepository";
import { UserApi } from "../../../api/UserApi";
import { error } from "../../../utils/error/error";
import { useRecentContracts } from "../../../hooks/useRecentContracts";
import { useRequest } from "../../../hooks/useRequest";
import { useRouteParam } from "../../../hooks/useRouteParam";
import { useSession } from "../../../hooks/useSession";

// eslint-disable-next-line @typescript-eslint/no-unused-vars

/**
 * This component is a concrete AuthRoleProvider which loads user authority roles depending on requested contract information.
 * The contract id must be provided via url (e.g. /contract/<contract-id>/systems).
 * The loaded authority roles can be can be checked via custom hook {@link useAuth}.
 */
export const ContractAuthRoleProvider: React.FC<
  IContractAuthRoleProviderProps
> = (props) => {
  const contractId = useRouteParam<string>("contractId");
  const session = useSession();
  const request = useRequest();
  const recentContracts = useRecentContracts();

  /**
   * Provides the {@link contractId} to the {@link SessionRepository}.
   * This is required to send the {@link contractId} with each REST Request which is contract based.
   */
  const provideContractIdToSessionRepository = (contractId: string) => {
    SessionRepository.setContractId(contractId);
  };

  const addToRecentContracts = (contractId: string) =>
    recentContracts.add(contractId);

  return (
    <AuthRoleProvider
      loadAuthRoles={async () => {
        provideContractIdToSessionRepository(contractId);
        addToRecentContracts(contractId);
        const authRoles = await request.send(async () => {
          return await UserApi.getAuthRolesByContractId(
            session ??
              error(
                "Error while getting user auth roles for contract. Session is undefined."
              ),
            contractId
          );
        });
        return authRoles ?? [];
      }}
    >
      {props.children}
    </AuthRoleProvider>
  );
};
