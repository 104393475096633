import { IServicePack } from "../../shared/model/IServicePack";
import { IVersion } from "../../shared/types/IVersion";

import { filterLowerHotfixes } from "./filterLowerHotfixes";

export const filterLowerServicePacks = (
  version: IVersion,
  servicePacks: IServicePack[]
): IServicePack[] => {
  const resultList: IServicePack[] = [];

  for (const servicePack of servicePacks) {
    // skip service packs, which are lower than my
    if (servicePack.SERVICE_PACK < version.SERVICE_PACK) {
      continue;
    }

    // add service packs, which are higher than my
    if (servicePack.SERVICE_PACK > version.SERVICE_PACK) {
      resultList.push(servicePack);
      continue;
    }

    // service pack is equal, filter hotfixes, which are lower than my
    const hotfixes = filterLowerHotfixes(version, servicePack.HOTFIXES);
    if (hotfixes.length === 0) {
      continue;
    }
    servicePack.HOTFIXES = hotfixes;
    resultList.push(servicePack);
  }

  return resultList;
};
