import { IPublicPageProps } from "./IPublicPageProps";
import { Page } from "../page/Page";

/**
 * This page is responsible for displaying public content.
 * A public page don't need any authentication or authorization and is accessible for everybody.
 */
export const PublicPage: React.FC<IPublicPageProps> = (props) => {
  const { children, ...propsWithoutChildren } = props;
  return <Page {...propsWithoutChildren}>{children}</Page>;
};
