import { ABAPBoolean } from "./../shared/types/ABAPBoolean";

/**
 * Converts the given boolean {@link value} to type of {@link ABAPBoolean}
 */
export const toABAPBoolean = (value: boolean): ABAPBoolean => {
  if (value === true) {
    return ABAPBoolean.true;
  }
  return ABAPBoolean.false;
};
