import { IAsyncLoadProps } from "./IAsyncLoadProps";
import { Spinner } from "@snpjs/core";
import { style } from "../../utils/style";
import styles from "./AsyncLoad.module.scss";
import { useEffect, useState } from "react"; 
/**
 * This component is responsible for displaying a component, which requires data that are loaded asynchronously.
 * As long as the data is not loaded, a loading spinner or a given fallback is displayed instead.
 */
export const AsyncLoad: React.FC<IAsyncLoadProps> = (props) => {
  const [loading, setLoading] = useState(true);

  const loadData = async () => {     
    setLoading(true);
    await props.load();
    setLoading(false);
  };
  
  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {loading ? (
        <div className={style(props.positionCentered ? styles.fallback : "")}>
          {props.fallback ? (
            props.fallback
          ) : (
            <div className={styles.center}>
              <Spinner intent="primary" />
            </div>
          )}
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
