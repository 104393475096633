import { AsyncLoad } from "../asyncLoad/AsyncLoad";
import { ConfigApi } from "../../api/ConfigApi";
import { ConfigContext } from "../../context/ConfigContext";
import { IConfig } from "../../shared/model/IConfig";
import { IConfigContextProviderProps } from "./IConfigContextProviderProps";
import { checkNotNull } from "../../utils/checkNotNull";
import { useRequest } from "../../hooks/useRequest";
import { useState } from "react";

export const ConfigContextProvider: React.FC<IConfigContextProviderProps> = (
  props
) => {
  const request = useRequest();
  const [config, setConfig] = useState<IConfig | undefined>(undefined);

  return (
    <AsyncLoad
      load={async () => {
        await request.send(async () => {
          const config = await ConfigApi.find();
          setConfig(config);
        });
      }}
    >
      {config && (
        <ConfigContext.Provider value={{ config: checkNotNull(config) }}>
          {props.children}
        </ConfigContext.Provider>
      )}
    </AsyncLoad>
  );
};
