import { IParamRoute } from "./IParamRoute";
import { RouteParams } from "./types/RouteParams";

export class ParamRoute<TPath extends string> implements IParamRoute<TPath> {
  constructor(readonly origin: TPath) {}

  toPath<TParams extends RouteParams<TPath>>(params: TParams): string {
    let path: string = this.origin;
    for (const propName in params) {
      path = path.replaceAll(`:${propName}`, params[propName]);
    }
    return path;
  }
}
