import { useLocation, useParams } from "react-router-dom";

import { BreadcrumbIconLoaderType } from "../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbIconLoaders/BreadcrumbIconLoaderType";
import { BreadcrumbIconLoaderTypes } from "../../../features/breadcrumbs/breadcrumbLoader/breadcrumbIconLoader/BreadcrumbIconLoaderTypes";
import { BreadcrumbLoaderRepo } from "../../../services/breadcrumbs/breadcrumbLoaders/BreadcrumbLoaderRepo";
import { BreadcrumbNameLoaderType } from "../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoaderType";
import { BreadcrumbNameLoaderTypes } from "../../../features/breadcrumbs/breadcrumbLoader/breadcrumbNameLoader/BreadcrumbNameLoaderTypes";
import { Breadcrumbs } from "../breadcrumbs/Breadcrumbs";
import { FilteredBreadcrumbPathSegments } from "../../../features/breadcrumbs/FilteredBreadcrumbPathSegments";
import { PathConverter } from "../../../services/pathConverter/PathConverter";
import { Routes } from "../../../routes/Routes";
import { useBreadcrumbFactory } from "../../../services/breadcrumbs/breadcrumbFactory/useBreadcrumbFactory";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars

/**
 * This component creates breadcrumbs by parsing the url.
 * If a {@link Page} has the parameter set to show breadcrumbs, they will automatically be build.
 */
export const BreadcrumbsProvider: React.FC = () => {
  const path = useLocation().pathname;
  const params = useParams();

  const pathSegments = useMemo(
    () => new PathConverter(path, params, Routes).convertToPathSegments(),
    [params, path]
  );

  const breadcrumbs = useBreadcrumbFactory(
    new BreadcrumbLoaderRepo<BreadcrumbNameLoaderType>(
      ...BreadcrumbNameLoaderTypes
    ),
    new BreadcrumbLoaderRepo<BreadcrumbIconLoaderType>(
      ...BreadcrumbIconLoaderTypes
    ),
    FilteredBreadcrumbPathSegments
  ).create(pathSegments);

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
