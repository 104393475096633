import { ISystemActionLog } from "../shared/model/ISystemActionLog";
import { Repository } from "./core/Repository";
import { SystemActionLogMeta } from "../shared/model/ISystemActionLog";

class SystemActionLogApiDefault extends Repository<ISystemActionLog> {
  constructor() {
    super(SystemActionLogMeta.path);
  }
}

export const SystemActionLogApi = new SystemActionLogApiDefault();
