import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";
import { IProduct } from "./IProduct";
import { IProductBundle } from "./IProductBundle";

/**
 * An implementation of this interface represents a customer contract.
 * It contains e.g. all products which are part of that contract.
 */
export interface IContract extends IEntity {
  CUSTOMER_ID: string;
  CUSTOMER_NAME: string;
  CUSTOMER_TYPE: string;
  PROJECT_ID: string;
  CONTRACT_TYPE_ID: string;
  DESCRIPTION: string;
  PRODUCTS?: IProduct[];
  PRODUCT_BUNDLES?: IProductBundle[];
  EMAIL_RESPONSIBLE_PERSONS: string[];
}

export const ContractMeta: IEntityMeta<IContract> = { path: "/contracts" };
