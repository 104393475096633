import { IProductBundleProps } from "./IProductBundleProps";
import { Products } from "../products/Products";
import React from "react";
import { Routes } from "../../../routes/Routes";
import { useNavigate } from "react-router-dom";

/**
 * This component is responsible for displaying all purchased products of a customer.
 */
export const ProductBundle: React.FC<IProductBundleProps> = (props) => {
  const navigate = useNavigate();
  const items = props.productBundles?.map((bundle, index) => (
    <Products
      key={index}
      contractId={props.contractId}
      description={bundle.DESCRIPTION}
      highlightedProductIds={props.highlightedProductIds}
      products={bundle.PRODUCTS}
      onProductSelect={(productId) =>
        navigate(
          Routes.contractProduct.toPath({
            contractId: props.contractId,
            productId: productId,
          })
        )
      }
    />
  ));

  return <>{items}</>;
};
