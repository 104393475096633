import { ColumnHeader } from "../conflictListColumnHeader/ColumnHeader";
import { texts } from "../../../../i18n/texts";
import useTranslation from "../../../../hooks/useTranslation";

export const ConflictListColumns: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ColumnHeader caption={t(texts.general.projectId)} />
      <ColumnHeader caption={t(texts.general.system.systemId)} />
      <ColumnHeader caption={t(texts.general.system.systemClient)} />
      <ColumnHeader caption={t(texts.general.system.installNumber)} />
      <ColumnHeader caption={t(texts.general.system.release)} />
      <ColumnHeader caption={t(texts.general.system.isLTS)} />
      <ColumnHeader caption={t(texts.general.system.servicePack)} />
      <ColumnHeader caption={t(texts.general.system.hotfix)} />
      <ColumnHeader caption={t(texts.general.description)} />
      <ColumnHeader caption={t(texts.general.action)} />
    </>
  );
};
