import { BreadcrumbLoader } from "../../../../decorators/BreadcrumbLoader";
import { BreadcrumbNameLoader } from "../../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoader";
import { ContractApi } from "../../../../api/ContractApi";
import { Routes } from "../../../../routes/Routes";

import { checkNotNull } from "../../../../utils/checkNotNull";

/**
 * This class is responsible for providing a name for the contract breadcrumb.
 * It loads the name of the contract by its id.
 */
@BreadcrumbLoader(Routes.contracts.toPath())
export class ContractBreadcrumbNameLoader extends BreadcrumbNameLoader {
  name = new Promise<string>(async (resolve) => {
    const id = checkNotNull(
      this.id,
      "Error when reading id because it was not provided. Provide an id in the constructor of the loader"
    );

    try {
      const projectId = await ContractApi.create(id).findProjectIdById(id);
      resolve(projectId ?? "");
    } catch (error) {}
  });
}
