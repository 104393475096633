import { Button } from "@snpjs/core";
import { ILoadingSpinnerButtonProps } from "./ILoadingSpinnerButtonProps";
import { Spinner } from "../spinner/Spinner";

export const LoadingSpinnerButton: React.FC<ILoadingSpinnerButtonProps> = (
  props
) => {
  const {
    showSpinner: isLoading,
    spinnerClassName,
    buttonClassNames,
    ...buttonProps
  } = props;

  buttonProps.icon = isLoading ? (
    <Spinner className={spinnerClassName} />
  ) : (
    props.icon
  );

  return <Button {...buttonProps} className={buttonClassNames} />;
};
