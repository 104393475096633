import { Card, Icon, Section, SectionCard } from "@snpjs/core";

import { AsyncLoad } from "../../../components/asyncLoad/AsyncLoad";
import { EditableSection } from "../../../components/editableSection/EditableSection";
import { IUserAuthRole } from "../../../shared/model/IUserAuthRole";
import { IUserAuthRoleSectionProps } from "./IUserAuthRoleSectionProps";
import { UserAuthRoleApi } from "../../../api/UserAuthRoleApi";
import { UserAuthRoleCard } from "../userAuthRoleCard/UserAuthRoleCard";
import { UserAuthRoleDummy } from "../../../model/UserAuthRoleDummy";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";
import { useUserAuthRoleSectionViewModel } from "./useUserAuthRoleSectionViewModel";

/**
 * This component is responsible for displaying the user authority roles
 * with its details and providing components to add new user authority roles.
 */
export const UserAuthRoleSection: React.FC<IUserAuthRoleSectionProps> = (
  props
) => {
  const { t } = useTranslation();
  const viewModel = useUserAuthRoleSectionViewModel(props);

  return (
    <AsyncLoad
      load={async () => {
        await viewModel.initialLoad();
      }}
    >
      <Section
        title={t(texts.userManagement.breadcrumb)}
        icon={<Icon icon="users" />}
      >
        <SectionCard padded={false}>
          <Card compact elevation={2}>
            <EditableSection<IUserAuthRole>
              canAdd={viewModel.canAddUser}
              createCard={(userAuthRole, _, onDelete, onSave) => {
                return (
                  <UserAuthRoleCard
                    contractId={props.contractId}
                    onSave={onSave}
                    onDelete={onDelete}
                    authRoles={viewModel.displayedAuthRoles}
                    userAuthRole={userAuthRole}
                  />
                );
              }}
              dummyClassType={UserAuthRoleDummy}
              labelAddButton={t(texts.userAuthRoleSection.labelAddButton)}
              numberOfColumns={1}
              onAdd={(userAuthRole) => viewModel.onSave(userAuthRole)}
              onDelete={(userAuthRole) => viewModel.onDelete(userAuthRole)}
              onUpdate={(userAuthRole) => viewModel.onUpdate(userAuthRole)}
              loadObjects={async () => {
                const users = await viewModel.findByContractId();
                if (!viewModel.isGlobalUser()) {
                  const filteredExternalRoles = users.filter((user) =>
                    user.USER_AUTH_ROLES.some((role) =>
                      viewModel.displayedAuthRoles.some(
                        (displayedAuthRoles) =>
                          displayedAuthRoles.USER_ROLE_ID === role
                      )
                    )
                  );
                  return filteredExternalRoles;
                } else {
                  return users;
                }
              }}
              repository={UserAuthRoleApi}
            />
          </Card>
        </SectionCard>
      </Section>
    </AsyncLoad>
  );
};
