import { PlaceholderText } from "../../components/placeholderText/PlaceholderText";
import { fillTextPlaceholders } from "./fillTextPlaceholders";

/**
 * Returns if {@link placeholders} contains a property which is of type object.
 * @example
 * // Here property link would be of object type JSX.Element. So the function would return true
 * { contractId: "123", link: <></> };
 */
function containsObjectProp<T extends object>(placeholders: T) {
  for (const propName in placeholders) {
    if (typeof placeholders[propName] === "object") {
      return true;
    }
  }
  return false;
}

/**
 * Returns {@link text} filled with all given {@link placeholders}.
 * If the placeholders are just text, the placeholder would be replaced by the corresponding text and a string is returned.
 * If at least one placeholder is of type object, the result is a JSX.Element, which wraps the text by fragments.
 */
export function fillPlaceholders<T extends object>(
  text: string,
  placeholders: T
): T[keyof T] {
  // check if placeholders contains a property of type object
  // if so, it has to be wrapped by a component, so we cannot return a simple string, but a JSX.Element
  if (containsObjectProp(placeholders)) {
    return (
      <PlaceholderText placeholders={placeholders} text={text} />
    ) as T[keyof T];
  }

  return fillTextPlaceholders(text, placeholders) as T[keyof T];
}
