import { IMultiSelectionDropDownProps } from "./IMultiSelectionDropDownProps";
import { ItemPredicate, ItemRenderer, MultiSelect, Select } from "@snpjs/select";
import { Button, MenuItem } from "@snpjs/core";
import React, {  useEffect } from "react";
import styles from "./MultiSelectionDropDown.module.scss";
import { Colors } from "@snpjs/core";
export function MultiSelectionDropDown<T>(props: IMultiSelectionDropDownProps<T>) {

  const findActive = (): T[] => {
    const activeObjects: T[] = []
    props.objects.map ((object) => {
      if (props.isSelected(object)) {
        activeObjects.push(object);
      }
    })
    return activeObjects;
  };

  const [selectedObjects, setSelectedObjects] = React.useState<T[]>(findActive());
  
  useEffect(() => {
    if(props.editMode === true && findActive()){
      setSelectedObjects(findActive());
    }
  }, [props.editMode])

  const selectObject = (object: T) => {
    if (!selectedObjects.some(selected => props.getObjectId(selected) === props.getObjectId(object))) {
      props.onUpdate([...selectedObjects, object]);
      setSelectedObjects([...selectedObjects, object]);
    }
  }

  const removeObject = (object: T, index: number) => {
    if (selectedObjects.some(selected => props.getObjectId(selected) === props.getObjectId(object))) {
      const updatedObjects = selectedObjects.filter((_object, i) => i !== index)
      props.onUpdate(updatedObjects);
      setSelectedObjects(updatedObjects);
    }
  }
    
  const disabledStyle = {
    backgroundColor: Colors.MONOCHROME_GRAY3,
    color: Colors.BLACK,
    cursor: "auto"
  };

  const filter: ItemPredicate<T> = (query, object, _index, exactMatch) => {
    const normalizedTitle = props.getName(object).toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${props.getName(object).toLowerCase()}. ${props.getObjectId(object).toLowerCase()} ${props.getInternalId(object).toLowerCase()}`.indexOf(normalizedQuery) >= 0;
    }
  };

  const render: ItemRenderer<T> = (object, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={props.getObjectId(object)}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={`${props.getName(object)}`}
      />
    );
  };
    const activeObjects: string[] = []
    if(props.objects.length === 0){
      activeObjects.push("No selection")
    } else {
      findActive().map((object) => {
        activeObjects.push(props.getName(object));
      })
    }

  return (
    <div className={styles.button}>
      { props.editMode ? (
          <MultiSelect<T>
            disabled={!props.editMode}
            onRemove={(_object, index) => removeObject(_object, index)}
            items={props.objects}
            itemPredicate={filter}
            itemRenderer={render}
            noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
            onItemSelect={selectObject}
            placeholder="No selection"
            selectedItems={selectedObjects}
            tagRenderer={(object) => props.getName(object)}
          />
      ) : (
        <div className={styles.displayRoles}>
          {activeObjects.length === 0 ? (
            <div>
              <Button
                style={disabledStyle}
                disabled={!props.editMode}
                text={"No selection"}
                fill={true}
              />
            </div>
          ) : (
            activeObjects.map((buttonText, index) => (
              <Select<T>
                key={index}
                filterable={true}
                items={props.objects}
                itemPredicate={filter}
                itemRenderer={render}
                noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
                onItemSelect={() => {}}
              >
                <Button
                  style={disabledStyle}
                  disabled={!props.editMode}
                  text={buttonText}
                  fill={true}
                />
              </Select>
          )))}
          </div>
      )}
    </div>
  );
}
