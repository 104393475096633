import { ISystemHeaderFieldsProps } from "./ISystemHeaderFieldsProps";

export const useSystemHeaderFieldsViewModel = (
  props: ISystemHeaderFieldsProps
) => {
  /**
   * if input fields are undefined and then later change to a value, they need
   * initializing beforehand as a string to avoid an uncontrolled error
   * */
  return {
    client: props.system?.SYSTEM_CLIENT ?? "",
    editMode: props.editMode,
    installNumber: props.system?.INSTNUMBER ?? "",
    inputFieldErrorSid: props.inputFieldErrorSid,
    inputFieldErrorClient: props.inputFieldErrorClient,
    inputFieldErrorInstallNumber: props.inputFieldErrorInstallNumber,
    inputFieldErrorDescription: props.inputFieldErrorDescription,
    setClient: props.onSetClient,
    setInstallNumber: props.onSetInstallNumber,
    setDescription: props.onSetDescription,
    setSid: props.onSetSid,
    systemId: props.system?.SYSTEM_ID ?? "",
    description: props.system?.DESCRIPTION ?? "",
  };
};
