import { Breadcrumb } from "./Breadcrumb";
import { IBreadcrumbsProps } from "./IBreadcrumbsProps";
import { style } from "../../../utils/style";
import styles from "./Breadcrumbs.module.scss";

/**
 * This component is responsible for displaying breadcrumbs to inform the user where he is.
 */
export const Breadcrumbs: React.FC<IBreadcrumbsProps> = (props) => {
  const items = () => {
    const length = props.breadcrumbs.length;
    return props.breadcrumbs.map((item, index) => (
      <Breadcrumb
        key={item.route}
        breadcrumb={item}
        isLastBreadcrumb={index === length - 1}
      />
    ));
  };

  return (
    <ul className={style(styles.breadcrumbs, props.className)}>{items()}</ul>
  );
};
