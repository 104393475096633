import { NonIdealState, NonIdealStateIconSize } from "@snpjs/core";

import { IUnavailableMessageProps } from "./IUnavailableMessageProps";

export const UnavailableMessage: React.FC<IUnavailableMessageProps> = (
  props
) => {
  return (
    <NonIdealState
      icon={props.icon}
      iconSize={NonIdealStateIconSize.SMALL}
      title={props.title}
      description={props.description}
      layout="horizontal"
    />
  );
};
