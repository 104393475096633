import { IVersionDetailsProps } from "./IVersionDetailsProps";
import { ServicePackVersionDetails } from "../servicePackVersionDetails/ServicePackVersionDetails";


export const VersionDetails: React.FC<IVersionDetailsProps> = (props) => {

  return (
    <>
      <ServicePackVersionDetails servicePackVersion={props.version} />
    </>
  );
};
