import { IDetailProps } from "./IDetailProps";
import { style } from "../../utils/style";
import styles from "./Detail.module.scss";

/**
 * This component is responsible for displaying a specific detail
 */
export const Detail: React.FC<IDetailProps> = (props) => {
  return (
    <div className={style(styles.detail, props.className)}>
      <div>{props.title}</div>
      <div>{props.value}</div>
    </div>
  );
};
