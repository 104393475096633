import { createBrowserRouter, redirect } from "react-router-dom";

import { ConflictManagementPage } from "../pages/ConflictManagementPage";
import { ContractDashboardPage } from "../pages/ContractDashboardPage";
import { ContractPage } from "../pages/ContractPage";
import { ErrorPage } from "../pages/ErrorPage";
import { InstallGuidePage } from "../pages/InstallGuidePage";
import { LandingPage } from "../pages/LandingPage";
import { LoginPage } from "../pages/LoginPage";
import { ProductPage } from "../pages/ProductPage";
import { Routes } from "./Routes";
import { UserAuthRolePage } from "../pages/UserAuthRolePage";
import { UpdateCheck } from "../pages/UpdateCheckPage";

/**
 * This constant provides access to the routes of this app and its corresponding components.
 */
export const AppRouter = createBrowserRouter([
  {
    path: Routes.systemConflictManagement.origin,
    element: <ConflictManagementPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.systemInstallChecklist.origin,
    element: <InstallGuidePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.contracts.origin,
    element: <ContractDashboardPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.contract.origin,
    element: <ContractPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.contractProduct.origin,
    element: <ProductPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.homePage.origin,
    errorElement: <ErrorPage />,
    loader: async () => {
      return redirect(Routes.contracts.toPath());
    },
  },
  {
    path: Routes.contractUserAuthRole.origin,
    element: <UserAuthRolePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.login.toPath(),
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.welcome.origin,
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.updateCheck.origin,
    element: <UpdateCheck />,
    errorElement: <ErrorPage />,
  },
]);
