import { IUserProfile } from "../model/IUserProfile";
import { IValue } from "../types/IValue";

import { useState } from "react";
import { readSessionStorage } from "../utils/readSessionStorage copy";
import { writeSessionStorage } from "../utils/writeSessionStorage";

const LOCAL_STORAGE_USER_PROFILE = "swpo.user-profile";

export const useUserProfileStorage = (): IValue<IUserProfile> => {
  const [value, setValue] = useState<IUserProfile>(() => {
    const ret: IUserProfile = readSessionStorage(LOCAL_STORAGE_USER_PROFILE) ?? {
      collapseLicenseOptions: [],
      collapsePageInfo: false,
      collapseSystems: false,
    }
    if(typeof ret.collapseLicenseOptions === "boolean") {
      ret.collapseLicenseOptions = [];
    }
    return ret
  });

  const updateSessionStorage = (newValue: IUserProfile) =>{
    writeSessionStorage(LOCAL_STORAGE_USER_PROFILE, newValue);
  }

  const updateValue = (newValue: React.SetStateAction<IUserProfile>) => {
    setValue((previous) => {
      if (typeof newValue === "function") {
        const updatedValue = newValue(previous);
        updateSessionStorage(updatedValue);
        return updatedValue;
      } else {
        updateSessionStorage(newValue);
        return newValue;
      }
    });
  };

  return { value, setValue: updateValue };
};
