import { useEffect, useState } from "react";

import { EditIcon } from "../editIcon/EditIcon";
import { IInputWithEditButtonProps } from "./IInputWithEditButtonProps";
import { InputDialog } from "../inputDialog/InputDialog";
import { Spinner } from "../spinner/Spinner";
import { request } from "../../utils/request";
import styles from "./InputWithEditButton.module.css";

export function InputWithEditButton<T>(props: IInputWithEditButtonProps<T>) {
  const [showInputDialog, setShowInputDialog] = useState(false);
  const [inputValue, setInputValue] = useState(props.initialValue);
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialized) {
      request(async () => {
        setIsLoading(true);
        await props.onEdit?.(inputValue);
        setIsLoading(false);
      });
    }
    setInitialized(true);
  }, [inputValue]); // !!! [initialized, inputValue, null] => infinite loop!

  return (
    <div className={styles.inputWithEditButton}>
      <span>{inputValue as string}</span>
      {isLoading ? (
        <Spinner className={styles.spinner} />
      ) : (
        <EditIcon icon="pencil" onClick={() => setShowInputDialog((previous) => !previous)} />
      )}
      {showInputDialog && (
        <InputDialog
          initialValue={inputValue}
          onOk={(value) => {
            setInputValue(value);
            setShowInputDialog(false);
          }}
          onCancel={() => setShowInputDialog(false)}
          validate={props.validate}
          autoFocus
          widthInRem={props.widthInRem}
        />
      )}
    </div>
  );
}
