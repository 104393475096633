import { ISystemFilterProps } from "../shared/types/ISystemFilterProps";

export const isSystemFilterPropsNotEmpty = (
  systemFilterProps: ISystemFilterProps
): boolean => {
  return (
    (systemFilterProps.RELEASE !== undefined &&
      systemFilterProps.RELEASE > 0) ||
    (systemFilterProps.SERVICE_PACK !== undefined &&
      systemFilterProps.SERVICE_PACK > 0) ||
    (systemFilterProps.HOTFIX !== undefined && systemFilterProps.HOTFIX > 0) ||
    (systemFilterProps.SYSTEM_ID !== undefined &&
      systemFilterProps.SYSTEM_ID.length > 0) ||
    (systemFilterProps.SYSTEM_CLIENT !== undefined &&
      systemFilterProps.SYSTEM_CLIENT.length > 0) ||
    (systemFilterProps.INSTNUMBER !== undefined &&
      systemFilterProps.INSTNUMBER.length > 0) ||
    (systemFilterProps.DESCRIPTION !== undefined &&
      systemFilterProps.DESCRIPTION.trim().length > 0)
  );
};
