export enum Environment {
  /**
   * Development
   */
  DEV = "DEV",
  /**
   * Test
   */
  TEST = "TEST",
  /**
   * Production
   */
  PROD = "PROD",
}
