import Enums from "../../../utils/Enums";
import { IUserGuideMenuItemProps } from "./IUserGuideMenuItemProps";
import { LanguageMenuItem } from "../../languageMenuItem/LanguageMenuItem";
import { LanguageType } from "../../../types/LanguageType";
import { MenuItem } from "@snpjs/core";

/**
 * User Guide Menu Item.
 */
export const UserGuidMenuItem: React.FC<IUserGuideMenuItemProps> = (props) => {
  const key = Enums.getKey(LanguageType, props.languageType);
  const href = "https://goto.snpgroup.com/swpo/" + key + "/documentation";

  return (
    <>
      <MenuItem
        text={<LanguageMenuItem languageType={props.languageType} />}
        href={href}
        target="_blank"
      />
    </>
  );
};
