import { DefaultRESTError } from "../errors/DefaultRESTError";
import { IRESTErrorSAP } from "../types/IRESTErrorSAP";
import { NoRestMethodError } from "../errors/NoRestMethodError";
import { RESTError } from "../core/RESTError";
import { RESTErrorClassType } from "../types/RESTErrorClassType";
import { RESTErrorRegistry } from "../errors/_RESTErrorRegistry";

/**
 * Creates a REST error of type {@link RESTError} from the given {@link origin}.
 * The mapping of the SAP specific exception to the TypeScript specific error must be maintained in {@link RESTErrorRegistry}.
 * If the mapping is not available an error is thrown.
 */
export const createRESTError = (
  origin: IRESTErrorSAP,
  response: Response
): RESTError => {
  const originException = origin.CLASS.toUpperCase();
  const classType = (RESTErrorRegistry as any)[
    originException
  ] as RESTErrorClassType;
  if (!classType) {
    console.log(origin);
    if (originException === NoRestMethodError.originException) {
      console.log(`Missing endpoint for '${response.url}'`);
    }

    return new DefaultRESTError(origin);
  }
  return new classType(origin);
};
