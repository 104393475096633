import { Dialog } from "../../../components/dialog/Dialog";
import { DialogFooter } from "../../../components/dialogFooter/DialogFooter";
import { ISystemActionLogDialogProps } from "./ISystemActionLogDialogProps";
import { SystemActionLog } from "../systemActionLog/SystemActionLog";
import { useState } from "react";
import { useValidatorConsumer } from "../../../hooks/useValidatorConsumer";

export const SystemActionLogDialog: React.FC<ISystemActionLogDialogProps> = (
  props
) => {
  const [text, setText] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const validator = useValidatorConsumer();

  const onOkay = async () => {
    setIsConfirming(true);
    if (!validator.isValid()) {
      setIsConfirming(false);
      return;
    }
    await props.onOkay(text);
    setIsConfirming(false);
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      footer={
        <DialogFooter
          onCancel={props.onCancel}
          onOkay={onOkay}
          isConfirming={isConfirming}
        />
      }
      onCancel={props.onCancel}
      title={props.title}
    >
      <SystemActionLog
        isReasonRequired={props.isReasonRequired}
        setText={setText}
        label={props.subTitle}
        text={text}
        requestValidator={validator.onRequestValidator}
      />
    </Dialog>
  );
};
