import { useCallback, useEffect, useState } from "react";

import { IEmergencyLicenseProps } from "./IEmergencyLicenseProps";
import { ValidationError } from "../../../errors/ValidationError";
import { ValidationHandler } from "../../../types/ValidationHandler";
import styles from "./EmergencyLicense.module.scss";
import useTranslation from "../../../hooks/useTranslation";
import { useValidatorConsumer } from "../../../hooks/useValidatorConsumer";
import { useValidatorProvider } from "../../../hooks/useValidatorProvider";
import { SystemActionLog } from "../systemActionLog/SystemActionLog";
import { texts } from "../../../i18n/texts";
import { LabeledCheckbox } from "../../../components/labeledCheckbox/LabeledCheckbox";

/**
 * This component is responsible for displaying release, service pack and hotfix to set an alternative download version.
 * And addition the user has to provide add a reason for changing the version.
 */
export const EmergencyLicense: React.FC<IEmergencyLicenseProps> = (props) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const actionLogValidator = useValidatorConsumer();
  const checkBoxValidator = useValidatorConsumer();

  const onValidate: ValidationHandler = useCallback(() => {
    // Call both validators for printing error messages
    const actionLogValid = actionLogValidator.isValid();
    const checkBoxValid = checkBoxValidator.isValid();
    
    if (!actionLogValid || !checkBoxValid) {
      throw new ValidationError();
    }
  }, [actionLogValidator, checkBoxValidator]);
  const validator = useValidatorProvider(onValidate);

  useEffect(() => {
    props.requestValidator?.(validator);
  },[props.requestValidator, validator])
  
  return (
    
    <div className={styles.emergencyLicense}>
      {props.subTextTranslation && 
        (
          <>
            <div className={styles.preWrap}>{t(props.subTextTranslation)}</div>
          </>
        )
      }
      {
        <div>
          <SystemActionLog
            isReasonRequired={true}
            label={t(texts.SystemGrantEmergencLicense.labelTextArea)}
            requestValidator={actionLogValidator.onRequestValidator}
            setText={props.setReason}
            text={props.reason}
          />
          <div className={styles.checkboxContainer}>
            <LabeledCheckbox className={styles.confirmCheckbox}
              requestValidator={checkBoxValidator.onRequestValidator}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              >
              <label>{t(texts.SystemGrantEmergencLicense.checkboxText)}</label>
            </LabeledCheckbox>
          </div>
        </div>        
      }
    </div>
  );
};
