import { LanguageSprasType } from "../types/LanguageSprasType";
import { LanguageType } from "../../types/LanguageType";

export const SprasToLanguageTypeMapper = (
  languageSprasType: LanguageSprasType
): LanguageType => {
  switch ((LanguageSprasType as any)[languageSprasType]) {
    case LanguageSprasType.D:
      return LanguageType.DE;
    case LanguageSprasType.E:
      return LanguageType.EN;
    case LanguageSprasType.J:
      return LanguageType.JA;
    default:
      throw new Error(
        `LanguageSprasType ${languageSprasType} not implemented.`
      );
  }
};
