import { ISignal } from "./ISignal";

/**
 * This class represents any kind of signal
 */
export class Signal<T = void> implements ISignal<T> {
  readonly createAt: Date = new Date();

  constructor(readonly value: T) {}
}
