import { IContract } from "../shared/model/IContract";
import { IEntityDetails } from "../shared/types/IEntityDetails";
import { InstallChecklistMeta } from "../shared/model/IInstallChecklist";
import { ISystem } from "./../shared/model/ISystem";
import { ISystemDoc } from "../shared/types/ISystemDoc";
import { ISystemDownloadHistory } from "../shared/model/ISystemDownloadHistory";
import { ISystemDownloadVersion } from "../shared/model/ISystemDownloadVersion";
import { ISystemIntendedVersion } from "../shared/model/ISystemIntendedVersion";
import { ISystemVersion } from "../shared/types/ISystemVersion";
import { ProductMeta } from "../shared/model/IProduct";
import { Repository } from "./core/Repository";
import { SystemDocPathMeta } from "../shared/model/ISystemDocPath";
import { SystemDownloadHistoryMeta } from "../shared/model/ISystemDownloadHistory";
import { SystemDownloadVersionMeta } from "../shared/model/ISystemDownloadVersion";
import { SystemIntendedVersionMeta } from "../shared/model/ISystemIntendedVersion";
import { SystemLandscapeMeta } from "../shared/model/ISystemLandscape";
import { SystemLastDownloadVersionMeta } from "../shared/model/ISystemDownloadVersion";
import { SystemMeta } from "../shared/model/ISystem";

import { isVersionInitial } from "../utils/version/isVersionInitial";

class SystemApiDefault extends Repository<ISystem> {
  constructor() {
    super(SystemMeta.path);
  }

  addBySystemLandscapeId(
    systemLandscapeId: string,
    item: IEntityDetails<ISystem>
  ): Promise<ISystem> {
    return this.createPromise(async (resolve, reject) => {
      try {
        const data = await this.post<ISystem>(
          `${this.host}${SystemLandscapeMeta.path}/${systemLandscapeId}${this.path}`,
          item
        );
        resolve(this.tidySystemDownloadHistory(data));
      } catch (error) {
        reject(error);
      }
    });
  }

  downloadFile(downloadUrl: string) {
    try {
      // Create a link element
      const a = document.createElement("a");
      a.href = downloadUrl;
      //   a.download = filename;

      // Append the link to the document body
      document.body.appendChild(a);

      // Programmatically click the link to trigger the download
      a.click();

      // Clean up by removing the link
      a.remove();
    } catch (error) {
      console.error("Download failed:", error);
    }
  }

  async fetchData(url: string) {
      const response = await fetch(url);
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error[0].SHORT_TEXT);
      }
      const data = await response.json();
      return data;
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  downloadTransportFile(systemObjectId: string): Promise<boolean> {
    return this.createPromise(async (resolve, reject) => {
      const url = `${this.url}/${systemObjectId}/software-bundle`
      try{
        const data = await this.fetchData(this.toSessionURL(url))
        resolve(true);   
        await this.sleep(2000);
        this.downloadFile(
          data.URI
        );
      }catch(error){
        reject(error);
      }
    });
  }
  downloadEmergencyLicenseFile(systemObjectId: string, reason: string): Promise<boolean> {
    // TODO reason needs to be added to the request
    return this.createPromise(async (resolve, reject) => {
      const url = `${this.url}/${systemObjectId}/software-emergency-license`
      try{
        const data : any = await this.post(this.toSessionURL(url), reason)
        resolve(true);   
        await this.sleep(2000);
        this.downloadFile(
          data.URI
        );
      }catch(error){
        reject(error);
      }
    });
  }

  downloadLicenseFile(systemObjectId: string): Promise<boolean> {
    return this.createPromise(async (resolve, reject) => {
      const url = `${this.url}/${systemObjectId}/software-license`
      try{
        const data = await this.fetchData(this.toSessionURL(url))
        resolve(true);   
        await this.sleep(2000);
        this.downloadFile(
          data.URI
      );
      }catch(error){
        reject(error);
      }
    });
  }

  findByProductId(productId: string): Promise<ISystem[]> {
    return this.getTidiedSystems(
      `${this.host}${ProductMeta.path}/${productId}${this.path}`
    );
  }

  findBySystemLandscapeId(systemLandscapeId: string): Promise<ISystem[]> {
    return this.getTidiedSystems(
      `${this.host}${SystemLandscapeMeta.path}/${systemLandscapeId}${this.path}`
    );
  }

  findDownloadHistory(
    systemObjectId: string
  ): Promise<ISystemDownloadHistory | undefined> {
    return this.createPromise(async (resolve, reject) => {
      try {
        const downloadHistory = await this.get<ISystemDownloadHistory>(
          this.toSessionURL(`${this.host}${SystemMeta.path}/${systemObjectId}${SystemDownloadHistoryMeta.path}`)
        );
        resolve(
          this.isSystemDownloadHistoryEmpty(downloadHistory)
            ? undefined
            : downloadHistory
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  findInstallChecklist(systemObjectId: string): Promise<string> {
    return this.get(
      `${this.url}/${systemObjectId}${InstallChecklistMeta.path}`
    );
  }

  findUserDocs(systemObjectId: string): Promise<ISystemDoc[]> {
    return this.get(`${this.url}/${systemObjectId}${SystemDocPathMeta.path}`);
  }

  getContractById(systemObjectId: string): Promise<IContract> {
    return this.get(`${this.url}/${systemObjectId}/contract`);
  }

  getDownloadVersion(systemObjectId: string): Promise<ISystemVersion> {
    return this.get(
      `${this.url}/${systemObjectId}${SystemDownloadVersionMeta.path}`
    );
  }

  getIntendedVersion(systemObjectId: string): Promise<ISystemIntendedVersion> {
    return this.get(
      `${this.url}/${systemObjectId}${SystemIntendedVersionMeta.path}`
    );
  }

  getLastDownloadVersion(systemObjectId: string): Promise<ISystemVersion> {
    return this.get(
      `${this.url}/${systemObjectId}${SystemLastDownloadVersionMeta.path}`
    );
  }

  setDownloadVersion(systemDownloadVersion: ISystemDownloadVersion) {
    return this.post(
      `${this.url}/${systemDownloadVersion.SYSTEM_OBJECT_ID}${SystemDownloadVersionMeta.path}`,
      systemDownloadVersion
    );
  }

  private getTidiedSystems(path: string): Promise<ISystem[]> {
    return this.createPromise(async (resolve, reject) => {
      try {
        const data = await this.get<ISystem[]>(path);
        resolve(this.tidySystemsDownloadHistory(data));
      } catch (error) {
        reject(error);
      }
    });
  }

  private tidySystemDownloadHistory(system: ISystem): ISystem {
    if (this.isSystemDownloadHistoryEmpty(system.DOWNLOAD_HISTORY)) {
      system.DOWNLOAD_HISTORY = undefined;
    }
    return system;
  }

  /**
   * Checks if the submitted download history is not set.
   * If so, replace it by undefined.
   */
  private tidySystemsDownloadHistory(systems: ISystem[]): ISystem[] {
    systems.forEach((system) => this.tidySystemDownloadHistory(system));
    return systems;
  }

  /**
   * Returns if the given {@link downloadHistory} is empty.
   * In SAP it means that props have specific values like 0000 or 00.
   */
  private isSystemDownloadHistoryEmpty(
    downloadHistory?: ISystemDownloadHistory
  ) {
    return downloadHistory && isVersionInitial(downloadHistory);
  }
}
/**
 * This class is responsible for handling all {@link ISystem} specific REST requests.
 */
export const SystemApi = new SystemApiDefault();
