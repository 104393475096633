import { AsyncLoad } from "../../components/asyncLoad/AsyncLoad";
import { IInstallGuideProps } from "./IInstallGuideProps";
import Markdown from "react-markdown";
import { MissingInstallGuideError } from "../../api/errors/MissingInstallGuideError";
import { SystemApi } from "../../api/SystemApi";
import remarkGfm from "remark-gfm";
import styles from "./InstallGuide.module.scss";
import { texts } from "../../i18n/texts";
import { useRequest } from "../../hooks/useRequest";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";

export const InstallGuide: React.FC<IInstallGuideProps> = (props) => {
  const request = useRequest();
  const [displayError, setDisplayError] = useState(false);
  const [content, setContent] = useState("");
  const { t } = useTranslation();

  return (
    <AsyncLoad
      load={async () => {
        await request.send(
          async () => {
            const content = await SystemApi.findInstallChecklist(
              props.systemObjectId
            );
            setContent(content);
          },
          (error) => {
            if (error instanceof MissingInstallGuideError) {
              setDisplayError(true);
              return true;
            }
            return false;
          }
        );
      }}
    >
      <div className={styles.installGuide}>
        {displayError && (
          <h3>{t(texts.installChecklist.errorLoadingInstallChecklist)}</h3>
        )}
        <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
      </div>
    </AsyncLoad>
  );
};
