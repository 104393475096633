import { DummyClassType } from "../types/DummyClassType";
import { IHaveObjectId } from "./../shared/types/have/IHaveId";

import { clone } from "../utils/clone";

/**
 * This abstract class represents a dummy object for an entity.
 * A dummy is an object which is not persisted yet.
 * The class also provides methods to create instances of the dummy and checks if the dummy is persisted or transient.
 */
export abstract class Dummy implements IHaveObjectId {
  OBJECT_ID: string = Math.random().toString();

  /**
   * Creates a clone of the given {@link origin} object. Cloning also includes to clone the OBJECT_ID of an entity. We create an exact clone of the origin
   * Properties of type array and object should also be cloned and not referred.
   * So we create a deep clone.
   * To not lose the information if the {@link origin} object is transient (so of type dummy),
   * the cloned object must be wrapped by an instance of dummy, if {@link origin} is transient.
   */
  static clone<T>(this: DummyClassType<T>, origin: T): T {
    let cloneObject = clone(origin);
    if (origin instanceof this) {
      cloneObject = this.create(cloneObject, true);
    }
    return cloneObject;
  }

  /**
   * Creates a an instance of a dummy object of type {@link T}.
   */
  static create<T>(
    this: DummyClassType<T>,
    template?: T,
    copyTemplateId?: boolean
  ): T {
    return new this(template, copyTemplateId);
  }

  /**
   * Returns if the given {@link object} is already persisted.
   */
  static isPersistent<T>(this: DummyClassType<T>, object: T): boolean {
    return !(object instanceof this);
  }

  /**
   * Returns if the given {@link object} is transient, so not persisted yet.
   */
  static isTransient<T>(this: DummyClassType<T>, object: T): boolean {
    return object instanceof this;
  }
}
