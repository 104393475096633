import { ICarouselBodyProps } from "./ICarouselBodyProps";
import { Icon } from "@snpjs/core";
import { style } from "../../../utils/style";
import styles from "./CarouselBody.module.scss";

export const CarouselBody: React.FC<ICarouselBodyProps> = (props) => {
  const navigationSize = 40;

  return (
    <div className={styles.body}>
      <button
        className={style(
          styles.button,
          props.isFirstItem
            ? styles.navigationInactive
            : styles.navigationActive
        )}
      >
        <Icon
          icon={"caret-left"}
          size={navigationSize}
          onClick={props.onPrevious}
        />
      </button>
      <div className={styles.item}>
        {Array.isArray(props.children)
          ? props.children[props.selectedIndex]
          : props.children}
      </div>
      <button
        className={style(
          styles.button,
          props.isLastItem ? styles.navigationInactive : styles.navigationActive
        )}
      >
        <Icon
          icon={"caret-right"}
          size={navigationSize}
          onClick={props.onNext}
        />
      </button>
    </div>
  );
};
