import { AppContext } from "../context/AppContext";
import { ISession } from "../shared/types/ISession";

import { useContext } from "react";

/**
 * This custom hook is responsible for providing the current session.
 * It is a simplification for getting the context and the session out of the context.
 * It returns undefined if the user is logged out.
 */
export const useSession = (): ISession | undefined => {
  const context = useContext(AppContext);
  return context.session.value;
};
