import { ABAPBoolean } from "../types/ABAPBoolean";
import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";
import { IHaveDescription } from "../../types/have/IHaveDescription";

export interface IProduct extends IEntity, IHaveDescription {
  PRODUCT_ID: string;
  CONTR_START_DATE: string;// start date wird nie verwendet?
  CONTR_END_DATE: string;
  IS_LICENSABLE: ABAPBoolean;
  HAS_FULL_LICENSE?: ABAPBoolean;
  MAINT_START_DATE: string;
  MAINT_END_DATE: string;
  EMERGENCY_START_DATE: string;
  EMERGENCY_END_DATE: string;

}

export const ProductMeta: IEntityMeta<IProduct> = { path: "/products" };
