import { ABAPBoolean } from "../types/ABAPBoolean";
import { IConflict } from "./IConflict";
import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";
import { IHaveDescription } from "../../types/have/IHaveDescription";
import { ISystemDownloadHistory } from "./ISystemDownloadHistory";
import { ISystemKeyFields } from "../types/ISystemKeyFields";
import { SystemRequirementName } from "../types/SystemRequirementName";
import { SystemRoleName } from "../types/SystemRoleName";

export interface ISystem extends IEntity, IHaveDescription, ISystemKeyFields {
  SYSTEM_TYPE: string;
  SYSTEM_ROLES: SystemRoleName[];
  SYSTEM_REQMTS: SystemRequirementName[];
  IS_DEACTIVATED: ABAPBoolean;
  DOWNLOAD_HISTORY?: ISystemDownloadHistory;
  CONFLICTS: IConflict[];
}

export const SystemMeta: IEntityMeta<ISystem> = { path: "/systems" };
