import {
  Button,
  ButtonGroup,
  Card,
  Icon,
  IconName,
  Section,
  SectionCard,
} from "@snpjs/core";
import { useEffect, useRef, useState } from "react";

import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { ISystemLandscapeHeaderProps } from "./ISystemLandscapeHeaderProps";
import { Link } from "react-router-dom";
import { SingleTags } from "../../../components/selection/singleTags/SingleTags";
import { formatDate } from "../../../utils/formatDate";
import styles from "./SystemLandscapeHeader.module.scss";
import { texts } from "../../../i18n/texts";
import { useAuth } from "../../../hooks/useAuth";
import { useConfig } from "../../../hooks/useConfig";
import { useProductInfo } from "../../../hooks/useProductInfo";
import { useSetAllLicensesCommand } from "../systemCommands/useSetAllLicensesCommand";
import useTranslation from "../../../hooks/useTranslation";
import { useUserProfile } from "../../../hooks/useUserProfile";
import { IMessage } from "../../../model/message/IMessage";
import { MessageSeverity } from "../../../model/message/MessageSeverity";
import { MessageArea } from "../../../components/messageArea/MessageArea";
import { Color } from "../../../api/types/Color";

export const SystemLandscapeHeader: React.FC<ISystemLandscapeHeaderProps> = (
  props
) => {
  const { t } = useTranslation();
  const config = useConfig();
  const productInfo = useProductInfo();
  const [disableToggle, setDisableToggle] = useState(false);
  const [allLicensesToggle, setAllLicensesToggle] = useState(
    props.product.HAS_FULL_LICENSE === ABAPBoolean.true ? true : false
  );
  const [message, setMessage] = useState<IMessage | undefined>(undefined);
  const reason: string = "";
  let reasonRef = useRef(reason);
  const userProfile = useUserProfile();
  const onToggleLicenses = async () => {
    if (disableToggle) {
      return;
    }
    setDisableToggle(true);
    setAllLicensesToggle(!allLicensesToggle);
    // updating the state ist asynchronous, meaning that props.onSetAllLicensesFlag() will not receive the changed value => initial negative value is sent
    const updatedValue = await props.onSetAllLicensesFlag(
      !allLicensesToggle,
      reasonRef.current
    );
    setAllLicensesToggle(updatedValue);
    setDisableToggle(false);
    return updatedValue === !allLicensesToggle;
  };

  const collapseLicenseOptions = !(
    userProfile.value === undefined ||
    userProfile.value.collapseLicenseOptions === undefined ||
    userProfile.value.collapseLicenseOptions.length === 0 ||
    (() => {
      const collapseLicenseEntry =
        userProfile.value.collapseLicenseOptions.find(
          ([id, flag]) => id === props.product.OBJECT_ID
        );
      if (collapseLicenseEntry === undefined) {
        return true;
      }
      return collapseLicenseEntry[1];
    })()
  );

  const toggleAllLicensesCommand = useSetAllLicensesCommand({
    onOkay: onToggleLicenses,
    reasonRef: reasonRef,
    productId: props.product.PRODUCT_ID,
    allLicensesToggle: allLicensesToggle,
  });


  useEffect(() => {
    if(!productInfo.isLicenseActive(props.product)){
      setMessage(
        () => ({
          severity: "error",
          text: t(texts.systemCard.errorProductExpired),
        }),
      );
    }
    else if(!productInfo.isAnyLicenseLongLasting(props.product, config.CONTRACT_EXPIRE_WARN_IN_DAYS)){
      let endDate = new Date();
      if (productInfo.getEmergencyAccessEndDate(props.product) === null){
        endDate = productInfo.getLongestEndDate(props.product);
      }
      else{
        endDate = new Date(Math.max(productInfo.getLongestEndDate(props.product).getTime(),
                           productInfo.getEmergencyAccessEndDate(props.product)!.getTime()));
      }
      let messageText = t(texts.systemCard.errorProductAboutToExpire) + endDate.toDateString() + ".";
      let severity: MessageSeverity = "info";
      let icon: IconName | undefined = "warning"; 
      if(!productInfo.isAnyLicenseLongLasting(props.product, config.EMERGENCY_EDIT_DAYS)){
        messageText = t(texts.systemCard.errorProductAboutToExpireShortly) + endDate.toDateString() + ".";
        severity = "warning";
        icon = undefined;
      }
      setMessage(
        () => ({
          severity: severity,
          icon: icon,
          text: messageText
        }),
      );
    }
  },[props.product])
  
  
  const messageAreaContent = message && (
    <div className={styles.messageArea}>
      <MessageArea className={styles.messageArea} message={message} />
    </div>
  );

  const licenseDates = (
    <div className={styles.header}>
      <div className={styles.headerElement}>
        <b>{t(texts.general.productBeginning)}</b>
        {formatDate(
          productInfo
            .getShortestStartDate(props.product)
            .toString()
        )}
      </div>
      <div className={styles.headerElement}>
        <b>{t(texts.general.productExpiration)}</b>
        <div style={{ color: productInfo.isExpired(props.product)? Color.DANGER : productInfo.isAboutToExpire(props.product)? Color.WARNING : "black"}}>
          {formatDate(
            productInfo
              .getLongestEndDate(props.product)
              .toString()
          )}
        </div>
      </div>
      {productInfo.getEmergencyAccessEndDate(props.product) &&
       productInfo.getEmergencyAccessEndDate(props.product)! >
       productInfo.getLongestEndDate(props.product) && (
        <div className={styles.headerElement}>
          <b>{t(texts.general.emergyncyLicenseExpiration)}</b>
          <div style={{ color: productInfo.isEmergencyExpired(props.product)? Color.DANGER : productInfo.isEmergencyAboutToExpire(props.product)? Color.WARNING : "black"}}>
            {formatDate(
              productInfo.getEmergencyAccessEndDate(props.product)!
              .toString()
            )}
          </div>
        </div>
      )}
    </div>
  )

  return (
    <><div>
      <Section
        rightElement={
          props.systemsExpandible ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.onExpandSystems();
              }}
              icon={"caret-down"}
              text={t(texts.systemLandscapeHeader.captionExpandSystems)}
              intent="none"
            />
          ) : (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.onCollapseSystems();
              }}                
              icon={"caret-up"}
              text={t(
                texts.systemLandscapeHeader.captionCollapseSystems
              )}
              intent="none"
            />
          )}
        collapsible={true}
        title={t(texts.systemLandscapeHeader.licenseOptions)}
        icon="file-seal"
      >
        <SectionCard>
           <div className = {styles.messageAreaContainer}>
               {licenseDates}
             {messageAreaContent}
           </div>
        </SectionCard>
        <SectionCard padded={false}>
          <Card compact>
              <div>
                {!collapseLicenseOptions && (
                  <>
                    {!collapseLicenseOptions && (
                      <div className={styles.licenseOptions}>
                        {props.licenseOptions.length === 0 &&
                        props.isLicenseOptionsRequired === true ? (
                          <>
                            {t(texts.systemLandscapeHeader.noLicenseOptions, {
                              supportPortal: (
                                <Link to={config.SUPPORT_PORTAL_LINK}>
                                  {config.SUPPORT_PORTAL_LINK}
                                </Link>
                              ),
                            })}
                          </>
                        ) : (
                          <SingleTags
                            objects={props.licenseOptions}
                            getName={(licenseOption) =>
                              licenseOption.DESCRIPTION
                            }
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.buttonGroup}>
                <ButtonGroup vertical>
                  {/* {props.systemsExpandible ? (
                    <Button
                      onClick={props.onExpandSystems}
                      icon={"caret-down"}
                      text={t(texts.systemLandscapeHeader.captionExpandSystems)}
                      intent="none"
                    />
                  ) : (
                    <Button
                      onClick={props.onCollapseSystems}
                      icon={"caret-up"}
                      text={t(
                        texts.systemLandscapeHeader.captionCollapseSystems
                      )}
                      intent="none"
                    />
                  )} */}
                  {useAuth().isSuperUser() && !collapseLicenseOptions && (
                    <div>
                      {toggleAllLicensesCommand.view}
                      <Button
                        icon={<Icon icon="lifebuoy" intent="danger" />}
                        text={t(texts.systemLandscape.captionSetAllLicense, {
                          action: allLicensesToggle
                            ? t(texts.systemLandscape.actionRevoke)
                            : t(texts.systemLandscape.actionGrant),
                        })}
                        intent={allLicensesToggle ? "warning" : "none"}
                        onClick={() => toggleAllLicensesCommand.execute()}
                      />
                    </div>
                  )}
                </ButtonGroup>
              </div>
            </Card>
          </SectionCard>
          {/* { Empty div creates a bottom-separator for the header } */}
          <div></div>
        </Section>
      </div>
    </>
  );
};
