import { IConfig } from "../shared/model/IConfig";
import { IProduct } from "../shared/model/IProduct";
import { IProductInfo } from "./IProductInfo";

export class ProductInfo implements IProductInfo {
  constructor(private readonly config: IConfig) {}

  isAboutToExpire(product: IProduct): boolean {
    if (!this.isExpired(product)) {
      const productEndDate = this.getLongestEndDate(product);
      const nowPlusWarnTime = new Date();
      nowPlusWarnTime.setDate(nowPlusWarnTime.getDate() + (this.config.EMERGENCY_EDIT_DAYS ?? 0));
      return nowPlusWarnTime.getTime() > productEndDate.getTime();
    }
    return false;
  }

  isEmergencyAboutToExpire(product: IProduct): boolean {
    if (!this.isEmergencyExpired(product)) {
      const emergencyEndDate = product.EMERGENCY_END_DATE?? "0000-00-00";
      if(emergencyEndDate !== "0000-00-00"){
        const nowPlusWarnTime = new Date ()
        nowPlusWarnTime.setDate(nowPlusWarnTime.getDate() + (this.config.EMERGENCY_EDIT_DAYS ?? 0));
        const endDate = new Date(emergencyEndDate)
        return nowPlusWarnTime.getTime() > endDate.getTime() ;
      }
    }
    return false;
  }

  isExpired(product: IProduct): boolean {
    const productEndDate = this.getLongestEndDate(product);
    const now = new Date();
    return productEndDate.getTime() < now.getTime();
  }

  isEmergencyExpired(product: IProduct): boolean {
    const emergencyEndDate = product.EMERGENCY_END_DATE?? "0000-00-00";
    if(emergencyEndDate !== "0000-00-00"){
      const now = new Date()
      const endDate = new Date(emergencyEndDate)
      return endDate.getTime() < now.getTime();
    }
    return false;    
  }

  isLicenseActive(product: IProduct): boolean {
    // not null and not expired, because never activated emergency license would return false from isEmergencyExpired()
    return (!this.isExpired(product) || (this.getEmergencyAccessEndDate(product) !== null && !this.isEmergencyExpired(product)));
  }

  isAnyLicenseLongLasting(product: IProduct, minimumDays: number): boolean {
    const EndDate = new Date()
    if (product.EMERGENCY_END_DATE === "0000-00-00" 
      || this.getLongestEndDate(product).getTime() > new Date(product.EMERGENCY_END_DATE).getTime()) {
      EndDate.setTime(this.getLongestEndDate(product).getTime())
    }
    else
    {
      EndDate.setTime(new Date(product.EMERGENCY_END_DATE).getTime())
    }
    EndDate.setDate(EndDate.getDate() - (minimumDays ?? 0));
    return EndDate.getTime() > new Date().getTime();
  }

  getShortestStartDate(product: IProduct): Date {
    if (product.CONTR_START_DATE < product.MAINT_START_DATE) {
      return new Date(product.CONTR_START_DATE);
    } else {
      return new Date(product.MAINT_START_DATE);
    }
  }

  getLongestEndDate(product: IProduct): Date {
    if (product.CONTR_END_DATE > product.MAINT_END_DATE) {
      return new Date(product.CONTR_END_DATE);
    } else {
      return new Date(product.MAINT_END_DATE);
    }
  }

  getEmergencyAccessEndDate(product: IProduct): Date | null{
    if(product.EMERGENCY_END_DATE !== "0000-00-00"){
      const endDate = new Date(product.EMERGENCY_END_DATE)
        return endDate;
    }
    return null
  }

  getNewEmergencyExpirationDate(product: IProduct): Date{
    const newEndDate = new Date()
    if (product.EMERGENCY_END_DATE === "0000-00-00" || this.getLongestEndDate(product).getTime() > new Date(product.EMERGENCY_END_DATE).getTime()) {
      if(this.isExpired(product)){
        newEndDate.setDate(new Date().getDate() + (this.config.EMERGENCY_EXT_DAYS ?? 0));
      }else{
        newEndDate.setTime(this.getLongestEndDate(product).getTime());
        newEndDate.setDate(newEndDate.getDate() + (this.config.EMERGENCY_EXT_DAYS ?? 0));
      }
    }
    else
    {
      if(this.isEmergencyExpired(product)){
        newEndDate.setDate(new Date().getDate() + (this.config.EMERGENCY_EXT_DAYS ?? 0));
      }
      else
      {
        newEndDate.setTime(new Date(product.EMERGENCY_END_DATE).getTime());
        newEndDate.setDate(newEndDate.getDate() + (this.config.EMERGENCY_EXT_DAYS ?? 0));
      }
    }
    return newEndDate;
  }
}
