import { RESTError } from "../core/RESTError";

/**
 * Returns if the given {@link value} is an {@link RESTError}.
 */
export const isRESTError = (value: any): value is RESTError => {
  if (typeof value !== "object" || value === undefined) {
    return false;
  }

  return "originError" in value;
};
