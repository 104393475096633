import { useState } from "react";

export const useInputBinding = <T>(
  initialValue: T,
  type: "string" | "number" = "string"
) => {
  const [value, setValue] = useState(initialValue);
  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      const value = event.target.value.replace(/[^0-9]/gi, "");
      setValue(value as T);
    } else {
      setValue(event.target.value as T);
    }
  };
  return { value, setValue, onChangeValue };
};
