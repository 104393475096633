import { IPathConverter } from "./IPathConverter";
import { NotSupportedError } from "../../errors/NotSupportedError";
import { Params } from "react-router-dom";
import { PathSegment } from "../../components/breadcrumbs/PathSegment";
import { RouteConfig } from "../../routes/core/types/RouteConfig";

/**
 * This class is responsible for analyzing the path and converting them into {@link PathSegment}s
 * If a path element is followed by an id, it is added to the {@link PathSegment}
 *  e.g. path "/contracts/123" results in one PathSegment = { root: "/contracts", param: "123" }
 */
export class PathConverter<TRouteType extends RouteConfig>
  implements IPathConverter
{
  private paramRouteMapping = new Map<string, string>();

  constructor(
    private path: string,
    private params: Readonly<Params<string>>,
    private routes: TRouteType
  ) {
    this.buildRouteTypeMapping();
  }

  private toSegments(path: string) {
    const segments: string[] = [];
    for (const segment of path.split("/")) {
      // skip empty segments
      if (segment.length === 0) {
        continue;
      }

      if (segment.startsWith(":")) {
        // append segment to predecessor
        let predecessor = segments[segments.length - 1];
        if (!predecessor) {
          throw new NotSupportedError(
            `Error while preparing path for breadcrumbs. Path with starting parameter (:param) currently not supported.`
          );
        }

        predecessor += `/${segment}`;
        segments[segments.length - 1] = predecessor;
      } else {
        // append segment separately
        segments.push(segment);
      }
    }
    return segments;
  }

  private buildRouteTypeMapping() {
    for (const propName in this.routes) {
      const route = this.routes[propName];
      // only collect routes that have parameters like /contract/:contractId
      if (!(route.origin as string).includes(":")) {
        continue;
      }
      const segments = this.toSegments(route.origin);
      for (const segment of segments) {
        const routeSegments: string[] = segment.split("/:");
        this.paramRouteMapping.set(
          routeSegments[0].replace("/", ""),
          routeSegments[1]
        );
      }
    }
  }

  convertToPathSegments(): PathSegment[] {
    const pathElements = this.splitPathBySlashes();

    const pathSegments: PathSegment[] = [];
    let skipNextPathElement = false;

    for (let i = 0; i < pathElements.length; i++) {
      if (skipNextPathElement) {
        skipNextPathElement = false;
        continue;
      }
      const pathSegment = this.buildPathSegment(
        pathElements[i],
        pathElements[i + 1]
      );
      if (pathSegment.param) {
        //skip the next path element because it is an id
        //and was already handled in the previous cycle
        skipNextPathElement = true;
      }
      pathSegments.push(pathSegment);
    }
    return pathSegments;
  }

  private buildPathSegment(
    currentPathElement: string,
    nextPathElement: string
  ): PathSegment {
    const paramValue = this.findParamValueInParams(
      currentPathElement,
      nextPathElement
    );
    return new PathSegment(`/${currentPathElement}`, paramValue);
  }

  private findParamValueInParams(
    rootPathElement: string,
    nextPathElement: string
  ) {
    const paramName = this.paramRouteMapping.get(rootPathElement);
    if (paramName) {
      //check if next element in path equals param value read from url params
      const paramValue = this.params[paramName];
      if (paramValue) {
        if (paramValue === nextPathElement) {
          return paramValue;
        }
      }
    }
  }

  private splitPathBySlashes(): string[] {
    const pathElements = this.path.split("/");
    //filter empty last path element after split
    if (pathElements[pathElements.length - 1] === "") {
      pathElements.splice(pathElements.length - 1, 1);
    }
    return pathElements;
  }
}
