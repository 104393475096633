import { ContractResponsiblesCollapse } from "../contractResponsiblesCollapse/ContractResponsiblesCollapse";
import { IContractResponsiblesProps } from "./IContractResponsiblesProps";
import styles from "./ContractResponsibles.module.scss";

export const ContractResponsibles: React.FC<IContractResponsiblesProps> = (
  props
) => {
  const items = props.responsibles.map((responsible, index) => (
    <div key={index}>{responsible}</div>
  ));

  const fallbackItems = props.responsibles
    .filter((_, index) => index < 2)
    .map((responsible, index) => <div key={index}>{responsible}</div>);

  return (
    <ContractResponsiblesCollapse
      hideIcon={props.responsibles.length < 3}
      fallback={
        <div className={styles.contractResponsibles}>{fallbackItems}</div>
      }
    >
      <div className={styles.contractResponsibles}>{items}</div>
    </ContractResponsiblesCollapse>
  );
};
