import { ABAPBoolean } from "../shared/types/ABAPBoolean";

import { texts } from "../i18n/texts";

import useTranslation from "./useTranslation";

/**
 * This custom hook is responsible for rendering an ABAP boolean value to either yes or no.
 */
export const useRenderABAPBoolean = () => {
  const { t } = useTranslation();

  const render = (abapBoolean?: ABAPBoolean) => {
    return abapBoolean === ABAPBoolean.true
      ? t(texts.general.yes)
      : t(texts.general.no);
  };

  return render;
};
