import { ISystemRequirement } from "../shared/model/ISystemRequirement";
import { ISystemRequirementType } from "../shared/model/ISystemRequirementType";
import { ISystemRequirementTypeInfo } from "./ISystemRequirementTypeInfo";

class SystemRequirementTypeInfoDefault implements ISystemRequirementTypeInfo {
  isSelectionValid(
    systemRequirementTypes: ISystemRequirementType[],
    selectedSystemRequirements: string[]
  ): boolean {
    for (const systemRequirementType of systemRequirementTypes) {
      if (systemRequirementType.SYSTEM_REQUIREMENTS.length === 0) {
        continue;
      }

      if (
        !this.isAssigned(
          selectedSystemRequirements,
          systemRequirementType.SYSTEM_REQUIREMENTS
        )
      ) {
        return false;
      }
    }
    return true;
  }

  toDescription(
    systemRequirementTypes: ISystemRequirementType[],
    systemRequirementIds: string[]
  ): string[] {
    const result: string[] = [];
    for (const systemRequirementId of systemRequirementIds) {
      const systemRequirement = this.findSystemRequirement(
        systemRequirementTypes,
        systemRequirementId
      );
      result.push(systemRequirement?.DESCRIPTION ?? systemRequirementId);
    }
    return result;
  }

  /**
   * Returns if at least one entry of {@link selectedSystemRequirements} is part of {@link systemRequirements}.
   */
  private isAssigned(
    selectedSystemRequirements: string[],
    systemRequirements: ISystemRequirement[]
  ): boolean {
    for (const systemRequirement of systemRequirements) {
      if (
        selectedSystemRequirements.includes(
          systemRequirement.SYSTEM_REQUIREMENT_ID
        )
      ) {
        return true;
      }
    }

    return false;
  }

  private findSystemRequirement(
    systemRequirementTypes: ISystemRequirementType[],
    systemRequirementId: string
  ): ISystemRequirement | undefined {
    for (const systemRequirementType of systemRequirementTypes) {
      for (const systemRequirement of systemRequirementType.SYSTEM_REQUIREMENTS) {
        if (systemRequirement.SYSTEM_REQUIREMENT_ID === systemRequirementId) {
          return systemRequirement;
        }
      }
    }

    return undefined;
  }
}

export const SystemRequirementTypeInfo = new SystemRequirementTypeInfoDefault();
