import { ContentType } from "../types/ContentType";
import { HeaderInfoFactory } from "../utils/headerInfo/HeaderInfoFactory";
import { IFile } from "../../shared/model/IFile";
import { IRESTErrorSAP } from "../types/IRESTErrorSAP";
import { RESTError } from "./RESTError";
import { SessionRepository } from "../session/SessionRepository";

import { createRESTError } from "../utils/createRESTError";

export class RESTApi {
  protected delete<T>(url: string): Promise<T> {
    return this.createPromise(async (resolve, reject) => {
      const response = await fetch(this.toSessionURL(url), {
        mode: "cors",
        method: "DELETE",
      });
      await this.handleResponse(resolve, reject, response);
    });
  }

  protected get<T>(url: string): Promise<T> {
    return this.createPromise(async (resolve, reject) => {
      const response = await fetch(this.toSessionURL(url), {
        mode: "cors",
        method: "GET",
        redirect: "follow",
      });
      await this.handleResponse(resolve, reject, response);
    });
  }

  protected post<T>(url: string, entity: any | undefined): Promise<T> {
    return this.createPromise(async (resolve, reject) => {
      const body = entity ? JSON.stringify(entity) : undefined;
      const response = await fetch(this.toSessionURL(url), {
        body: body,
        method: "POST",
        mode: "cors",
      });
      await this.handleResponse(resolve, reject, response);
    });
  }

  protected put<T>(url: string, entity: any): Promise<T> {
    return this.createPromise(async (resolve, reject) => {
      const response = await fetch(this.toSessionURL(url), {
        mode: "cors",
        method: "PUT",
        body: JSON.stringify(entity),
      });
      await this.handleResponse(resolve, reject, response);
    });
  }

  /**
   * Creates a promise to run asynchronous functions and handles errors.
   */
  protected createPromise<T>(
    block: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void
    ) => void
  ): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        await block(resolve, reject);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Converts a {@ink response} to a REST error
   */
  private async toRESTError(response: Response): Promise<RESTError> {
    try {
      const data = await response.json();
      if (Array.isArray(data)) {
        const originError: IRESTErrorSAP = data[0];
        if (originError !== undefined) {
          return createRESTError(originError, response);
        }
        console.log(response.statusText);
        throw new Error(
          "Error while resolving error response. Unknown error format."
        );
      } else {
        throw new Error(
          "Error while resolving error response. Response must be of type array."
        );
      }
    } catch (error) {
      var message = "Communication with the backend is currently not possible.";
      if (error instanceof Error) {
        message = message.concat("\n").concat(error.message);
      }
      throw new Error(message);
    }
  }

  /**
   * Creates a new URL which contains the session specific information, like the token, if it exists.
   * Returns the previous {@link url}, if no session exists.
   */
  protected toSessionURL(url: string): string {
    if (SessionRepository.session !== undefined) {
      let convertedURL = `${url}?token=${SessionRepository.session.TOKEN}`;
      if (SessionRepository.contractId !== undefined) {
        convertedURL += `&coi=${SessionRepository.contractId}`;
      }
      return convertedURL;
    }
    return url;
  }

  /**
   * Default behavior for handling fetch response
   */
  private async handleResponse<T>(
    resolve: (value: T | PromiseLike<T>) => void,
    reject: (reason?: any) => void,
    response: Response
  ) {
    if (response.ok) {
      const contentType =
        HeaderInfoFactory.createFromResponse(response).getContentType();
      if (contentType === ContentType.OCTET_STREAM) {
        //file download
        const blob = await response.blob();
        const headerInfo = HeaderInfoFactory.createFromResponse(response);
        const data: IFile = {
          blob: blob,
          filename: headerInfo.getFilename(),
        };
        resolve(data as T);
      } else if (
        contentType === ContentType.PNG ||
        contentType === ContentType.SVG
      ) {
        const blob = await response.blob();
        const data: IFile = { blob: blob };
        resolve(data as T);
      } else if (contentType === ContentType.TEXT) {
        const data = await response.text();
        resolve(data as T);
      } else {
        const data = await response.json();
        resolve(data);
      }
    } else {
      const error = await this.toRESTError(response);
      reject(error);
    }
  }
}
