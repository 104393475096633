import { ILabeledSelectProps } from "./ILabeledSelectProps";
import { LabeledElement } from "../labeledElement/LabeledElement";
import { Select } from "../select/Select";
import styles from "./LabeledSelect.module.scss";
import { useId } from "react";

export function LabeledSelect<T>(props: ILabeledSelectProps<T>) {
  const selectId = useId();
  const { errorMessage, label, placeholder, ...selectProps } = props;
  return (
    <LabeledElement {...props}>
      <div className={styles.selectContainer}>
        {props.disabled ? (
          <div className={styles.select} id={selectId}>
            {props.selected && props.renderOptionTitle(props.selected)}
          </div>
        ) : (
          <Select
            {...selectProps}
            id={selectId}
            className={styles.select}
            disabled={props.disabled}
          />
        )}
      </div>
    </LabeledElement>
  );
}
