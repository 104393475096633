import { AppConfig } from "./AppConfig";
import { AppContextProvider } from "./components/appContextProvider/AppContextProvider";
import { AppRouter } from "./routes/AppRouter";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { fetchMock } from "./api/mock/fetchMock";

/**
 * Backup fetch function and replace it by fetchMock if required.
 */
export const fetchBackup = fetch;
if (AppConfig.isDevelopment && AppConfig.MOCK_REST) {
  global.fetch = fetchMock;
}

/**
 * This component is the app component, the starting point.
 *
 * Components can be displayed by opening the corresponding route.
 * To display a component a related route has to be added to {@link AppRouter}.
 */
const App: React.FC = () => {
  return (
    <AppContextProvider>
      <RouterProvider router={AppRouter} />
    </AppContextProvider>
  );
};

export default App;
