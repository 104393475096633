import { LanguageType } from "../types/LanguageType";

import { texts } from "../i18n/texts";

import useTranslation from "./useTranslation";

/**
 * Mapper to map from the ABAP SPRAS language to a readable format
 */

export const useLanguageTypeToTextMapper = (): ((
  languageType: LanguageType
) => string) => {
  const { t } = useTranslation();

  const map = (languageType: LanguageType): string => {
    switch (languageType) {
      case LanguageType.DE:
        return t(texts.languages.DE);
      case LanguageType.EN:
        return t(texts.languages.EN);
      case LanguageType.JA:
        return t(texts.languages.JA);
      default:
        throw new Error(`LanguageType ${languageType} not implemented.`);
    }
  };
  return map;
};
