import { Button, Tooltip } from "@snpjs/core";

import { ReactComponent as CircleWithOne } from "../../assets/circleWithOne.svg";
import { ReactComponent as CircleWithTwo } from "../../assets/circleWithTwo.svg";
import { ISupportPortalTicketInfo } from "./ISupportPortalTicketInfo";
import { Link } from "react-router-dom";
import { style } from "../../utils/style";
import styles from "./SupportPortalTicketInfo.module.css";
import { texts } from "../../i18n/texts";
import { useConfig } from "../../hooks/useConfig";
import useTranslation from "../../hooks/useTranslation";

export const SupportPortalTicketInfo: React.FC<ISupportPortalTicketInfo> = (
  props
) => {
  const { t } = useTranslation();
  const config = useConfig();

  const ticketLinkToErroneousPage = t(
    texts.supportPortalTicketInfo.ticketLinkToErroneousPage,
    {
      link: props.linkToErroneousPage,
    }
  );
  const ticketIntoText = `${t(
    texts.supportPortalTicketInfo.ticketIntroductionText
  )} \n\n${ticketLinkToErroneousPage}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(ticketIntoText);
  };

  return (
    <div className={style(styles.supportPortalTicketInfo, props.className)}>
      <h1 className={styles.supportPortalTicketInfoTitle}>
        {t(texts.supportPortalTicketInfo.howToTitle)}
      </h1>
      <div className={styles.circleNumberAndText}>
        <CircleWithOne className={styles.circleWithNumber} />
        {t({
          text: texts.supportPortalTicketInfo.linkAndInstructions,
          placeholders: {
            support_portal: (
              <Link to={config.SUPPORT_PORTAL_LINK} target="_blank">
                {t(texts.supportPortalTicketInfo.supportPortal)}
              </Link>
            ),
          },
        })}
      </div>
      <div className={styles.circleNumberAndText}>
        <CircleWithTwo className={styles.circleWithNumber} />
        <div>
          {t(texts.supportPortalTicketInfo.environmentDescription)}
          <div className={styles.ticketInfosBox}>
            <textarea
              disabled
              className={styles.ticketInfos}
              value={ticketIntoText}
            ></textarea>
            <Tooltip
              content={t(texts.supportPortalTicketInfo.copyButtonTooltip)}
              className={styles.copyButton}
            >
              <Button icon="copy" onClick={copyToClipboard} />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
