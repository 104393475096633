/**
 * This type provides the available authority roles for this app.
 * An authority role is responsible to restrict the access to specific pages or action for only authorized user.
 */
export enum AuthRole {
  ACCOUNT_MANAGER = "ACCOUNT_MANAGER",
  ADMIN = "ADMIN",
  CONTRACT_OWNER = "CONTRACT_OWNER",
  DELIVERY = "DELIVERY",
  PRINCIPAL = "PRINCIPAL",
  PROJECT_LEAD = "PROJECT_LEAD",
  SUPPORT = "SUPPORT",
  SYSTEM_OWNER = "SYSTEM_OWNER",
  TESTER = "TESTER",
}
