import { IMultiSelectionButtonProps } from "./IMultiSelectionButtonProps";
import { SelectionButton } from "../selectionButton/SelectionButton";
import { useEffect } from "react";
import { useToggle } from "../../../hooks/useToggle";

/**
 * This component is responsible for displaying a specific option, which can be selected or unselected.
 */
export const MultiSelectionButton: React.FC<IMultiSelectionButtonProps> = (
  props
) => {
  const [active, toggleActive] = useToggle(props.isSelected);

  useEffect(() => {
    // needs active to be restored?
    // Restoring is required if editMode was changed back to display mode (editMode = false)
    // if MultiSelectionButton was already clicked in edit mode, the active state must be restored to the previous version
    if (props.editMode === false && active !== props.isSelected) {
      toggleActive(props.isSelected);
    }
  }, [active, props.editMode, props.isSelected, toggleActive]);

  const onToggleActive = () => {
    toggleActive();
    if (active) {
      props.onDeactivate();
    } else {
      props.onActivate();
    }
  };

  return (
    <SelectionButton
      isImmutable={props.isImmutable? props.isImmutable : false}
      active={active}
      disabled={props.isDisabled || props.isImmutable}
      editMode={props.editMode}
      objectName={props.objectName}
      objectTooltip={props.objectTooltip}
      onClick={onToggleActive}
    />
  );
};
