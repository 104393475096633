import { useContext, useState } from "react";

import { AppContext } from "../context/AppContext";
import { AsyncLoad } from "../components/asyncLoad/AsyncLoad";
import { AuthRole } from "../auth/types/AuthRole";
import { ConflictManagement } from "../features/conflictManagement/ConflictManagement";
import { ContractBasedPage } from "../features/contract/contractBasedPage/ContractBasedPage";
import { IContract } from "../shared/model/IContract";
import { ISystem } from "../shared/model/ISystem";
import { ISystemVersion } from "../shared/types/ISystemVersion";
import { MissingURLParameterError } from "../errors/MissingURLParameterError";
import { SystemApi } from "../api/SystemApi";
import { UserApi } from "../api/UserApi";
import { checkNotNull } from "../utils/checkNotNull";
import { texts } from "../i18n/texts";
import { useParams } from "react-router-dom";
import { useRequest } from "../hooks/useRequest";
import useTranslation from "../hooks/useTranslation";

export const ConflictManagementPage: React.FC = (props) => {
  const request = useRequest();
  const [system, setSystem] = useState<ISystem | undefined>(undefined);
  const [conflictingSystem, setConflictingSystem] = useState<
    ISystem | undefined
  >(undefined);
  const [systemVersion, setSystemVersion] = useState<
    ISystemVersion | undefined
  >(undefined);
  const params = useParams<{ systemId: string; productId: string }>();
  const systemId = checkNotNull(
    params.systemId,
    new MissingURLParameterError("system id")
  );
  const productId = checkNotNull(
    params.productId,
    new MissingURLParameterError("product id")
  );
  const [conflictingContract, setConflictingContract] = useState<
    IContract | undefined
  >(undefined);
  const [authRolesOfConflictingContract, setAuthRolesOfConflictingContract] =
    useState<AuthRole[]>([]);

  const { t } = useTranslation();
  const context = useContext(AppContext);
  const infoArea = <>{t(texts.conflictManagement.infoArea.infoMessage)}</>;

  return (
    <ContractBasedPage iconName="warning" showBreadcrumbs infoArea={infoArea}>
      <AsyncLoad
        load={async () => {
          await request.send(async () => {
            const system = await SystemApi.findById(systemId);
            setSystem(system);
            const conflictingSystem = await SystemApi.findById(
              checkNotNull(system).CONFLICTS[0].SYSTEM_OBJECT_ID
            );
            setConflictingSystem(conflictingSystem);
            if (conflictingSystem) {
              const contract = await SystemApi.getContractById(
                conflictingSystem.OBJECT_ID
              );
              setConflictingContract(contract);
              if (context.session.value) {
                const userAuthRoles = await UserApi.getAuthRolesByContractId(
                  context.session.value,
                  contract.OBJECT_ID
                );
                setAuthRolesOfConflictingContract(userAuthRoles);
              }
            }
            const systemVersion = await SystemApi.getDownloadVersion(systemId);
            setSystemVersion(systemVersion);
          });
        }}
      >
        {system &&
          systemVersion &&
          conflictingSystem &&
          conflictingContract && (
            <ConflictManagement
              conflictingSystem={conflictingSystem}
              conflictingContract={conflictingContract}
              authRolesOfConflictingContract={authRolesOfConflictingContract}
              productId={productId}
              system={checkNotNull(system)}
              systemVersion={checkNotNull(systemVersion)}
              linkToConflictManagement={window.location.href}
            />
          )}
      </AsyncLoad>
    </ContractBasedPage>
  );
};
