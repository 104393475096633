import { Icon, Popover } from "@snpjs/core";

import { IProductExpirationOverviewPopoverProps } from "./IProductExpirationOverviewPopoverProps";
import { ProductExpirationOverview } from "../productExpirationOverview/ProductExpirationOverview";
import styles from "./ProductExpirationOverviewPopover.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";

export const ProductExpirationOverviewPopover: React.FC<
  IProductExpirationOverviewPopoverProps
> = (props) => {
  const { t } = useTranslation();

  return (
    <Popover
      className={styles.productExpirationOverviewPopover}
      content={
        <div className={styles.productExpirationOverview}>
          <h3>{t(texts.general.productExpiration)}</h3>
          <ProductExpirationOverview products={props.products} />
        </div>
      }
      placement="bottom-start"
    >
      <Icon icon="info" />
    </Popover>
  );
};
