import { BreadcrumbIconLoaderType } from "../breadcrumbLoaders/breadcrumbIconLoaders/BreadcrumbIconLoaderType";
import { BreadcrumbNameLoaderType } from "../breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoaderType";
import { IBreadcrumb } from "../../../components/breadcrumbs/breadcrumbs/IBreadcrumb";
import { IBreadcrumbFactory } from "./IBreadcrumbFactory";
import { IBreadcrumbLoaderRepo } from "../breadcrumbLoaders/IBreadcrumbLoaderRepo";
import { IconName } from "@snpjs/core";
import { PathSegment } from "../../../components/breadcrumbs/PathSegment";

import useTranslation from "../../../hooks/useTranslation";

/**
 * This hook is responsible for creating {@link Breadcrumb}s from {@link PathSegment}s
 */
export const useBreadcrumbFactory = (
  breadcrumbNameLoaderRepo?: IBreadcrumbLoaderRepo<BreadcrumbNameLoaderType>,
  breadcrumbIconLoaderRepo?: IBreadcrumbLoaderRepo<BreadcrumbIconLoaderType>,
  filteredPathSegments?: PathSegment[]
): IBreadcrumbFactory => {
  const { t } = useTranslation();

  const create = (pathSegments: PathSegment[]): IBreadcrumb[] => {
    const breadcrumbs: IBreadcrumb[] = [];
    let route = "";
    pathSegments.forEach((pathSegment) => {
      //add path to previous path so the breadcrumb has an absolute path all the way back to the root element
      route = addPathSegmentToRoute(route, pathSegment);

      if (!needsFiltering(pathSegment)) {
        const breadcrumb = createBreadcrumb(route, pathSegment);
        breadcrumbs.push(breadcrumb);
      }
    });
    return breadcrumbs;
  };

  const addPathSegmentToRoute = (
    route: string,
    pathSegment: PathSegment
  ): string => {
    //special case: clear the route for home, so the path does not consist of "//path"
    if (route === "/") {
      route = "";
    }
    return `${route}${buildRouteFromPathSegment(pathSegment)}`;
  };

  const buildRouteFromPathSegment = (pathSegment: PathSegment): string => {
    return `${pathSegment.rootPath}${
      pathSegment.param ? `/${pathSegment.param}` : ""
    }`;
  };

  const createBreadcrumb = (
    route: string,
    pathSegment: PathSegment
  ): IBreadcrumb => {
    const breadcrumb = {
      route,
      name: getName(pathSegment),
      iconName: getIconName(pathSegment),
    };
    //add a default value to prevent the breadcrumb from being empty
    if (breadcrumb.name === undefined && breadcrumb.iconName === undefined) {
      breadcrumb.name = pathSegment.rootPath.replace("/", "");
    }
    return breadcrumb;
  };

  const getIconName = (pathSegment: PathSegment): IconName | undefined => {
    const breadcrumbIconLoaderClass =
      breadcrumbIconLoaderRepo?.find(pathSegment);

    if (
      breadcrumbIconLoaderRepo === undefined ||
      breadcrumbIconLoaderClass === undefined
    ) {
      return;
    }
    return new breadcrumbIconLoaderClass().iconName;
  };

  const getName = (
    pathSegment: PathSegment
  ): string | Promise<string> | undefined => {
    const breadcrumbNameLoaderClass =
      breadcrumbNameLoaderRepo?.find(pathSegment);

    if (
      breadcrumbNameLoaderRepo === undefined ||
      breadcrumbNameLoaderClass === undefined
    ) {
      return;
    }
    const breadcrumbNameLoader = new breadcrumbNameLoaderClass(
      pathSegment.param
    );
    if (typeof breadcrumbNameLoader.name === "string") {
      return t(breadcrumbNameLoader.name);
    } else {
      return breadcrumbNameLoader.name;
    }
  };

  /**
   * When a path segment is part of the filtered segments,
   * check if the path param is equal, meaning
   * :id is equal to an actual id
   */
  const needsFiltering = (pathSegment: PathSegment): boolean => {
    const filteredPathSegment = filteredPathSegments?.find(
      (filteredPathSegment) =>
        filteredPathSegment.rootPath === pathSegment.rootPath
    );
    if (!filteredPathSegment) {
      return false;
    }

    if (
      filteredPathSegment?.param === undefined &&
      pathSegment.param === undefined
    ) {
      return true;
    }

    if (filteredPathSegment?.param && pathSegment.param) {
      return true;
    }
    return false;
  };

  return { create: (pathSegments: PathSegment[]) => create(pathSegments) };
};
