import { Flag } from "../flag/Flag";
import { ILanguageMenuItemProps } from "./ILanguageMenuItemProps";
import styles from "./LanguageMenuItem.module.scss";
import { useLanguageTypeToTextMapper } from "../../hooks/useLanguageTypeToTextMapper";

export const LanguageMenuItem: React.FC<ILanguageMenuItemProps> = (props) => {
  const mapLanguage = useLanguageTypeToTextMapper();

  return (
    <div className={styles.languageMenuItem}>
      <Flag languageType={props.languageType} />
      <div>{mapLanguage(props.languageType)}</div>
    </div>
  );
};
