import { IHeaderInfo } from "./IHeaderInfo";
import { IHeaderInfoFactory } from "./IHeaderInfoFactory";
import { ResponseHeaderInfo } from "./ResponseHeaderInfo";

class HeaderInfoFactoryDefault implements IHeaderInfoFactory {
  createFromResponse(response: Response): IHeaderInfo {
    return new ResponseHeaderInfo(response);
  }
}
export const HeaderInfoFactory = new HeaderInfoFactoryDefault();
