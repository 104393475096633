import { useCallback } from "react";
import { useState } from "react";

/**
 * This custom hook is responsible to toggle a value from true to false and reverse.
 */
export const useToggle = (
  initialValue: boolean
): [value: boolean, toggle: (newValue?: boolean) => void] => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(
    (newValue?: boolean) =>
      newValue ? setValue(newValue) : setValue((previous) => !previous),
    []
  );
  return [value, toggle];
};
