// import { useLocation, useNavigate } from "react-router-dom";

// import { AuthRole } from "../../auth/types/AuthRole";
// import { Button } from "@snpjs/core";

import { ConflictSection } from "./conflictSection/ConflictSection";
import { IConflictManagementProps } from "./IConflictManagementProps";
import { ISolution } from "../../components/questionWizard/ISolution";
import { OpenSupportTicketSolution } from "./conflictWizard/conflictSolutions/OpenSupportTicketSolution";
import { SolutionEffect } from "./solutionEffect/SolutionEffect";
import { SupportPortalTicketInfo } from "../supportPortalTicketInfo/SupportPortalTicketInfo";
import { SystemHeaderFields } from "../system/systemHeaderFields/SystemHeaderFields";
import { checkNotNull } from "../../utils/checkNotNull";
import styles from "./ConflictManagement.module.scss";
import { texts } from "../../i18n/texts";
import useTranslation from "../../hooks/useTranslation";

// import { DeactivateSystemInLandscapeSolution } from "./conflictWizard/conflictSolutions/DeactivateSystemInLandscapeSolution";
// import { DeactivateSystemInOtherLandscapeSolution } from "./conflictWizard/conflictSolutions/DeactivateSystemInOtherLandscapeSolution";

// import { ProblemConflictSectionContent } from "./problemConflictSectionContent/ProblemConflictSectionContent";
// import { QuestionWizard } from "./conflictWizard/questionWizard/QuestionWizard";
// import { Routes } from "../../routes/Routes";

// import { SufficientVersionWizardStep } from "./conflictWizard/conflictWizardSteps/sufficientVersion/SufficientVersionWizardStep";

// import { UpgradeToDifferentVersionSolution } from "./conflictWizard/conflictSolutions/UpgradeToDifferentVersionSolution";
// import { UpgradeToNewestVersionSolution } from "./conflictWizard/conflictSolutions/UpgradeToNewestVersionSolution";
// import { UseOldVersionSolution } from "./conflictWizard/conflictSolutions/UseOldVersionSolution";
// import { ValidationError } from "../../errors/ValidationError";

// import { useAuth } from "../../hooks/useAuth";
// import { useDeactivateSystemCommand } from "../system/systemCommands/useDeactivateSystemCommand";
// import { useSetSystemDownloadVersionCommand } from "../system/systemCommands/useSetSystemDownloadVersionCommand";
// import { useState } from "react";

export const ConflictManagement: React.FC<IConflictManagementProps> = (
  props
) => {
  const { t } = useTranslation();
  // const location = useLocation();
  // const navigate = useNavigate();
  // const [solution, setSolution] = useState<ISolution | undefined>(new OpenSupportTicketSolution());
  const solution: ISolution = new OpenSupportTicketSolution();
  // const [solutionNotPickedError, setSolutionNotPickedError] = useState(false);
  // const auth = useAuth();

  // const onCompleted = () => navigateBackToProduct();

  // const isOwnerOfConflictingContract: boolean =
  //   props.authRolesOfConflictingContract.find(
  //     (authRole) => authRole === AuthRole.CONTRACT_OWNER
  //   ) !== undefined;

  // const hasOnlyOneConflict = props.system.CONFLICTS.length === 1;

  //A conflict can only be solved if the user is a support employee or
  //if there is not more than one conflicting system and
  //if the user is the owner of the other contract, which includes the conflicting system
  // const canFixConflict =
  //   auth.isSupport() || (hasOnlyOneConflict && isOwnerOfConflictingContract);

  // const deactivateSystemCommand = useDeactivateSystemCommand({
  //   system: props.system,
  //   onOkay: onCompleted,
  // });

  // const deactivateSystemInOtherLandscapeCommand = useDeactivateSystemCommand({
  //   system: props.conflictingSystem,
  //   onOkay: onCompleted,
  // });

  // const setSystemDownloadVersionCommand = useSetSystemDownloadVersionCommand({
  //   hotfix: props.system.CONFLICTS[0].HOTFIX,
  //   release: props.system.CONFLICTS[0].RELEASE,
  //   servicePack: props.system.CONFLICTS[0].SERVICE_PACK,
  //   productId: props.productId,
  //   system: props.system,
  //   onOkay: onCompleted,
  // });

  // const upgradeToDifferentVersionCommand = useSetSystemDownloadVersionCommand({
  //   isVersionChangeable: true,
  //   productId: props.productId,
  //   system: props.system,
  //   onOkay: onCompleted,
  // });

  // const validate = () => {
  //   if (solution === undefined) {
  //     throw new ValidationError(
  //       t(texts.conflictManagement.errorMissingSolution)
  //     );
  //   }
  // };

  // const applySolution = () => {
  //   switch (checkNotNull(solution).constructor) {
  //     case DeactivateSystemInLandscapeSolution: {
  //       deactivateSystemCommand.execute();
  //       break;
  //     }
  //     case DeactivateSystemInOtherLandscapeSolution: {
  //       deactivateSystemInOtherLandscapeCommand.execute();
  //       break;
  //     }
  //     case UseOldVersionSolution: {
  //       setSystemDownloadVersionCommand.execute();
  //       break;
  //     }
  //     case UpgradeToNewestVersionSolution: {
  //       setSystemDownloadVersionCommand.execute();
  //       break;
  //     }
  //     case OpenSupportTicketSolution: {
  //       navigateBackToProduct();
  //       break;
  //     }
  //     case UpgradeToDifferentVersionSolution: {
  //       upgradeToDifferentVersionCommand.execute();
  //       break;
  //     }
  //   }
  // };

  // const onConfirm = () => {
  //   try {
  //     validate();
  //     applySolution();
  //   } catch (error) {
  //     if (error instanceof ValidationError) {
  //       setSolutionNotPickedError(true);
  //     } else {
  //       throw error;
  //     }
  //   }
  // };

  // const onCancel = () => {
  //   navigateBackToProduct();
  // };

  // const navigateBackToProduct = () => {
  //   const pathElements = location.pathname.split(
  //     Routes.excerptSystems.toPath()
  //   );
  //   navigate(pathElements[pathElements.length - 2]);
  // };

  const proceedMessage = () => {
    if (checkNotNull(solution).constructor === OpenSupportTicketSolution) {
      return (
        <SupportPortalTicketInfo
          className={styles.supportPortalTicketInfo}
          linkToErroneousPage={props.linkToConflictManagement}
        />
      );
    }
  };

  return (
    <>
      <div className={styles.conflictManagement}>
        {/*
      <div className={styles.conflictManagement}>
        */}
        {/* {deactivateSystemCommand.view}
      {deactivateSystemInOtherLandscapeCommand.view}
      {setSystemDownloadVersionCommand.view}
      {upgradeToDifferentVersionCommand.view}
      System section */}
        <ConflictSection
          iconName="info"
          title={t(texts.conflictManagement.system)}
          intent="primary"
        >
          <SystemHeaderFields system={props.system} />
          {/* </ConflictSection>
      Problem section
      <ConflictSection
        iconName="warning"
        title={t(texts.conflictManagement.problem.title)}
        intent="danger"
      > */}
          {/* <ProblemConflictSectionContent
          canFixConflict={canFixConflict}
          conflictingSystem={props.conflictingSystem}
          conflictingContract={props.conflictingContract}
          system={props.system}
          systemVersion={props.systemVersion}
          linkToConflictManagement={props.linkToConflictManagement}
        /> */}
        </ConflictSection>
        {/* Find a solution section -> show if there is only one conflicting system */}
        {/* {canFixConflict && (
        <ConflictSection
          iconName="question"
          title={t(texts.conflictManagement.solution.title)}
          intent="warning"
        >
          {!hasOnlyOneConflict && (
            <div className={styles.warningFirstConflictFix}>
              {t(texts.conflictManagement.warningFirstConflictFix, {
                downloadedVersionSectionTitle: t(
                  texts.conflictManagement.problemConflictSectionContent
                    .headlineConflictingVersion
                ),
              })}
            </div>
          )}
          <QuestionWizard
            explanationForQuestions={t(
              texts.conflictManagement.solution.answerQuestions
            )}
            explanationForSolution={t(
              texts.conflictManagement.solution.pickSolution
            )}
            startingWizardStep={new SufficientVersionWizardStep()}
            onSolutionChanged={setSolution}
            onAnswerChanged={() => setSolutionNotPickedError(false)}
            showLastQuestionAsErroneous={solutionNotPickedError}
          />
        </ConflictSection>
      )} */}
        {/* Effects of solution section */}
        {solution && (
          // <ConflictSection
          //   iconName="check-circle"
          //   title={t(texts.conflictManagement.solutionEffect.title)}
          //   intent="success"
          // >
          <SolutionEffect
            solution={solution}
            proceedMessage={proceedMessage()}
          />
          // </ConflictSection>
        )}
        {/* <div className={styles.proceedButtons}>
        {canFixConflict && (
          <Button
            text={t(texts.general.okay)}
            onClick={onConfirm}
            icon="check"
            intent="success"
          />
        )}
        <Button
          text={t(texts.general.cancel)}
          onClick={onCancel}
          icon="x"
          intent="danger"
        />
      </div> */}
      </div>
    </>
  );
};
