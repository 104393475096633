import { IPlaceholderTextProps } from "./IPlaceholderTextProps";
import { findObjectPlaceholder } from "../../utils/placeholder/findObjectPlaceholder";
import styles from "./PlaceholderText.module.scss";

export const PlaceholderText: React.FC<IPlaceholderTextProps> = (props) => {
  // Split text at {{<placeholder-name>}}
  const texts = props.text.split(/({{.*?}})/);
  // wrap text elements by fragment
  // if text is a placeholder, find the placeholder and set it instead of the text
  const items = texts.map((text) => {
    const placeholder = findObjectPlaceholder(props.placeholders, text);
    return (
      <span  key={text} className={styles.item}>
        {placeholder ? placeholder : text}
      </span >
    );
  });

  return <div>{items}</div>;
};
