import { DialogBody, DialogFooter, Dialog as SNPKitDialog } from "@snpjs/core";

import { IDialogProps } from "./IDialogProps";

export const Dialog: React.FC<IDialogProps> = (props) => {
  return (
    <SNPKitDialog
      isOpen={props.isOpen}
      title={props.title}
      onClose={props.onCancel}
    >
      <DialogBody>{props.children}</DialogBody>
      <DialogFooter actions={props.footer} />
    </SNPKitDialog>
  );
};
