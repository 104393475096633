import { ABAPBoolean } from "../../shared/types/ABAPBoolean";
import { IHotfix } from "../../shared/model/IHotfix";
import { IRelease } from "../../shared/model/IRelease";
import { IServicePack } from "../../shared/model/IServicePack";
import { IVersionSelectionProps } from "./IVersionSelectionProps";
import { OthersRelease } from "../../model/OthersRelease";
import { ValidationError } from "../../errors/ValidationError";
import { ValidationHandler } from "../../types/ValidationHandler";

import { releaseToVersion } from "../../utils/version/releaseToVersion";
import { texts } from "../../i18n/texts";
import { useCallback, useEffect } from "react"; import { useState } from "react";
import { useToggle } from "../../hooks/useToggle";
import { useValidatorProvider } from "../../hooks/useValidatorProvider";

import useTranslation from "../../hooks/useTranslation";

export const useVersionSelectionViewModel = (props: IVersionSelectionProps) => {
  const { t } = useTranslation();
  const [selectableServicePacks, setSelectableServicePacks] = useState<
    IServicePack[]
  >([]);
  const [selectableHotfixes, setSelectableHotfixes] = useState<IHotfix[]>([]);
  const [releaseErrorMessage, setReleaseErrorMessage] = useState("");
  const [servicePackErrorMessage, setServicePackErrorMessage] = useState("");
  const [hotfixErrorMessage, setHotfixErrorMessage] = useState("");
  const [isLTS, toggleIsLTS] = useToggle(props.isLTS);

  const onValidate: ValidationHandler = useCallback(() => {
    let isValid = true;
    if (!props.release) {
      isValid = false;
      setReleaseErrorMessage(t(texts.versionSelection.errorReleaseEmpty));
    }

    if (!props.servicePack) {
      isValid = false;
      setServicePackErrorMessage(
        t(texts.versionSelection.errorServicePackEmpty)
      );
    }

    if (props.displayHotfix === true && !props.hotfix) {
      isValid = false;
      setHotfixErrorMessage(t(texts.versionSelection.errorHotfixEmpty));
    }
    if (!isValid) {
      throw new ValidationError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hotfix, props.release, props.servicePack]);

  const validator = useValidatorProvider(onValidate);
  useEffect(() => {
    props.requestValidator?.(validator);
  }, [props.requestValidator, validator])

  const onReleaseSelected = (release?: IRelease) => {
    setSelectableServicePacks(release?.SERVICE_PACKS ?? []);
    setSelectableHotfixes([]);
    props.setRelease?.(release);
    props.setServicePack?.(undefined);
    props.setHotfix?.(undefined);
    setReleaseErrorMessage("");
    props.onReleaseSelected?.(release);
  };

  const onServicePackSelected = (servicePack?: IServicePack) => {
    setSelectableHotfixes(servicePack?.HOTFIXES ?? []);
    props.setServicePack?.(servicePack);
    props.setHotfix?.(undefined);
    setServicePackErrorMessage("");
  };

  const onHotfixSelected = (hotfix?: IHotfix) => {
    props.setHotfix?.(hotfix);
    setHotfixErrorMessage("");
  };

  const onIsLTSSelected = () => {
    toggleIsLTS();
    props.setIsLTS?.(!isLTS);
    props.setRelease?.(undefined);
    props.setServicePack?.(undefined);
    props.setHotfix?.(undefined);
  };

  const getReleases = () => {
    const releases = props.releases.filter((release) => {
      if (isLTS) {
        return release.IS_LTS === ABAPBoolean.true;
      } else {
        return release.IS_LTS === ABAPBoolean.false;
      }
    });

    // TODO: enable later
    // if (props.displayOthersRelease) {
    releases.push(new OthersRelease());
    //  }

    return releases;
  };

  const getTitleByRelease = (release: IRelease): string => {
    if (release instanceof OthersRelease) {
      return t(texts.versionSelection.othersRelease);
    }
    const releaseTitle = releaseToVersion(release.RELEASE);
    return release.IS_LTS === ABAPBoolean.true
      ? `${releaseTitle} (LTS)`
      : releaseTitle;
  };

  const isOthersReleaseSelected = props.release instanceof OthersRelease;

  return {
    isLTS,
    isOthersReleaseSelected,
    onIsLTSSelected,
    releaseErrorMessage,
    getReleases,
    onReleaseSelected,
    getTitleByRelease,
    servicePackErrorMessage,
    selectableServicePacks,
    onServicePackSelected,
    hotfixErrorMessage,
    selectableHotfixes,
    onHotfixSelected,
  };
};
