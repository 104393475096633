import { ISystemRequirementTypeProps } from "./ISystemRequirementTypeProps";
import styles from "./SystemRequirementType.module.scss";
import React from "react";
import { SingleSelectionDropDown } from "../../../components/selection/singleSelectionDropDown/SingleSelectionDropDown";
import { ISystemRequirement } from "../../../shared/model/ISystemRequirement";

export const SystemRequirementType: React.FC<ISystemRequirementTypeProps> = (
  props
) => {

  const onSystemRequirementChanged = (systemRequirement: ISystemRequirement | undefined, previousRequirement: ISystemRequirement | undefined) => {
    if(systemRequirement && !previousRequirement){
      const updatedCheckedRequirements = props.activeRequirements
      updatedCheckedRequirements.push(systemRequirement.SYSTEM_REQUIREMENT_ID);
      props.onSystemRequirementChanged(updatedCheckedRequirements );
    }
    if(systemRequirement && previousRequirement &&
       systemRequirement.SYSTEM_REQUIREMENT_ID !== previousRequirement.SYSTEM_REQUIREMENT_ID){
      const updatedCheckedRequirements = props.activeRequirements.map(id =>
        id === previousRequirement.SYSTEM_REQUIREMENT_ID ? systemRequirement.SYSTEM_REQUIREMENT_ID : id
      );
      props.onSystemRequirementChanged(updatedCheckedRequirements);
    }
  }

  return (
    <div>
      <h4 className={styles.headline}>
        {props.systemRequirementType.DESCRIPTION}
      </h4>
      <div className={styles.selection}>
        <SingleSelectionDropDown
          editMode={props.editMode}
          objects={props.systemRequirementType.SYSTEM_REQUIREMENTS}
          isSelected={props.isSystemRequirementSelected}
          getName={(systemRequirement) => systemRequirement.DESCRIPTION}
          getInternalId={(systemRequirement) => systemRequirement.SYSTEM_REQUIREMENT_ID}
          getObjectId={(systemRequirement) => systemRequirement.OBJECT_ID}
          onSelectionChanged={onSystemRequirementChanged}
        />
      </div>
    </div>
  );
};
