import { ITagProps } from "./ITagElementProps";
import { Tag } from "@snpjs/core";
import styles from "./TagElement.module.scss";

export const TagElement: React.FC<ITagProps> = (props) => {
  return (
    <div className={styles.tag}>
      <Tag minimal={true} round={true} intent="none">
        {props.text}
      </Tag>
    </div>
  );
};
