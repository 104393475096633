import { AsyncLoad } from "../components/asyncLoad/AsyncLoad";
import { ContractApi } from "../api/ContractApi";
import { ContractBasedPage } from "../features/contract/contractBasedPage/ContractBasedPage";
import { ContractCard } from "../features/contract/contract/ContractCard";
import { IContract } from "../shared/model/IContract";
import styles from "./ContractPage.module.scss";
import { texts } from "../i18n/texts";
import { useRequest } from "../hooks/useRequest";
import { useRouteParam } from "../hooks/useRouteParam";
import { useState } from "react";
import useTranslation from "../hooks/useTranslation";

/**
 * This page is responsible for displaying a specific contract.
 */
export const ContractPage: React.FC = () => {
  const contractId = useRouteParam<string>("contractId");
  const [contract, setContract] = useState<IContract>();
  const request = useRequest();
  const { t } = useTranslation();

  const infoArea = (
    <>
      <br />
      <br />
      <p>{t(texts.contracts.infoArea.infoMessageSingleContract)}</p>
    </>
  );

  return (
    <ContractBasedPage
      iconName="folder-simple-lock"
      showBreadcrumbs
      infoArea={infoArea}
    >
      <AsyncLoad
        positionCentered
        load={async () => {
          await request.send(async () => {
            const contract = await ContractApi.create(contractId).findById(
              contractId
            );
            // Todo - check if contract is null
            setContract(contract);
          });
        }}
      >
        {contract && (
          <div className={styles.cardContainer}>
            <ContractCard
              singleView={true}
              hideContractLink={true}
              key={contract.OBJECT_ID}
              contract={contract}
            />
          </div>
        )}
      </AsyncLoad>
    </ContractBasedPage>
  );
};
