/**
 * An error that occurs if a specific URL parameter is missing.
 */
export class MissingURLParameterError extends Error {
  constructor(parameterName: string) {
    super(
      `Error while getting '${parameterName}' from url. '${parameterName}' was not provided in the url.`
    );
  }
}
