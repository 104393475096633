import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { Dialog } from "../../../components/dialog/Dialog";
import { DialogFooter } from "../../../components/dialogFooter/DialogFooter";
import { DownloadVersion } from "../downloadVersion/DownloadVersion";
import { IDownloadVersion } from "../../../shared/model/IDownloadVersion";
import { IDownloadVersionDialogProps } from "./IDownloadVersionDialogProps";
import { IHotfix } from "../../../shared/model/IHotfix";
import { IRelease } from "../../../shared/model/IRelease";
import { IServicePack } from "../../../shared/model/IServicePack";
import { OthersRelease } from "../../../model/OthersRelease";
import { SystemActionLogType } from "../../../shared/model/SystemActionLogType";
import { checkNotNull } from "../../../utils/checkNotNull";
import { toABAPBoolean } from "../../../utils/toABAPBoolean";
import { useSession } from "../../../hooks/useSession";
import { useState } from "react";
import { useValidatorConsumer } from "../../../hooks/useValidatorConsumer";

export const DownloadVersionDialog: React.FC<IDownloadVersionDialogProps> = (
  props
) => {
  const validator = useValidatorConsumer();
  const [isConfirming, setIsConfirming] = useState(false);
  const [release, setRelease] = useState<IRelease | undefined>(props.release);
  const [servicePack, setServicePack] = useState<IServicePack | undefined>(
    props.servicePack
  );
  const [hotfix, setHotfix] = useState<IHotfix | undefined>(props.hotfix);
  const [isLTS, setIsLTS] = useState<boolean>(props.isLTS ?? false);
  const [reason, setReason] = useState(props.reasonPlaceholder || "");
  const session = useSession();
  const [isOthersReleaseSelected, setIsOthersReleaseSelected] =
    useState<boolean>(false);

  const createDownloadVersion = (): IDownloadVersion => ({
    OBJECT_ID: props.OBJECT_ID,
    HOTFIX: hotfix?.HOTFIX ?? 0,
    RELEASE: checkNotNull(release).RELEASE,
    SERVICE_PACK: checkNotNull(servicePack).SERVICE_PACK,
    IS_LTS: toABAPBoolean(isLTS),
    IS_PRE_RELEASE: ABAPBoolean.false,
    SYS_ACT_LOG_REASON: reason,
    SYS_ACT_LOG_TYPE: SystemActionLogType.SET_SYSTEM_DOWNLOAD_VERSION,
    USER_ID: checkNotNull(session).USER.USER_ID,
  });

  const onOkay = async () => {
    setIsConfirming(true);
    if (!validator.isValid()) {
      setIsConfirming(false);
      return;
    }
    const downloadVersion = createDownloadVersion();
    await props.onOkay(downloadVersion);
    setIsConfirming(false);
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      title={props.title}
      footer={
        <DialogFooter
          disableConfirmButton={isOthersReleaseSelected}
          isConfirming={isConfirming}
          onCancel={props.onCancel}
          onOkay={onOkay}
        />
      }
    >
      <DownloadVersion
        displayHotfix={props.displayHotfix}
        filterReleases={props.filterReleases}
        isVersionChangeable={props.isVersionChangeable}
        hotfix={hotfix}
        isLTS={isLTS}
        isOthersReleaseSelected={isOthersReleaseSelected}
        productId={props.productId}
        reason={reason}
        release={release}
        requestValidator={validator.onRequestValidator}
        servicePack={servicePack}
        setHotfix={setHotfix}
        setIsLTS={setIsLTS}
        setReason={setReason}
        setRelease={setRelease}
        setServicePack={setServicePack}
        subTextTranslation={props.subTextTranslation}
        onReleaseSelected={(release) =>
          setIsOthersReleaseSelected(release instanceof OthersRelease)
        }
      />
    </Dialog>
  );
};
