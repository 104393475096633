/**
 * An error that occurs if a specific app environment parameter type is invalid.
 * E.g. expected parameter type is boolean, but the parameter is of type string.
 */
export class InvalidAppParameterTypeError extends Error {
  constructor(parameterName: string, expectedType: any) {
    super(
      `Error while getting app parameter '${parameterName}'. Parameter must be of type '${expectedType}'. Correct parameter '${parameterName}' in config file '.env'. Restart server to apply changes.`
    );
  }
}
