import { IHintProps } from "./IHintProps";
import { splitTextToNewLine } from "../../utils/splitTextToNewLine";
import styles from "./Hint.module.scss";

export const Hint: React.FC<IHintProps> = (props) => {
  return (
    <div>
      <h4 className={styles.title}>{props.title}</h4>
      <div className={styles.text}>{splitTextToNewLine(props.text)}</div>
    </div>
  );
};
