import { ContractApi } from "../../../../api/ContractApi";
import { ContractSearchResult } from "../../services/ContractSearchResult";
import { FuzzySearch } from "../../../../services/fuzzySearch/FuzzySearch";
import { IContract } from "../../../../shared/model/IContract";
import { IContractSearchProps } from "./IContractSearchProps";
import { IDownloadLast } from "../../../../shared/model/IDownloadLast";
import { ISystemFilter } from "../../../../shared/types/ISystemFilter";
import { ISystemFilterProps } from "../../../../shared/types/ISystemFilterProps";
import { SessionRepository } from "../../../../api/session/SessionRepository";

import { isSystemFilterPropsNotEmpty } from "../../../../utils/isSystemFilterPropsNotEmpty";
import { useCallback } from "react";
import { useState } from "react";

const SESSION_STORAGE_QUERY = "query";

export const useContractSearchViewModel = (props: IContractSearchProps) => {
  const [query, setQuery] = useState<string>(sessionStorage.getItem(SESSION_STORAGE_QUERY) || "");
  const [isSearching, setIsSearching] = useState(false);
  const [systemFilterProps, setSystemFilterProps] = useState<
    ISystemFilterProps | undefined
  >(undefined);

  const onSearch = async () => {
    setIsSearching(true);

    // first filter contracts depending on the query,
    // which includes all properties of the contract itself
    const fuzzySearch = new FuzzySearch<IContract>();
    let contracts = fuzzySearch.search(query, props.contracts);
    const contractSearchResult = new ContractSearchResult();

    if (systemFilterProps && isSystemFilterPropsNotEmpty(systemFilterProps)) {
      const contractObjectIds = contracts.map((contract) => contract.OBJECT_ID);
      const systemFilter: ISystemFilter = {
        CONTRACT_OBJECT_IDS: contractObjectIds,
        ...systemFilterProps,
      };
      const downloadLasts: IDownloadLast[] = await ContractApi.create(
        SessionRepository.contractId
      ).findBySystemFilter(systemFilter);
      contracts = contracts.filter((contract) => {
        const downloadLastFiltered = downloadLasts.filter((downloadLast) => {
          return (
            downloadLast.CUSTOMER_ID === contract.CUSTOMER_ID &&
            downloadLast.PROJECT_ID === contract.PROJECT_ID
          );
        });
        if (downloadLastFiltered.length > 0) {
          downloadLastFiltered.forEach((element) => {
            contractSearchResult.add(contract.OBJECT_ID, element.PRODUCT_ID);
          })
        }
        return downloadLastFiltered.length > 0;
      });
    }
    props.onSearchCompleted?.(contracts, contractSearchResult, systemFilterProps, query);

    setIsSearching(false);
  };

  const onSystemFilterPropsChange = useCallback(
    (systemFilterProps: ISystemFilterProps) =>
      setSystemFilterProps(systemFilterProps),
    []
  );

  return {
    isSearching,
    onSearch,
    onSystemFilterPropsChange,
    query,
    setQuery,
  };
};
