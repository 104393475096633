import { DownloadVersionMeta } from "../shared/model/IDownloadVersion";
import { FileService } from "./services/FileService";
import { IDownloadVersion } from "../shared/model/IDownloadVersion";
import { IEntityDetails } from "../shared/types/IEntityDetails";
import { ISystemLandscape } from "../shared/model/ISystemLandscape";
import { ProductMeta } from "../shared/model/IProduct";
import { Repository } from "./core/Repository";
import { SystemActionLogMeta } from "../shared/model/ISystemActionLog";
import { SystemLandscapeMeta } from "../shared/model/ISystemLandscape";

class SystemLandscapeApiDefault extends Repository<ISystemLandscape> {
  constructor() {
    super(SystemLandscapeMeta.path);
  }

  addByProductId(
    productId: string,
    entity: IEntityDetails<ISystemLandscape>
  ): Promise<ISystemLandscape> {
    return this.post(
      `${this.host}${ProductMeta.path}/${productId}${this.path}`,
      entity
    );
  }

  deleteEmpty(entity: ISystemLandscape): Promise<boolean> {
    return this.delete(`${this.host}${this.path}/${entity.OBJECT_ID}`);
  }

  findById(id: string): Promise<ISystemLandscape> {
    return this.get(`${this.host}${this.path}/${id}`);
  }

  findInfoGraphic(productId: string): Promise<string> {
    return this.createPromise(async (resolve, reject) => {
      try {
        const result = await this.getFile(
          `${this.url}/${productId}/info-graphic`
        );
        if (result.blob.size !== 0) {
          const fileSource = FileService.getSrc(result);
          resolve(fileSource);
        } else {
          resolve("");
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Sets an alternative download version for all systems of the system landscape,
   * which is referred by {@link downloadVersion}.
   */
  setSystemsDownloadVersion(downloadVersion: IDownloadVersion) {
    return this.post(
      `${this.url}/${downloadVersion.OBJECT_ID}${DownloadVersionMeta.path}`,
      downloadVersion
    );
  }

  update(entity: ISystemLandscape): Promise<ISystemLandscape> {
    return this.post(`${this.host}${this.path}/${entity.OBJECT_ID}`, entity);
  }

  updateSystemActionLog(downloadVersion: IDownloadVersion): Promise<boolean> {
    return this.post(
      `${this.host}${this.path}/${downloadVersion.OBJECT_ID}${SystemActionLogMeta.path}`,
      downloadVersion
    );
  }
}

/**
 * This class is responsible for handling all {@link ISystem} specific REST requests.
 */
export const SystemLandscapeApi = new SystemLandscapeApiDefault();
