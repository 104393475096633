import { AppContext } from "../../context/AppContext";
import { IAppContextProviderProps } from "./IAppContextProviderProps";
import { LanguageType } from "../../types/LanguageType";
import { useLocalStorageValue } from "../../hooks/useLocalStorageValue";
import { useRecentContractsStorage } from "../../hooks/useRecentContractsStorage";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { useUserProfileStorage } from "../../hooks/useUserProfileStorage";

/**
 * This component is responsible for initializing the {@link AppContext} with all its values.
 */
export const AppContextProvider: React.FC<IAppContextProviderProps> = (
  props
) => {
  const language = useLocalStorageValue("swpo.language", LanguageType.EN);
  return (
    <AppContext.Provider
      value={{
        language,
        session: useSessionStorage(),
        userProfile: useUserProfileStorage(),
        recentContracts: useRecentContractsStorage(),
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
