import { HTMLSelect } from "@snpjs/core";
import { ISelectProps } from "./ISelectProps";

export function Select<T>(props: ISelectProps<T>) {
  let items = props.items.map((item, index) => (
    <option key={index + 1}>{props.renderOptionTitle(item)}</option>
  ));
  
  items = [<option key="0">{props.placeholder}</option>, ...items];

  const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const item = props.items[event.target.selectedIndex - 1];
    props.onSelect?.(item);
  };

  return (
    <HTMLSelect
      className={props.className}
      disabled={props.disabled}
      id={props.id}
      onChange={onSelect}
      value={
        props.selected !== undefined
          ? props.renderOptionTitle(props.selected)
          : ""
      }
    >
      {items}
    </HTMLSelect>
  );
}
