import { Button } from "@snpjs/core";
import { LandingPageCarousel } from "../landingPageCarousel/LandingPageCarousel";
import { LandingPageOption } from "../landingPageOption/LandingPageOption";
import { openLinkInTab } from "../../../utils/openLinkInTab";
import styles from "./LandingPageOptionList.module.scss";
import { texts } from "../../../i18n/texts";
import { useConfig } from "../../../hooks/useConfig";
import { useLogin } from "../../../hooks/useLogin";
import useTranslation from "../../../hooks/useTranslation";

export const LandingPageOptionList: React.FC = () => {
  const login = useLogin();
  const { t } = useTranslation();
  const config = useConfig();

  const onOpenELearning = () => openLinkInTab(config.ECAMPUS_LINK);

  return (
    <div className={styles.landingPageOptionList}>
      <LandingPageOption title={t(texts.landingPage.optionWhatsNew.title)}>
        <LandingPageCarousel />
      </LandingPageOption>
      <LandingPageOption title={t(texts.landingPage.optionDirectLink.title)}>
        <p>{t(texts.landingPage.optionDirectLink.text)}</p>
        <Button onClick={login}>{t(texts.landingPage.goToLogin)}</Button>
      </LandingPageOption>
      <LandingPageOption title={t(texts.landingPage.optionVideo.title)}>
        <p>{t(texts.landingPage.optionVideo.text)}</p>
        <Button onClick={onOpenELearning}>
          {t(texts.landingPage.openVideo)}
        </Button>
      </LandingPageOption>
    </div>
  );
};
