import { Button, Tooltip } from "@snpjs/core";

import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { EditableCard } from "../../../components/editableCard/EditableCard";
import { IAuthRole } from "../../../shared/model/IAuthRole";
import { IUserAuthRoleCardProps } from "./IUserAuthRoleCardProps";
import { LabeledInput } from "../../../components/labeledInput/LabeledInput";
import { MessageArea } from "../../../components/messageArea/MessageArea";
import { MultiSelection } from "../../../components/selection/multiSelection/MultiSelection";
import { UserAuthRoleDummy } from "../../../model/UserAuthRoleDummy";
import styles from "./UserAuthRoleCard.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";
import { useUserAuthRoleCardViewModel } from "./useUserAuthRoleCardViewModel";

/**
 * This component is responsible for displaying a specific user authority roles
 */
export const UserAuthRoleCard: React.FC<IUserAuthRoleCardProps> = (props) => {
  const viewModel = useUserAuthRoleCardViewModel(props);
  const { t } = useTranslation();

  const isAuthRoleSelected = (authRole: IAuthRole): boolean => {
    return (
      viewModel.userAuthRole.USER_AUTH_ROLES.includes(authRole.USER_ROLE_ID) ||
      viewModel.userAuthRole.INTERNAL_AUTH_ROLES.includes(authRole.USER_ROLE_ID)
    );
  };

  const isAuthRoleDisabled = (authRole: IAuthRole): boolean => {
    return authRole.USER_ROLE_INTERNAL === ABAPBoolean.true;
  };

  return (
    <EditableCard
      actionsDisplayMode={
        <Tooltip
          content={
            viewModel.canShareContract()
              ? t(texts.userAuthRoleCard.tooltipShare)
              : t(texts.userAuthRoleCard.tooltipShareNoPrivilige)
          }
        >
          <Button
            icon="share"
            outlined
            onClick={viewModel.share}
            disabled={!viewModel.canShareContract()}
          />
        </Tooltip>
      }
      canDelete={viewModel.canDeleteUser}
      canEdit={viewModel.canEditUser}
      isEditable={viewModel.userIsEditable}
      className={styles.card}
      deleteText={(userAuthRole) => {
        return {
          text: texts.userAuthRoleCard.deleteText,
          placeholders: { email: userAuthRole.EMAIL_ADDRESS },
        };
      }}
      deleteTitle={texts.userAuthRoleCard.deleteTitle}
      deleteTooltip={
        !viewModel.canDeleteUser()
          ? t(texts.userAuthRoleCard.deleteTooltip)
          : undefined
      }
      dummyClassType={UserAuthRoleDummy}
      editMode={viewModel.editMode}
      initialObject={props.userAuthRole}
      onDelete={props.onDelete}
      onSave={viewModel.onSave}
      onValidate={viewModel.onValidate}
      setEditMode={viewModel.setEditMode}
      setObject={viewModel.setUserAuthRole}
    >
      <div className={styles.userAuthRole}>
        {UserAuthRoleDummy.isTransient(props.userAuthRole) && (
          <MessageArea
            className={styles.messageArea}
            message={{
              text: t(
                texts.userAuthRoleCard.infoTextEmailSendAfterAddingNewUser
              ),
              severity: "info",
            }}
          />
        )}
        <div className={styles.details}>
          <form onSubmit={(event) => event.preventDefault()}>
            <LabeledInput
              className={styles.labeledInput}
              autoFocus={true}
              errorMessage={
                viewModel.inputFieldError && viewModel.inputFieldError
              }
              intent={
                viewModel.inputFieldError
                  ? "danger"
                  : viewModel.editMode &&
                    UserAuthRoleDummy.isTransient(props.userAuthRole)
                  ? "primary"
                  : "none"
              }
              label={t(texts.userAuthRoleCard.email)}
              large={true}
              onChange={(event) => viewModel.setEmail(event.target.value)}
              readOnly={
                !(
                  viewModel.editMode &&
                  UserAuthRoleDummy.isTransient(props.userAuthRole)
                )
              }
              value={viewModel.userAuthRole.EMAIL_ADDRESS}
            />
          </form>

          <div className={styles.detailsBody}>
            <h4>{t(texts.userAuthRoleCard.authRoles)}</h4>

            <MultiSelection<IAuthRole>
              // immutable objects are not visible in edit mode
              immutableObjects={props.authRoles.filter((authRole) =>
                viewModel.getImmutableRoles().includes(authRole.USER_ROLE_ID)
              )}
              editMode={viewModel.editMode}
              getName={(authRole) => authRole.DESCRIPTION}
              isSelected={isAuthRoleSelected}
              isDisabled={isAuthRoleDisabled}
              objects={props.authRoles}
              onActivate={viewModel.onAuthRoleActivated}
              onDeactivate={viewModel.onAuthRoleDeactivated}
            />
          </div>
        </div>
      </div>
    </EditableCard>
  );
};
