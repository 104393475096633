import { ClassType } from "../types/ClassType";
import { Decorator } from "../types/Decorator";
import { IDecoratorInfo } from "./IDecoratorInfo";

class DecoratorInfoDefault implements IDecoratorInfo {
  private decorations: Map<ClassType<any>, Map<Decorator, any>> = new Map();

  delete(classType: ClassType<any>, decorator: Decorator) {
    this.decorations.get(classType)?.delete(decorator);
  }

  find<T>(classType: ClassType<any>, decorator: Decorator): T | undefined {
    const decoration = this.decorations.get(classType);
    return decoration?.get(decorator);
  }

  introduce<T>(classType: ClassType<any>, decorator: Decorator, value: T) {
    const decoration =
      this.decorations.get(classType) ?? new Map<Decorator, any>();
    decoration.set(decorator, value);
    this.decorations.set(classType, decoration);
  }

  has(classType: ClassType<any>, decorator: Decorator): boolean {
    return this.find(classType, decorator) !== undefined;
  }
}

/**
 * This service is responsible for providing access to class decorations
 */
export const DecoratorInfo: IDecoratorInfo = new DecoratorInfoDefault();
