import { IVersion } from "../../shared/types/IVersion";

import { flattenVersion } from "./flattenVersion";

/**
 * Compares the two versions {@link a} and {@link b}.
 * Returns zero if the versions are equal, a negative number if the first version is less than the second,
 * or a positive number if the first version is greater than the second.
 */
export const compareVersion = (a: IVersion, b: IVersion): number => {
  const flattenA = flattenVersion(a);
  const flattenB = flattenVersion(b);

  if (flattenA > flattenB) {
    return 1;
  }

  if (flattenA < flattenB) {
    return -1;
  }

  return 0;
};
