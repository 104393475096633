import { ABAPBoolean } from "../types/ABAPBoolean";
import { AuthRole } from "../../auth/types/AuthRole";
import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";
import { IHaveDescription } from "../../types/have/IHaveDescription";

/**
 * An implementation of this interface represents an authority role.
 * A user can have authority roles, like admin, delivery, support
 */
export interface IAuthRole extends IEntity, IHaveDescription {
  USER_ROLE_ID: AuthRole;
  USER_ROLE_INTERNAL: ABAPBoolean;
}

export const AuthRoleMeta: IEntityMeta<IAuthRole> = { path: "/auth-roles" };
