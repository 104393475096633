import { InvalidAppParameterTypeError } from "./errors/InvalidAppParameterTypeError";
import { NoSuchAppParameterError } from "./errors/NoSuchAppParameterError";

import { checkNotNull } from "./utils/checkNotNull";
import { toBoolean } from "./utils/toBoolean";

/**
 * This constant provides access to app specific environment parameters.
 */
export const AppConfig = {
  BACKEND_HOST: checkNotNull(
    process.env.REACT_APP_BACKEND_HOST,
    new NoSuchAppParameterError("REACT_APP_BACKEND_HOST")
  ),
  MOCK_REST: process.env.REACT_APP_MOCK_REST
    ? toBoolean(
        process.env.REACT_APP_MOCK_REST,
        new InvalidAppParameterTypeError("REACT_APP_MOCK_REST", Boolean.name)
      )
    : false,
  /**
   * Returns if the current environment is of type development
   */
  isDevelopment: process.env.NODE_ENV === "development",
};
