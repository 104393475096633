import { ErrorReason } from "./../types/ErrorReason";

import { createError } from "./error/createError";

/**
 * Converts a given {@link value} to boolean.
 */
export const toBoolean = (value: any, reason?: ErrorReason): boolean => {
  if (typeof value === "string") {
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        break;
    }
  }

  throw createError(
    reason ??
      `Error while converting value to boolean. Invalid value. Value must be "true" or "false"`
  );
};
