import { AuthRole } from "../auth/types/AuthRole";
import { Dummy } from "./Dummy";
import { IUserAuthRole } from "../shared/model/IUserAuthRole";
import { ABAPBoolean } from "../shared/types/ABAPBoolean";

/**
 * This class represents a dummy for {@link IUserAuthRole}.
 * A dummy means the object is not persisted yet.
 */
export class UserAuthRoleDummy extends Dummy implements IUserAuthRole {
  EMAIL_ADDRESS: string = "";
  USER_AUTH_ROLES: AuthRole[] = [];
  INTERNAL_AUTH_ROLES: AuthRole[] = [];
  IS_SYNCHRONIZED: ABAPBoolean = ABAPBoolean.false;
  IS_DEACTIVATED: ABAPBoolean = ABAPBoolean.false;

  constructor(template?: IUserAuthRole, copyTemplateId?: boolean) {
    super();
    if (template) {
      if (copyTemplateId === true) {
        this.OBJECT_ID = template.OBJECT_ID;
      }
      this.EMAIL_ADDRESS = template.EMAIL_ADDRESS;
      this.USER_AUTH_ROLES = template.USER_AUTH_ROLES;
    }
  }
}
