import { Alignment, Navbar } from "@snpjs/core";

import { AccountButton } from "../account/AccountButton";
import { EnvironmentTag } from "../environment/EnvironmentTag";
import { ReactComponent as Logo } from "../../assets/snp_logo_blue.svg";
import { Routes } from "../../routes/Routes";
import { SupportButton } from "../system/support/SupportButton";
import { UserGuideButton } from "../userGuide/UserGuideButton";
import styles from "./Header.module.scss";
import { texts } from "../../i18n/texts";
import { useNavigate } from "react-router";
import useTranslation from "../../hooks/useTranslation";

/**
 * This component is responsible for displaying the header for the app.
 */
export const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Navbar fixedToTop className={styles.navbar}>
      <Navbar.Group align={Alignment.LEFT}>
        <Logo
          className={styles.logo}
          onClick={() => navigate(Routes.homePage.toPath())}
        />
        <Navbar.Heading className={styles.title}>
          {t(texts.general.swpoTitle)}&nbsp; <EnvironmentTag />
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Navbar.Divider />
        <UserGuideButton />
        <SupportButton />
        <Navbar.Divider />
        <AccountButton />
      </Navbar.Group>
    </Navbar>
  );
};
