import { IContractResponsiblesCollapseProps } from "./IContractResponsiblesCollapseProps";
import { Icon } from "@snpjs/core";
import styles from "./ContractResponsiblesCollapse.module.scss";
import { useToggle } from "../../../hooks/useToggle";

export const ContractResponsiblesCollapse: React.FC<
  IContractResponsiblesCollapseProps
> = (props) => {
  const [isCollapsed, toggleIsCollapsed] = useToggle(props.collapsed ?? false);

  const onToggle = () => toggleIsCollapsed();

  return (
    <div className={styles.collapse}>
      {isCollapsed ? <>{props.children}</> : <>{props.fallback}</>}
      {props.hideIcon !== true && (
        <div>
          <Icon
            className={styles.icon}
            icon={isCollapsed ? "caret-up" : "caret-down"}
            onClick={onToggle}
          />
        </div>
      )}
    </div>
  );
};
