import { LandingPageHeader } from "../features/landingPage/landingPageHeader/LandingPageHeader";
import { LandingPageOptionList } from "../features/landingPage/landingPageOptionList/LandingPageOptionList";
import { PublicPage } from "../components/pages/publicPage/PublicPage";
import styles from "./LandingPage.module.scss";

export const LandingPage: React.FC = () => {
  return (
    <PublicPage
      className={styles.landingPage}
      iconName="shuffle"
      showPageInfo={false}
    >
      <LandingPageHeader />
      <LandingPageOptionList />
    </PublicPage>
  );
};
