import { Button, Popover } from "@snpjs/core";

import useTranslation from "../../hooks/useTranslation";
import { texts } from "../../i18n/texts";
import { UserGuideMenu } from "./userGuideMenu/UserGuideMenu";

/**
 * User Guide Button.
 */
export const UserGuideButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Popover content={<UserGuideMenu />} placement="bottom-start">
        <Button
          text={t(texts.userGuide.userGuide)}
          icon="book-info"
          rightIcon="caret-down"
          minimal={true}
        />
      </Popover>
    </>
  );
};
