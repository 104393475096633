import { ABAPBoolean } from "../shared/types/ABAPBoolean";
import { Dummy } from "./Dummy";
import { IConflict } from "../shared/model/IConflict";
import { IProductVersion } from "../shared/types/IProductVersion";
import { ISystem } from "../shared/model/ISystem";

/**
 * This class represents a dummy for {@link ISystem}.
 * A dummy means the object is not persisted yet.
 */
export class SystemDummy extends Dummy implements ISystem {
  IS_DEACTIVATED: ABAPBoolean = ABAPBoolean.false;
  DESCRIPTION: string = "";
  SYSTEM_CLIENT: string = "";
  SYSTEM_TYPE: string = "";
  INSTNUMBER: string = "0";
  SYSTEM_ID: string = "";
  SYSTEM_ROLES: string[] = [];
  SYSTEM_REQMTS: string[] = [];
  CONFLICTS: IConflict[] = [];
  VERSION: IProductVersion = {
    PRODUCT_ID: "",
    RELEASE: 0,
    IS_LTS: ABAPBoolean.false,
    IS_PRE_RELEASE: ABAPBoolean.false,
    SERVICE_PACK: 0,
    HOTFIX: 0,
  };

  constructor(template?: ISystem, copyTemplateId?: boolean) {
    super();
    if (template) {
      if (copyTemplateId === true) {
        this.OBJECT_ID = template.OBJECT_ID;
      }
      this.IS_DEACTIVATED = template.IS_DEACTIVATED;
      this.SYSTEM_CLIENT = template.SYSTEM_CLIENT;
      this.INSTNUMBER = template.INSTNUMBER;
      this.SYSTEM_ID = template.SYSTEM_ID;
      this.SYSTEM_ROLES = template.SYSTEM_ROLES;
      this.SYSTEM_REQMTS = template.SYSTEM_REQMTS;
      this.DESCRIPTION = template.DESCRIPTION;
    }
  }
}
