import { ICommand } from "../../../types/ICommand";
import { texts } from "../../../i18n/texts";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { IUseSetAllLicensesCommandProps } from "./IUseSetAllLicensesCommandProps";
import { SetAllLicenseDialog } from "../setAllLicenseDialog/SetAllLicenseDialog";
/**
 * This command handles the selection of a version of a system landscape,
 * which is empty or its systems are initial or have no downloaded version yet.
 */
export const useSetAllLicensesCommand = (
  props: IUseSetAllLicensesCommandProps
): ICommand => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const onCancel = () => {
    setShow(false);
  };

  const onOkay = async () => {
    const success = await props.onOkay();
    if(success === true){
      setShow(false);
    }
    return success
  };

  const view = show && (
    <SetAllLicenseDialog
      reasonRef={props.reasonRef}
      isOpen={show}
      onCancel={onCancel}
      onOkay={onOkay}
      subTextTranslation={{
        text: texts.SystemGrantAllLicenseDialog.subTitle,
        placeholders: {
          action: props.allLicensesToggle? t(texts.SystemGrantAllLicenseDialog.actionRevoke): t(texts.SystemGrantAllLicenseDialog.actionGrant),
          product: props.productId,
        },
      }}
      title={t(texts.systemLandscape.captionSetAllLicense, {
        action: props.allLicensesToggle? t(texts.SystemGrantAllLicenseDialog.actionRevokeCapital): t(texts.SystemGrantAllLicenseDialog.actionGrantCapital)}
      )}
    />
  );

  const execute = () => {
    setShow(true);
  };

  return {
    execute,
    view,
  };
};
