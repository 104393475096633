import { ISystemRequirementTypeListProps } from "./ISystemRequirementTypeListProps";
import { SystemRequirementType } from "../systemRequirementType/SystemRequirementType";

export const SystemRequirementTypeList: React.FC<
  ISystemRequirementTypeListProps
> = (props) => {
  const items = props.systemRequirementTypes.map(
    (systemRequirementType, index) => {
        return (
          <SystemRequirementType
            key={systemRequirementType.SYSTEM_REQUIREMENT_TYPE_ID}
            {...props}
            systemRequirementType={systemRequirementType}
          />
        );
    }
  );
  return <>{items}</>;
};
