import { Card } from "@snpjs/core";
import { ILandingPageOptionProps } from "./ILandingPageOptionProps";
import styles from "./LandingPageOption.module.scss";

export const LandingPageOption: React.FC<ILandingPageOptionProps> = (props) => {
  return (
    <section className={styles.landingPageOption}>
      <Card>
        <h2 className={styles.header}>{props.title}</h2>
        <div>{props.children}</div>
      </Card>
    </section>
  );
};
