import { ConflictListColumns } from "../conflictListColumns/ConflictListColumns";
import { ConflictListItem } from "../conflictListItem/ConflictListItem";
import { IConflictListProps } from "./IConflictListProps";
import UUID from "../../../../utils/UUID";
import styles from "./ConflictList.module.scss";

export const ConflictList: React.FC<IConflictListProps> = (props) => {
  const items = props.conflicts.map((conflict) => (
    <ConflictListItem key={UUID.randomUUID()} conflict={conflict} />
  ));

  return (
    <div className={styles.conflictList}>
      <ConflictListColumns />
      {items}
    </div>
  );
};
