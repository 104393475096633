import { ContractSearchResult } from "../services/ContractSearchResult";
import { IContract } from "../../../shared/model/IContract";
import { IContractListProps } from "./IContractListProps";
import { IContractSearchResult } from "../services/IContractSearchResult";
import { ISystemFilterProps } from "../../../shared/types/ISystemFilterProps";

import { checkNotNull } from "../../../utils/checkNotNull";
import { useConfig } from "../../../hooks/useConfig";
import { useRecentContracts } from "../../../hooks/useRecentContracts";
import { useState } from "react";

const SESSION_STORAGE_CONTRACTS = "contracts";
const SESSION_STORAGE_SEARCHHIGHLIGHTS = "searchHighlights";
const SESSION_STORAGE_SYSTEMFILTER = "systemFilter";
const SESSION_STORAGE_QUERY = "query";

export const useContractListViewModel = (props: IContractListProps) => {
  const recentContracts = useRecentContracts();
  const config = useConfig();

  const displayRecentlyOpenedContracts =
    props.hideRecentlyOpenedContracts === undefined ||
    props.hideRecentlyOpenedContracts === false;

  const needsDisplayContractSearch =
    props.contracts && props.contracts.length >= config.SHOW_SEARCH_CONTRACTS;

  // set initial contracts from session storage if possible
  const setInitialContracts = () => {
    if (needsDisplayContractSearch === false) {
      return props.contracts;
    }
    try {
      const storedContracts = sessionStorage.getItem(SESSION_STORAGE_CONTRACTS);
      return storedContracts ? JSON.parse(storedContracts) : props.contracts;
    } catch (error) {
      console.error("Failed to parse contracts from sessionStorage", error);
      return props.contracts;
    }
  };

  // set initial search-highlighted products from session storage if possible
  const setInitialFilterHighlights = () => {
    if (needsDisplayContractSearch === false) {
      return undefined;
    }
    try {
      const storedSearchHighlights = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_SEARCHHIGHLIGHTS)!
      );
      const contractSearchResult = new ContractSearchResult();
      storedSearchHighlights?.forEach((valuePair: [string, string]) => {
        contractSearchResult.add(valuePair[0], valuePair[1]);
      });
      return contractSearchResult;
    } catch (error) {
      console.error(
        "Failed to parse searchHighlights from sessionStorage",
        error
      );
      return undefined;
    }
  };

  const [contracts, setContracts] = useState<IContract[]>(setInitialContracts);
  const [contractSearchResult, setContractSearchResult] = useState<
    IContractSearchResult | undefined
  >(setInitialFilterHighlights);

  // saving filter configurations and resulting contracts to the session storage
  const setFiltersInSessionStorage = (
    contracts: IContract[],
    searchHighlights: [string, string][],
    systemFilterProps: ISystemFilterProps | undefined,
    query: string
  ) => {
    try {
      sessionStorage.setItem(
        SESSION_STORAGE_CONTRACTS,
        JSON.stringify(contracts)
      );
      sessionStorage.setItem(
        SESSION_STORAGE_SEARCHHIGHLIGHTS,
        JSON.stringify(searchHighlights)
      );
      sessionStorage.setItem(
        SESSION_STORAGE_SYSTEMFILTER,
        JSON.stringify(systemFilterProps)
      );
      sessionStorage.setItem(SESSION_STORAGE_QUERY, query);
    } catch (error) {
      console.error("Failed to save filters to sessionStorage", error);
    }
  };

  const recentlyOpenedContracts = recentContracts.value.contractIds
    .filter(
      (contractId) =>
        contracts?.findIndex(
          (contract) => contract.OBJECT_ID === contractId
        ) !== -1
    )
    .map((contractId) =>
      checkNotNull(
        contracts?.find((contract) => contract.OBJECT_ID === contractId)
      )
    );

  const onSearchCompleted = (
    contracts: IContract[],
    contractSearchResult: IContractSearchResult,
    systemFilterProps: ISystemFilterProps | undefined,
    query: string
  ) => {
    setContracts(contracts);
    setContractSearchResult(contractSearchResult);
    let searchResultArray: [string, string][] = [];
    const iterable = contractSearchResult.getAllContracts();
    // transforming mapping into a simple array representation
    iterable?.forEach((mapping, contractId) => {
      mapping?.forEach((value, key) => {
        searchResultArray.push([contractId, key]);
      });
    });
    setFiltersInSessionStorage(
      contracts,
      searchResultArray,
      systemFilterProps,
      query
    );
  };

  /**
   * Only display contract search if user is support user and
   * recently opened contracts are displayed, which means all contracts are displayed
   */

  return {
    contracts,
    contractSearchResult,
    displayRecentlyOpenedContracts,
    needsDisplayContractSearch,
    onSearchCompleted,
    recentlyOpenedContracts,
  };
};
