import { Fragment } from "react";
import { IProductExpirationOverviewProps } from "./IProductExpirationOverviewProps";
import styles from "./ProductExpirationOverview.module.scss";
import { useFormatDate } from "../../../hooks/useFormatDate";
import { useProductInfo } from "../../../hooks/useProductInfo";

export const ProductExpirationOverview: React.FC<
  IProductExpirationOverviewProps
> = (props) => {
  const formatDate = useFormatDate();
  const productInfo = useProductInfo();

  const items = props.products.map((product, index) => (
    <Fragment key={index}>
      <div>{product.DESCRIPTION}</div>
      <div>{formatDate(productInfo.getLongestEndDate(product).toString())}</div>
    </Fragment>
  ));

  return <div className={styles.productExpirationOverview}>{items}</div>;
};
