import { ReactComponent as Logo } from "../../../assets/snp_logo_blue.svg";
import { splitTextToParagraph } from "../../../utils/splitTextToParagraph";
import styles from "./LandingPageHeader.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";

export const LandingPageHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className={styles.titleSection}>
        <Logo width={"5rem"} height={"5rem"} />
        <h1 className={styles.title}>{t(texts.landingPage.title)}</h1>
      </section>
      <section className={styles.explanation}>
        <div>{splitTextToParagraph(t(texts.landingPage.description))}</div>
      </section>
    </>
  );
};
