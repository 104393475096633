import { DownloadVersionDialog } from "../downloadVersionDialog/DownloadVersionDialog";
import { ICommand } from "../../../types/ICommand";
import { IDownloadVersion } from "../../../shared/model/IDownloadVersion";
import { IHotfix } from "../../../shared/model/IHotfix";
import { IUseSetSystemLandscapeDownloadVersionCommandProps } from "./IUseSetSystemLandscapeDownloadVersionCommandProps";
import { Link } from "react-router-dom";
import { SystemLandscapeApi } from "../../../api/SystemLandscapeApi";
import { texts } from "../../../i18n/texts";
import { useConfig } from "../../../hooks/useConfig";
import { useRequest } from "../../../hooks/useRequest";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";

/**
 * This command handles the selection of a version of a system landscape,
 * which is empty or its systems are initial or have no downloaded version yet.
 */
export const useSetSystemLandscapeDownloadVersionCommand = (
  props: IUseSetSystemLandscapeDownloadVersionCommandProps
): ICommand => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const request = useRequest();
  const config = useConfig();

  const onCancel = () => {
    setShow(false);
  };

  const onOkay = async (downloadVersion: IDownloadVersion) => {
    await request.send(async () => {
      props.systemLandscape.RELEASE = downloadVersion.RELEASE;
      props.systemLandscape.SERVICE_PACK = downloadVersion.SERVICE_PACK;
      props.systemLandscape.HOTFIX = downloadVersion.HOTFIX;
      props.systemLandscape.IS_LTS = downloadVersion.IS_LTS;
      await SystemLandscapeApi.setSystemsDownloadVersion(downloadVersion);
      await SystemLandscapeApi.update(props.systemLandscape);
      await SystemLandscapeApi.updateSystemActionLog(downloadVersion);
      setShow(false);
      props.onOkay?.();
    });
  };

  const createHotfix = (): IHotfix | undefined =>
    props.hotfix
      ? {
          HOTFIX: props.hotfix,
        }
      : undefined;

  const view = show && (
    <DownloadVersionDialog
      hotfix={createHotfix()}
      isVersionChangeable={props.isVersionChangeable ?? false}
      isOpen={show}
      OBJECT_ID={props.systemLandscape.OBJECT_ID}
      onCancel={onCancel}
      onOkay={onOkay}
      productId={props.productId}
      subTextTranslation={{
        text: texts.setSystemLandscapeUpgradeVersionCommand.subTitle,
        placeholders: {
          supportPortal: (
            <Link to={config.SUPPORT_PORTAL_LINK}>
              {config.SUPPORT_PORTAL_LINK}
            </Link>
          ),
        },
      }}
      title={t(texts.systemSetDownloadVersionCommand.title)}
    />
  );

  const execute = () => {
    setShow(true);
  };

  return {
    execute,
    view,
  };
};
