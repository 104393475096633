import { IHotfix } from "../../shared/model/IHotfix";
import { IVersion } from "../../shared/types/IVersion";

export const filterLowerHotfixes = (
  version: IVersion,
  hotfixes: IHotfix[]
): IHotfix[] => {
  const resultList: IHotfix[] = [];

  for (const hotfix of hotfixes) {
    if (hotfix.HOTFIX <= version.HOTFIX) {
      continue;
    }

    resultList.push(hotfix);
  }

  return resultList;
};
