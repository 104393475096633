import { ICarouselProps } from "./ICarouselProps";

import { useState } from "react";

export const useCarouselViewModel = (props: ICarouselProps) => {
  const numberItems = Array.isArray(props.children) ? props.children.length : 1;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const isFirstItem = selectedIndex === 0;

  const isLastItem = selectedIndex === numberItems - 1;

  const onNext = () =>
    setSelectedIndex((previous) => {
      if (previous + 1 < numberItems) {
        previous++;
      }
      return previous;
    });

  const onPrevious = () =>
    setSelectedIndex((previous) => {
      if (previous > 0) {
        previous--;
      }
      return previous;
    });

  const onSelect = (index: number) => setSelectedIndex(index);

  return {
    isFirstItem,
    isLastItem,
    onNext,
    onPrevious,
    onSelect,
    selectedIndex,
  };
};
