import { AuthRole } from "../../auth/types/AuthRole";
import { ABAPBoolean } from "../types/ABAPBoolean";
import { IEntity } from "../types/IEntity";
import { IEntityMeta } from "../types/IEntityMeta";

/**
 * An implementation of this interface represents an user authority role.
 * It contains a user id (or here email) and authority roles which are assigned to a user.
 */
export interface IUserAuthRole extends IEntity {
  EMAIL_ADDRESS: string;
  USER_AUTH_ROLES: AuthRole[];
  INTERNAL_AUTH_ROLES: AuthRole[];
  IS_SYNCHRONIZED: ABAPBoolean;
  IS_DEACTIVATED: ABAPBoolean;
}

export const UserAuthRoleMeta: IEntityMeta<IUserAuthRole> = {
  path: "/user-auth-roles",
};
