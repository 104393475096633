import { BreadcrumbNameLoaderType } from "../../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoaderType";
import { ConflictManagementBreadcrumbNameLoader } from "./ConflictManagementBreadcrumbNameLoader";
import { ContractBreadcrumbNameLoader } from "./ContractBreadcrumbNameLoader";
import { InstallChecklistBreadcrumbNameLoader } from "./InstallChecklistBreadcrumbNameLoader";
import { ProductBreadcrumbNameLoader } from "./ProductBreadcrumbNameLoader";
import { UserRolesBreadcrumbNameLoader } from "./UserRolesBreadcrumbNameLoader";

/**
 * This list is responsible for providing all {@link BreadcrumbNameLoaderType}s
 * to ensure that e.g. entities and their ids can be mapped to a readable format.
 */
export const BreadcrumbNameLoaderTypes: BreadcrumbNameLoaderType[] = [
  ConflictManagementBreadcrumbNameLoader,
  ContractBreadcrumbNameLoader,
  InstallChecklistBreadcrumbNameLoader,
  ProductBreadcrumbNameLoader,
  UserRolesBreadcrumbNameLoader,
];
