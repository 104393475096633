import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { DownloadVersionDialog } from "../downloadVersionDialog/DownloadVersionDialog";
import { ICommand } from "../../../types/ICommand";
import { IDownloadVersion } from "../../../shared/model/IDownloadVersion";
import { IHotfix } from "../../../shared/model/IHotfix";
import { IRelease } from "../../../shared/model/IRelease";
import { IUseSetSystemLandscapeUpgradeVersionCommandProps } from "./IUseSetSystemLandscapeUpgradeVersionCommandProps";
import { IVersion } from "../../../shared/types/IVersion";
import { Link } from "react-router-dom";
import { ReleaseFilter } from "../../../types/ReleaseFilter";
import { SystemLandscapeApi } from "../../../api/SystemLandscapeApi";
import { filterLowerReleases } from "../../../utils/version/filterLowerReleases";
import { texts } from "../../../i18n/texts";
import { useConfig } from "../../../hooks/useConfig";
import { useRequest } from "../../../hooks/useRequest";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";

/**
 * This command handles the selection of an alternative download version for the system landscape,
 * which can be upgraded (e.g. if a newer version is available).
 */
export const useSetSystemLandscapeUpgradeVersionCommand = (
  props: IUseSetSystemLandscapeUpgradeVersionCommandProps
): ICommand => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const request = useRequest();
  const config = useConfig();

  const onCancel = () => {
    setShow(false);
  };

  const onOkay = async (downloadVersion: IDownloadVersion) => {
    await request.send(async () => {
      props.systemLandscape.RELEASE = downloadVersion.RELEASE;
      props.systemLandscape.SERVICE_PACK = downloadVersion.SERVICE_PACK;
      props.systemLandscape.HOTFIX = downloadVersion.HOTFIX;
      props.systemLandscape.IS_LTS = downloadVersion.IS_LTS;
      await SystemLandscapeApi.setSystemsDownloadVersion(downloadVersion);
      await SystemLandscapeApi.update(props.systemLandscape);
      await SystemLandscapeApi.updateSystemActionLog(downloadVersion);
      setShow(false);
      props.onOkay?.();
    });
  };

  const createHotfix = (): IHotfix | undefined =>
    props.hotfix
      ? {
          HOTFIX: props.hotfix,
        }
      : undefined;

  const filterReleases: ReleaseFilter = (releases: IRelease[]): IRelease[] => {
    if (!props.release || !props.isLTS || !props.servicePack || !props.hotfix) {
      return releases;
    }

    const version: IVersion = {
      RELEASE: props.release,
      IS_LTS: props.isLTS,
      IS_PRE_RELEASE: ABAPBoolean.false,
      SERVICE_PACK: props.servicePack,
      HOTFIX: props.hotfix,
    };

    return filterLowerReleases(version, releases);
  };

  const view = show && (
    <DownloadVersionDialog
      filterReleases={filterReleases}
      hotfix={createHotfix()}
      isVersionChangeable={props.isVersionChangeable ?? false}
      isOpen={show}
      OBJECT_ID={props.systemLandscape.OBJECT_ID}
      onCancel={onCancel}
      onOkay={onOkay}
      productId={props.productId}
      subTextTranslation={{
        text: texts.setSystemLandscapeUpgradeVersionCommand.subTitle,
        placeholders: {
          supportPortal: (
            <Link to={config.SUPPORT_PORTAL_LINK}>
              {config.SUPPORT_PORTAL_LINK}
            </Link>
          ),
        },
      }}
      title={t(texts.systemSetDownloadVersionCommand.title)}
      reasonPlaceholder={t(texts.systemLandscape.UpgradeVersionReasonPlaceholder)}
    />
  );

  const execute = () => {
    setShow(true);
  };

  return {
    execute,
    view,
  };
};
