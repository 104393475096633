import { AuthActionPool } from "../auth/types/AuthActionPool";
import { AuthConfig } from "../auth/AuthConfig";
import { AuthRole } from "../auth/types/AuthRole";
import { AuthRoleContext } from "../context/AuthRoleContext";
import { AuthRoleProvider } from "../components/authRole/AuthRoleProvider";
import { AuthRoles } from "../auth/types/AuthRoles";
import { IAuth } from "../auth/IAuth";
import { LoadContextError } from "../errors/LoadContextError";

import { checkNotNull } from "../utils/checkNotNull";
import { createAuthChecks } from "../auth/utils/createAuthChecks";
import { useContext } from "react";
import { useMemo } from "react";
import { useSession } from "./useSession";

// eslint-disable-next-line @typescript-eslint/no-unused-vars










/**
 * This custom hook is responsible for providing functions to check for user authorizations.
 * It must be called within a component which is a child of an {@link AuthRoleProvider}.
 * The {@link AuthRoleProvider} is responsible for loading the authorities. The loaded authorities are cached within the {@link AuthRoleContext}.
 *
 * @example
 * const Demo: React.FC = () => {
 *   const auth = useAuth();
 *   return <>{auth.has("ADMIN") ? <>Admin Content</> : <></>}</>;
 * };
 */
export const useAuth = (): IAuth & AuthActionPool => {
  const session = useSession();
  const authRoleContext = useContext(AuthRoleContext);

  checkNotNull(
    authRoleContext,
    new LoadContextError("AuthRoleContext", useAuth.name, AuthRoleProvider.name)
  );

  /**
   * Create authority check functions based on {@link AuthConfig}.
   */
  const authChecks = useMemo(
    () => createAuthChecks(AuthConfig, authRoleContext.authRoles),
    [authRoleContext.authRoles]
  );

  const has = (...authRoles: AuthRoles): boolean => {
    // Return false if user is not logged in
    if (!session) {
      return false;
    }

    // Return true if no authority should be checked
    if (!authRoles || authRoles.length === 0) {
      return true;
    }

    // Check if user has all required authorities
    for (const authRole of authRoles) {
      if (!authRoleContext.authRoles.includes(authRole)) {
        return false;
      }
    }

    return true;
  };

  const isAdmin = (): boolean => {
    return has(AuthRole.ADMIN);
  };

  const isSupport = (): boolean => {
    return has(AuthRole.SUPPORT);
  };

  const isTester = (): boolean => {
    return has(AuthRole.TESTER);
  };

  const isGlobalUser = (): boolean => {
    return has(AuthRole.SUPPORT) || has(AuthRole.ADMIN) || has(AuthRole.PRINCIPAL);
  };

  const isSuperUser = (): boolean => {
    return has(AuthRole.SUPPORT) || has(AuthRole.ADMIN);
  };

  /*start backdoor */
  const isContractOwner = (): boolean => {
    return has(AuthRole.CONTRACT_OWNER);
  };
  /*end backdoor */

  return { ...authChecks, has, isAdmin, isSupport, isTester, isGlobalUser, isSuperUser/*start backdoor*/, isContractOwner  /*end backdoor */
  };
};
