import { ReactComponent as GermanFlag } from "../../assets/flags/DE.svg";
import { IFlagProps } from "./IFlagProps";
import { ReactComponent as JapanFlag } from "../../assets/flags/JP.svg";
import { LanguageType } from "../../types/LanguageType";
import { ReactElement } from "react";
import { ReactComponent as USFlag } from "../../assets/flags/US.svg";

export const Flag: React.FC<IFlagProps> = (props) => {
  const getFlagByLanguageType = (languageType: LanguageType): ReactElement => {
    switch (languageType) {
      case LanguageType.DE:
        return <GermanFlag />;
      case LanguageType.EN:
        return <USFlag />;
      case LanguageType.JA:
        return <JapanFlag />;
      default:
        throw new Error(
          `Flag for LanguageType ${languageType} is missing. Please add it.`
        );
    }
  };
  return getFlagByLanguageType(props.languageType);
};
