import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";

import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { DocType } from "../../../shared/types/DocType";
import { IMenuItem } from "./IMenuItem";
import { IMessage } from "../../../model/message/IMessage";
import { ISystem } from "../../../shared/model/ISystem";
import { ISystemCardProps } from "./ISystemCardProps";
import { ISystemDoc } from "../../../shared/types/ISystemDoc";
import { ISystemDocLink } from "../../../shared/types/ISystemDocLink";
import { ISystemRequirement } from "../../../shared/model/ISystemRequirement";
import { ISystemRequirementType } from "../../../shared/model/ISystemRequirementType";
import { ISystemRole } from "../../../shared/model/ISystemRole";
import { IllegalStateError } from "../../../errors/IllegalStateError";
import { LanguageMenuItem } from "../../languageMenuItem/LanguageMenuItem";
import { LoadingSpinnerButton } from "../../../components/loadingSpinnerButton/LoadingSpinnerButton";
import { Message } from "../../../services/Message";
import { MissingDocuLinkError } from "../../../api/errors/MissingDocuLinkError";
import { RESTError } from "../../../api/core/RESTError";
import { Routes } from "../../../routes/Routes";
import { SprasToLanguageTypeMapper } from "../../../shared/utils/SprasToLanguageTypeMapper";
import { SystemApi } from "../../../api/SystemApi";
import { SystemDummy } from "../../../model/SystemDummy";
import { SystemDuplicateError } from "../../../api/errors/SystemDuplicateError";
import { SystemRequirementTypeInfo } from "../../../services/SystemRequirementTypeInfo";
import { SystemRequirementsOutOfSyncError } from "../../../api/errors/SystemRequirementsOutOfSyncError";
import { SystemRoleInfo } from "../../../services/SystemRoleInfo";
import { ValidationError } from "../../../errors/ValidationError";
import { checkNotNull } from "../../../utils/checkNotNull";
import { openLinkInTab } from "../../../utils/openLinkInTab";
import styles from "./SystemCard.module.scss";
import { texts } from "../../../i18n/texts";
import { useConfig } from "../../../hooks/useConfig";
import { useDeactivateSystemCommand } from "../systemCommands/useDeactivateSystemCommand";
import { useProductInfo } from "../../../hooks/useProductInfo";
import { useRequest } from "../../../hooks/useRequest";
import useTranslation from "../../../hooks/useTranslation";

/**
 * This view model handles states for system card.
 */
export const useSystemCardViewModel = (props: ISystemCardProps) => {
  const [system, setSystem] = useState(props.system);
  const [number] = useState(props.number);
  const [editMode, setEditMode] = useState(false);
  const [inputFieldErrorSid, setInputFieldErrorSid] = useState("");
  const [inputFieldErrorClient, setInputFieldErrorClient] = useState("");
  const [inputFieldErrorInstallNumber, setInputFieldErrorInstallNumber] =
    useState("");
  const [inputFieldErrorDescription, setInputFieldErrorDescription] =
    useState("");
  const { t } = useTranslation();
  const request = useRequest();
  const [message, setMessage] = useState<IMessage | undefined>(undefined);
  const systemDeactivateCommand = useDeactivateSystemCommand({
    system,
    setSystem,
  });
  const [isActivating, setIsActivating] = useState(false);
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoadingDocu, setIsLoadingDocu] = useState(false);
  const productInfo = useProductInfo();
  const config = useConfig();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [installationHelpMenuEntries, setInstallationHelpMenuEntries] =
    useState<IMenuItem[]>([]);
  const onActivate = useCallback(async () => {
    setIsActivating(true);
    await request.send(async () => {
      const updatedSystem = clone(system);
      updatedSystem.IS_DEACTIVATED = ABAPBoolean.false;
      const changedSystem = await SystemApi.update(updatedSystem);
      updatedSystem.CONFLICTS = changedSystem.CONFLICTS;
      setSystem(updatedSystem);
    });
    setIsActivating(false);
  }, [request, system]);

  const product = useMemo(() => {
    const products = checkNotNull(props.contract.PRODUCTS);
    for (const product of products) {
      if (product.OBJECT_ID === props.product.OBJECT_ID) {
        return product;
      }
    }

    throw new IllegalStateError(
      `Error when getting product. Displayed product must be part of the contract.`
    );
  }, [props.contract.PRODUCTS, props.product.OBJECT_ID]);
  const isLicenseActive = productInfo.isLicenseActive(product);

  /**
   * Path to the conflict management page
   */
  const conflictManagementPath = Routes.systemConflictManagement.toPath({
    contractId: props.contract.OBJECT_ID,
    productId: props.product.OBJECT_ID,
    systemId: props.system.OBJECT_ID,
  });

  /**
   * Path to install guide page
   */
  const installChecklistPath = Routes.systemInstallChecklist.toPath({
    contractId: props.contract.OBJECT_ID,
    productId: props.product.OBJECT_ID,
    systemId: props.system.OBJECT_ID,
  });

  const messageSystemConflict: IMessage = useMemo(
    () => ({
      severity: "error",
      text: t(texts.systemCard.errorSystemConflict, {
        here: (
          <>
            <Link to={conflictManagementPath}>{t(texts.systemCard.here)}</Link>
          </>
        ),
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conflictManagementPath, navigate]
  );

  // const messageProductExpired: IMessage = useMemo(
  //   () => ({
  //     severity: "error",
  //     text: t(texts.systemCard.errorProductExpired),
  //   }),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  const messageSystemRoleSelectionInvalid: IMessage = useMemo(
    () => ({
      severity: "error",
      text: t(texts.systemCard.errorSystemRoleSelectionInvalid),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const messageSystemRequirementSelectionInvalid: IMessage = useMemo(
    () => ({
      severity: "error",
      text: t(texts.systemCard.errorSystemRequirementSelectionInvalid),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const messageSystemVersionInconsistent: IMessage = useMemo(
    () => ({
      severity: "error",
      text: t(texts.systemCard.errorSystemVersionInconsistent, {
        supportPortal: (
          <Link to={config.SUPPORT_PORTAL_LINK} target="_blank">
            {config.SUPPORT_PORTAL_LINK}
          </Link>
        ),
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isSystemRoleSelectionInvalid = useCallback((): boolean => {
    return props.system.SYSTEM_ROLES.length === 0;
  }, [props.system.SYSTEM_ROLES]);

  /**
   * Returns if the selection for the systemRequirementTypes is valid.
   * This means that at least one system requirement is selected for each system requirement type.
   */
  const isSystemRequirementSelectionInvalid = useCallback((): boolean => {
    const isSelectionValid = SystemRequirementTypeInfo.isSelectionValid(
      props.systemRequirementTypes,
      props.system.SYSTEM_REQMTS
    );
    return !isSelectionValid;
  }, [props.system.SYSTEM_REQMTS, props.systemRequirementTypes]);

  useEffect(() => {
    if (props.collapseSignal) {
      if (!editMode) {
        setIsCollapsed(true);
      }
    }
  }, [props.collapseSignal, editMode]);

  useEffect(() => {
    if (props.expandSignal) {
      setIsCollapsed(false);
    }
  }, [props.expandSignal]);


  useEffect(() => {
    if (system.IS_DEACTIVATED === ABAPBoolean.true) {
      setMessage({
        severity: "info",
        text: t(texts.systemCard.textMarkAsUnused, {
          setToInUse: (
            <span className={styles.messageButton}>
              <LoadingSpinnerButton
                onClick={onActivate}
                showSpinner={isActivating}
                text={t(texts.systemCard.setToInUse)}
              />
            </span>
          ),
        }),
      });
    } else if (system.CONFLICTS.length > 0) {
      setMessage(messageSystemConflict);
    } else if (props.containsSystemVersionInconsistency) {
      setMessage(messageSystemVersionInconsistent);
    } else if (editMode === false && isSystemRoleSelectionInvalid()) {
      setMessage(messageSystemRoleSelectionInvalid);
    } else if (editMode === false && isSystemRequirementSelectionInvalid()) {
      setMessage(messageSystemRequirementSelectionInvalid);
    } else {
      setMessage(undefined);
    }
  }, [
    props.containsSystemVersionInconsistency,
    editMode,
    isActivating,
    isSystemRequirementSelectionInvalid,
    productInfo,
    messageSystemConflict,
    messageSystemRequirementSelectionInvalid,
    messageSystemVersionInconsistent,
    product,
    props.contract,
    onActivate,
    system.CONFLICTS.length,
    system.IS_DEACTIVATED,
    t,
    isSystemRoleSelectionInvalid,
    messageSystemRoleSelectionInvalid,
  ]);

  const checkLength = (
    attribute: string,
    actualLength: number,
    expectedLength: number,
    setErrorTextFunction: Dispatch<SetStateAction<string>>
  ) => {
    if (actualLength < expectedLength) {
      const errorMessage = t(texts.systemCard.errorLength, {
        attribute: attribute,
        length: expectedLength,
      });
      setErrorTextFunction(errorMessage);
      throw new ValidationError(errorMessage);
    }
  };

  /**
   * Creates a deep clone copy of that object, that also clones arrays and objects which are assigned as properties
   */
  const clone = (system: ISystem): ISystem => {
    return SystemDummy.clone(system);
  };

  const hasSystemConflicts = system.CONFLICTS.length > 0;

  const isSystemDeactivated = system.IS_DEACTIVATED === ABAPBoolean.true;

  const onRestore = async () => {
    setInputFieldErrorSid("");
    setInputFieldErrorClient("");
    setInputFieldErrorInstallNumber("");
    setInputFieldErrorDescription("");
    return true;
  };

  const onValidate = () => {
    checkLength(
      t(texts.systemHeaderFields.sid),
      system.SYSTEM_ID.length,
      3,
      setInputFieldErrorSid
    );
    checkLength(
      t(texts.systemHeaderFields.client),
      system.SYSTEM_CLIENT.length,
      3,
      setInputFieldErrorClient
    );
    checkLength(
      t(texts.systemHeaderFields.installNumber),
      system.INSTNUMBER.toString().length,
      10,
      setInputFieldErrorInstallNumber
    );
  };

  const onSetSid = (sid: string) => {
    setInputFieldErrorSid("");
    setSystem((previous) => {
      const updatedSystem = clone(previous);
      updatedSystem.SYSTEM_ID = sid.toUpperCase();
      return updatedSystem;
    });
  };

  const onSetClient = (client: string) => {
    setInputFieldErrorClient("");
    setSystem((previous) => {
      const updatedSystem = clone(previous);
      updatedSystem.SYSTEM_CLIENT = client;
      return updatedSystem;
    });
  };

  const onSetInstallNumber = (installNumber: string) => {
    setInputFieldErrorInstallNumber("");
    setSystem((previous) => {
      const updatedSystem = clone(previous);
      updatedSystem.INSTNUMBER = installNumber;
      return updatedSystem;
    });
  };

  const onSetDescription = (description: string) => {
    setInputFieldErrorDescription("");
    setSystem((previous) => {
      const updatedSystem = clone(previous);
      updatedSystem.DESCRIPTION = description;
      return updatedSystem;
    });
  };

  const onDelete = async () => {
    return props.onDelete?.(props.system) ?? true;
  };

  const onSave = async () => {
    return (
      (await props.onSave?.(system, (error) => {
        if (error instanceof SystemDuplicateError) {
          Message.error(
            t(texts.systemCard.errorSystemDuplicate, {
              SID: system.SYSTEM_ID,
              client: system.SYSTEM_CLIENT,
              installNumber: system.INSTNUMBER,
            })
          );
          return true;
        }

        if (error instanceof SystemRequirementsOutOfSyncError) {
          const systemRequirementDescriptions =
            SystemRequirementTypeInfo.toDescription(
              props.systemRequirementTypes,
              error.responseEnvelope.DATA.SYSTEM_REQMTS
            );

          const systemRequirements =
            systemRequirementDescriptions.join(";") ?? "";

          Message.error(
            t(texts.systemCard.errorSystemRequirementsOutOfSync, {
              systemRequirements,
            })
          );
          return true;
        }
        return false;
      })) ?? true
    );
  };

  const onCopy = async () => {
    return props.onCopy?.(props.system) ?? true;
  };

  const onDownloadSoftware = async () => {
    // After Downloading a system, the download history must be refreshed, to directly show the downloaded version
    setIsDownloading(true);
    await props.onDownloadSoftware?.(props.system);
    await onReloadDownloadHistory(props.system);
    setIsDownloading(false);
  };

  const onDownloadLicense = async () => {
    // display message if underlying product is not licensable
    if (props.product.IS_LICENSABLE === ABAPBoolean.false) {
      Message.error(
        t(texts.systemCard.errorProductNotLicensable, {
          supportPortal: config.SUPPORT_PORTAL_LINK,
        })
      );
      return;
    }
    setIsDownloading(true);
    await props.onDownloadLicense?.(props.system);
    setIsDownloading(false);
  };

  const onDownloadEmergencyLicense = async (reason: string) => {
    if (props.product.IS_LICENSABLE === ABAPBoolean.false) {
      Message.error(
        t(texts.systemCard.errorProductNotLicensable, {
          supportPortal: config.SUPPORT_PORTAL_LINK,
        })
      );
      return;
    }
    setIsDownloading(true);
    await props.onDownloadEmergencyLicense?.(props.system, reason);
    setIsDownloading(false);
  };

  const onReloadDownloadHistory = async (system: ISystem) => {
    await request.send(async () => {
      const downloadHistory = await SystemApi.findDownloadHistory(
        system.OBJECT_ID
      );
      setSystem((previous) => {
        const updatedSystem = clone(previous);
        updatedSystem.DOWNLOAD_HISTORY = downloadHistory;
        return updatedSystem;
      });
    });
  };

  const onSystemRoleActivated = (systemRole: ISystemRole) => {
    setSystem((previous) => {
      const updatedSystem = clone(previous);
      updatedSystem.SYSTEM_ROLES.push(systemRole.SYSTEM_ROLE_ID);
      return updatedSystem;
    });
  };

  const onSystemRolesUpdate = (systemRoles: ISystemRole[]) => {
    const updatedRoles: string[] = []
    systemRoles.map((systemRole) => {
      updatedRoles.push(systemRole.SYSTEM_ROLE_ID);
  })
    setSystem((previous) => {
      const updatedSystem = clone(previous);
      updatedSystem.SYSTEM_ROLES=updatedRoles;
      return updatedSystem;
    });
  };
  

  const onSystemRoleDeactivated = (systemRole: ISystemRole) => {
    setSystem((previous) => {
      const index = previous.SYSTEM_ROLES.findIndex(
        (systemRoleName) => systemRoleName === systemRole.SYSTEM_ROLE_ID
      );
      if (index !== -1) {
        const updatedSystem = clone(previous);
        updatedSystem.SYSTEM_ROLES.splice(index, 1);
        return updatedSystem;
      }
      return previous;
    });
  };

  const onSystemRequirementChanged = (
    updatedArray: string[]
  ) => {
    setSystem((previous) => {
      const updatedSystem = clone(previous);
      updatedSystem.SYSTEM_REQMTS = updatedArray;
      return updatedSystem;
    });
  };

  const onDisplayInstallChecklist = () => {
    navigate(installChecklistPath);
  };

  const buildLanguageMenu = (docLinks: ISystemDocLink[]): IMenuItem[] => {
    return docLinks.map((docLink) => ({
      onClick: () => openLinkInTab(docLink.DOC_LINK),
      text: (
        <LanguageMenuItem
          languageType={SprasToLanguageTypeMapper(docLink.LANGUAGE)}
        />
      ),
    }));
  };

  const onLoadInstallationHelpMenu = async () => {
    setIsLoadingDocu(true);
    const menuItems: IMenuItem[] = [];
    menuItems.push({
      onClick: onDisplayInstallChecklist,
      text: t(texts.systemCard.openInstallationChecklistButton),
    });
    setInstallationHelpMenuEntries(menuItems);

    await request.send(
      async () => {
        const systemDocs: ISystemDoc[] = await SystemApi.findUserDocs(
          props.system.OBJECT_ID
        );
        setIsLoadingDocu(false);
        systemDocs.forEach((systemDoc) => {
          if (systemDoc.DOC_LINKS.length === 0) {
            return;
          }
          switch (systemDoc.DOC_TYPE) {
            case DocType.INST_GUIDE:
              menuItems.push({
                onClick: () => {},
                text: t(texts.systemCard.openInstallationGuideButton),
                children: buildLanguageMenu(systemDoc.DOC_LINKS),
              });
              break;
            case DocType.USER_GUIDE:
              menuItems.push({
                onClick: () => {},
                text: t(texts.systemCard.openProductUserGuideButton),
                children: buildLanguageMenu(systemDoc.DOC_LINKS),
              });
              break;
            default:
              throw new Error(
                `Document type ${systemDoc.DOC_TYPE} is not supported. Please add it.`
              );
          }
        });
        setInstallationHelpMenuEntries(menuItems);
      },
      (error: RESTError) => {
        setIsLoadingDocu(false);
        if (error instanceof MissingDocuLinkError) {
          Message.error(
            t(texts.systemCard.errorMissingDocuLink, {
              supportPortal: config.SUPPORT_PORTAL_LINK,
            })
          );
          return true;
        } else {
          return false;
        }
      }
    );
  };

  const isExpired = (): boolean => {
    return productInfo.isExpired(product);
  };

  const isAboutToExpire = (): boolean => {
    return productInfo.isAboutToExpire(product);
  };

  const isEmergencyLicenseRequestable = (): boolean => {
    if (productInfo.getEmergencyAccessEndDate(product) !== null) {
      if (
        productInfo.getEmergencyAccessEndDate(product)! >
        productInfo.getLongestEndDate(product)
      ) {
        return (
          productInfo.isEmergencyAboutToExpire(product) || !isLicenseActive
        );
      }
    }
    return productInfo.isAboutToExpire(product) || !isLicenseActive;
  };

  /**
   * Returns if a license for the system is required,
   * which depends on if an assigned system role is selected which needs a license.
   */
  const isLicenseRequired = (): boolean =>
    SystemRoleInfo.isLicenseRequired(
      props.systemRoles,
      props.system.SYSTEM_ROLES
    );

  /**
   * Returns the calculated (in frontend!) expiration date of the emergency license.
   */
  const getNewEmergencyExpirationDate = (): Date => {
    return productInfo.getNewEmergencyExpirationDate(product);
  };

  const isSystemRoleSelected = (systemRole: ISystemRole): boolean => {
    return props.system.SYSTEM_ROLES.includes(systemRole.SYSTEM_ROLE_ID);
  };

  const isSystemRequirementSelected = (
    systemRequirement: ISystemRequirement | undefined
  ): boolean => {
    if (!systemRequirement) {
      return false;
    }
    return props.system.SYSTEM_REQMTS.includes(
      systemRequirement.SYSTEM_REQUIREMENT_ID
    );
  };

  const hasSelectedSystemRequirement = (
    systemRequirementType: ISystemRequirementType
  ): boolean => {
    for (const systemRequirement of systemRequirementType.SYSTEM_REQUIREMENTS) {
      if (isSystemRequirementSelected(systemRequirement)) {
        return true;
      }
    }
    return false;
  };

  const toggleIsCollapsed = () => {
    setIsCollapsed((previous) => {
      if (previous) {
        if (props.onExpandSingleSystem) {
          props.onExpandSingleSystem();
        }
      } else if (props.onCollapseSingleSystem) {
        props.onCollapseSingleSystem();
      }
      return !previous;
    });
  };

  const displayConflicts = system.CONFLICTS.length > 0;
  const needsCollapse = SystemDummy.isPersistent(props.system) && isCollapsed;

  return {
    conflictManagementPath,
    displayConflicts,
    editMode,
    hasSelectedSystemRequirement,
    hasSystemConflicts,
    inputFieldErrorSid,
    inputFieldErrorClient,
    inputFieldErrorInstallNumber,
    inputFieldErrorDescription,
    installationHelpMenuEntries,
    isDownloading,
    isLoadingDocu,
    isLicenseActive,
    isLicenseRequired,
    isExpired,
    isAboutToExpire,
    isEmergencyLicenseRequestable,
    getNewEmergencyExpirationDate,
    isSystemDeactivated,
    isSystemRequirementSelected,
    isSystemRequirementSelectionInvalid,
    isSystemRoleSelected,
    isSystemRoleSelectionInvalid,
    systemDeactivateCommand,
    message,
    needsCollapse,
    onCopy,
    onDelete,
    onLoadInstallationHelpMenu,
    onRestore,
    onDownloadSoftware,
    onDownloadLicense,
    onDownloadEmergencyLicense,
    onSave,
    onSetClient,
    onSetInstallNumber,
    onSetDescription,
    onSetSid,
    onSystemRoleActivated,
    onSystemRoleDeactivated,
    onSystemRolesUpdate,
    onSystemRequirementChanged,
    onValidate,
    setEditMode,
    setSystem,
    system,
    number,
    toggleIsCollapsed,
  };
};
