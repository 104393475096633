import { Button } from "@snpjs/core";
import { IConflictListItemProps } from "./IConflictListItemProps";
import { Routes } from "../../../../routes/Routes";
import { openLinkInTab } from "../../../../utils/openLinkInTab";
import { texts } from "../../../../i18n/texts";
import { useRenderABAPBoolean } from "../../../../hooks/useRenderABAPBoolean";
import useTranslation from "../../../../hooks/useTranslation";
import styles from "./ConflictListItem.module.scss";
import { useAuth } from "../../../../hooks/useAuth";

export const ConflictListItem: React.FC<IConflictListItemProps> = (props) => {
  const { t } = useTranslation();
  const renderABAPBoolean = useRenderABAPBoolean();
  const auth = useAuth();

  return (
    <>
      <div>{props.conflict.PROJECT_ID}</div>
      <div>{props.conflict.SYSTEM_ID}</div>
      <div>{props.conflict.SYSTEM_CLIENT}</div>
      <div>{props.conflict.INSTNUMBER}</div>
      <div>{props.conflict.RELEASE}</div>
      <div>{renderABAPBoolean(props.conflict.IS_LTS)}</div>
      <div>{props.conflict.SERVICE_PACK}</div>
      <div>{props.conflict.HOTFIX}</div>
      {auth.isSuperUser() ? props.conflict.DESCRIPTION : t(texts.systemCard.conflictMessageForUser)}
      <div className={styles.conflictListButton}>
        <Button
          icon="folder-open"
          onClick={() => {
            const link = Routes.contractProduct.toPath({
              contractId: props.conflict.CONTRACT_OBJECT_ID,
              productId: props.conflict.PRODUCT_OBJECT_ID,
            });
            openLinkInTab(link);
          }}
        >
          {t(texts.general.openContract)}
        </Button>
      </div>
    </>
  );
};
