import { Callout } from "@snpjs/core";
import { IMessageAreaProps } from "./IMessageAreaProps";
import { NotImplementedError } from "../../errors/NotImplementedError";
import styles from "./MessageArea.module.scss";

// import { NotImplementedError } from "../../errors/NotImplementedError";

export const MessageArea: React.FC<IMessageAreaProps> = (props) => {
  const getIntent = () => {
    if (props.message.severity) {
      switch (props.message.severity) {
        case "error": {
          return "danger";
        }
        case "none": {
          return "none";
        }
        case "info": {
          return "primary";
        }
        case "success": {
          return "success";
        }
        case "warning": {
          return "warning";
        }
        default: {
          throw new NotImplementedError();
        }
      }
    }
  };

  return (
    <div className={styles.messageArea}>
      <Callout
        icon={props.message.icon ? props.message.icon : "warning"}
        title={props.message.text}
        intent={getIntent()}
        compact
      ></Callout>
    </div>
  );
};
