import { BreadcrumbLoader } from "../../../../decorators/BreadcrumbLoader";
import { BreadcrumbNameLoader } from "../../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoader";
import { Routes } from "../../../../routes/Routes";

import { texts } from "../../../../i18n/texts";

@BreadcrumbLoader(Routes.excerptInstallGuide.toPath())
export class InstallChecklistBreadcrumbNameLoader extends BreadcrumbNameLoader {
  name = texts.installChecklist.breadcrumb;
}
