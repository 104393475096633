import { Icon, Popover } from "@snpjs/core";

import { ISystemDetailsOverviewPopoverProps } from "./ISystemDetailsOverviewPopoverProps";
import { SystemDetailsOverview } from "../systemDetailsOverview/SystemDetailsOverview";
import { style } from "../../../utils/style";
import styles from "./SystemDetailsOverviewPopover.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";

export const SystemDetailsOverviewPopover: React.FC<
  ISystemDetailsOverviewPopoverProps
> = (props) => {
  const { t } = useTranslation();

  return (
    <Popover
      className={style(styles.systemDetailsOverviewPopover, props.className)}
      content={
        <div className={styles.systemDetailsOverview}>
          <h3>{t(texts.systemDetailsOverview.title)}</h3>
          <SystemDetailsOverview systemObjectId={props.systemObjectId} />
        </div>
      }
      placement="bottom-start"
    >
      <Icon icon="info" />
    </Popover>
  );
};
