import { AppContext } from "../context/AppContext";
import { IRecentContracts } from "../model/IRecentContracts";

import { useContext } from "react";

/**
 * Adds the given {@link item} to {@link list} at index 1.
 * Removes {@link item} if it already exists and returns the first {@link numberItems}.
 */
const addToList = <T>(list: T[], item: T, numberItems: number = 2): T[] => {
  // remove existing entry if exists
  const index = list.findIndex((element) => element === item);
  if (index !== -1) {
    list.splice(index, 1);
  }

  // add entry at first position and only return first 2 entries
  return [item, ...list.slice(0, numberItems)];
};

/**
 * This custom hook is responsible for providing access to the recently opened contracts.
 */
export const useRecentContracts = (): {
  value: IRecentContracts;
  add: (contractId: string) => void;
} => {
  const context = useContext(AppContext);

  const add = (contractId: string) => {
    const updatedList = addToList(
      context.recentContracts.value.contractIds,
      contractId
    );
    context.recentContracts.setValue({ contractIds: updatedList });
  };

  return { value: context.recentContracts.value, add };
};
