import { IHavePath } from "../types/IHavePath";
import { IProductVersion } from "../types/IProductVersion";

export interface ISystemIntendedVersion {
  LAST_PRODUCT_VERSION: IProductVersion;
  INTENDED_VERSION: IProductVersion;
}

export const SystemIntendedVersionMeta: IHavePath = {
  path: "/intended-version",
};
