import { ContractSearchFilter } from "../contractSearchFilter/ContractSearchFilter";
import { ContractSearchInput } from "../contractSearchInput/ContractSearchInput";
import { IContractSearchProps } from "./IContractSearchProps";
import { LoadingSpinnerButton } from "../../../../components/loadingSpinnerButton/LoadingSpinnerButton";
import styles from "./ContractSearch.module.scss";
import { texts } from "../../../../i18n/texts";
import { useContractSearchViewModel } from "./useContractSearchViewModel";
import useTranslation from "../../../../hooks/useTranslation";

export const ContractSearch: React.FC<IContractSearchProps> = (props) => {
  const viewModel = useContractSearchViewModel(props);
  const { t } = useTranslation();

  return (
    <div className={styles.contractSearch}>
      <div className={styles.input}>
        <ContractSearchInput
          onEnter={viewModel.onSearch}
          query={viewModel.query}
          setQuery={viewModel.setQuery}
        />
        <ContractSearchFilter
          onEnter={viewModel.onSearch}
          onSystemFilterPropsChange={viewModel.onSystemFilterPropsChange}
        />
      </div>
      <div className={styles.buttonContainer}>
        <LoadingSpinnerButton
          className={styles.searchButton}
          buttonClassNames={styles.searchButton}
          icon="magnifying-glass"
          text={t(texts.search.search)}
          onClick={viewModel.onSearch}
          outlined
          showSpinner={viewModel.isSearching}
        />
      </div>
    </div>
  );
};
