import { ISingleTagsProps } from "./ISingleTagsProps";
import { Tag } from "@snpjs/core";
import styles from "./SingleTags.module.scss";

export function SingleTags<T>(props: ISingleTagsProps<T>) {
  const items = props.objects.map((object, index) => {
    const name = props.getName(object);
    return (
      <Tag key={index} fill={true} intent="primary">
        {name}
      </Tag>
    );
  });

  return (
    <>
      <div className={styles.gridContainer}>{items}</div>
    </>
  );
}
