import { ABAPBoolean } from "../../../shared/types/ABAPBoolean";
import { Detail } from "../../../components/detail/Detail";
import { IServicePackVersionDetailsProps } from "./IServicePackVersionDetailsProps";
import { releaseToVersion } from "../../../utils/version/releaseToVersion";
import styles from "./ServicePackVersionDetail.module.scss";
import { texts } from "../../../i18n/texts";
import { useAuth } from "../../../hooks/useAuth";
import { useRenderABAPBoolean } from "../../../hooks/useRenderABAPBoolean";
import useTranslation from "../../../hooks/useTranslation";

export const ServicePackVersionDetails: React.FC<
  IServicePackVersionDetailsProps
> = (props) => {
  const { t } = useTranslation();
  const renderABAPBoolean = useRenderABAPBoolean();
  const auth = useAuth();
  return (
    <>
      {props.servicePackVersion.RELEASE.toString() !== "0000" ? (     
        <>
          <Detail
            title={t(texts.general.system.release)}
            value={releaseToVersion(props.servicePackVersion.RELEASE)}
          />
          <Detail
          title={t(texts.general.system.isLTS)}
          value={renderABAPBoolean(props.servicePackVersion.IS_LTS)}
          />
          {auth.isTester() && (
            <Detail
              className={
                props.servicePackVersion.IS_PRE_RELEASE === ABAPBoolean.true
                  ? styles.isPreRelease
                  : ""
              }
              title={t(texts.general.system.isPreRelease)}
              value={renderABAPBoolean(props.servicePackVersion.IS_PRE_RELEASE)}
            />
          )}
          <Detail
            title={t(texts.general.system.servicePack)}
            value={props.servicePackVersion.SERVICE_PACK}
          />
          <Detail
            title={t(texts.general.system.hotfix)}
            value={props.servicePackVersion.HOTFIX}
          />
        </>
      ) : (
        <div>{t(texts.systemLandscapeHeader.UndefinedVersionHeaderText)}</div>
        )}
    </>
  );
};
