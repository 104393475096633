import { AsyncLoad } from "../asyncLoad/AsyncLoad";
import { Button } from "@snpjs/core";
import { EditableList } from "../editableList/EditableList";
import { IEditableSectionProps } from "./IEditableSectionProps";
import { IEntity } from "../../shared/types/IEntity";
import { style } from "../../utils/style";
import styles from "./EditableSection.module.scss";
import { texts } from "../../i18n/texts";
import { useEditableSectionViewModel } from "./useEditableSectionViewModel";
import { useRequest } from "../../hooks/useRequest";
import useTranslation from "../../hooks/useTranslation";

/**
 * This component is responsible for displaying objects of type {@link T} in a list.
 * The objects are displayed in cards. The objects are editable in these cards.
 */
export function EditableSection<T extends IEntity>(
  props: IEditableSectionProps<T>
) {
  const viewModel = useEditableSectionViewModel<T>(props);
  const { t } = useTranslation();
  const request = useRequest();

  //add the add button in the top if there are more than 4 entries underneath each other
  const needsToolbarInTop =
    viewModel.filterObjects().length / (props.numberOfColumns ?? 1) >= 4;

  const addButtonSpacingNeeded = viewModel.filterObjects().length > 0;

  const toolbarContent = (
    <div
      className={style(
        styles.toolbar,
        addButtonSpacingNeeded ? styles.addButtonSpacing : ""
      )}
    >
      <Button
        disabled={!viewModel.canAdd()}
        icon="plus"
        onClick={viewModel.onAdd}
        text={props.labelAddButton ?? t(texts.editableSection.addObject)}
      />
      {props.toolbarContent && props.toolbarContent}
    </div>
  );

  return (
    <AsyncLoad
      load={async () => {
        await request.send(async () => {
          const objects = await props.loadObjects();
          viewModel.setObjects(objects);
        });
      }}
    >
      <div>
        {needsToolbarInTop && toolbarContent}
        <EditableList
          createCard={props.createCard}
          numberOfColumns={props.numberOfColumns}
          onCopy={viewModel.onCopy}
          onDelete={viewModel.onDelete}
          onSave={viewModel.onSave}
          onUpdate={viewModel.onUpdate}
          objects={viewModel.filterObjects()}
          number={0}
        />
        {toolbarContent}
      </div>
    </AsyncLoad>
  );
}
