import { IResponseEnvelope } from "../../shared/types/IResponseEnvelope";
import { IResponseEnvelopeFactory } from "./IResponseEnvelopeFactory";
import { ResponseEnvelopeType } from "../../shared/types/ResponseEnvelopeType";

export class ResponseEnvelopeFactory implements IResponseEnvelopeFactory {
  createFromReason<T>(reason: string): IResponseEnvelope<T> {
    if (reason.length === 0) {
      return this.createEmpty();
    } else {
      return this.create(reason);
    }
  }

  private createEmpty<T>(): IResponseEnvelope<T> {
    return {
      DATA: {},
      MESSAGE: "",
      TYPE: ResponseEnvelopeType.INFO,
    } as IResponseEnvelope<T>;
  }

  private create<T>(reason: string): IResponseEnvelope<T> {
    const responseEnvelope: IResponseEnvelope<T> = JSON.parse(reason);
    responseEnvelope.DATA = JSON.parse(responseEnvelope.DATA as string);
    return responseEnvelope;
  }
}
