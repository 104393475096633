import {
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Section,
  SectionCard,
} from "@snpjs/core";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContext";
import { ILoginProps } from "./ILoginProps";
import { InvalidAccessCodeError } from "../../api/errors/InvalidAccessCodeError";
import { LoadingSpinnerButton } from "../../components/loadingSpinnerButton/LoadingSpinnerButton";
import { MaxLoginFailError } from "../../api/errors/MaxLoginFailError";
import { Message } from "../../services/Message";
import { RESTError } from "../../api/core/RESTError";
import { TokenExpiredError } from "../../api/errors/TokenExpiredError";
import { UserApi } from "../../api/UserApi";
import { UserNotFoundError } from "../../api/errors/UserNotFoundError";
import { isEmailValid } from "../../utils/isEmailValid";
import styles from "./Login.module.scss";
import { texts } from "../../i18n/texts";
import { toBoolean } from "../../utils/toBoolean";
import { useRequest } from "../../hooks/useRequest";
import useTranslation from "../../hooks/useTranslation";

/**
 * This component is responsible for displaying a user login screen.
 */
export const Login: React.FC<ILoginProps> = (props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [requestAccessCode, setRequestAccessCode] = useState(false);
  const [requestLogin, setRequestLogin] = useState(false);
  const context = useContext(AppContext);
  const request = useRequest();
  const [contract, setContract] = useState("");
  const [product, setProduct] = useState("");

  const handleRequestAccessCodeError = (error: RESTError) => {
    switch (error.constructor) {
      case UserNotFoundError: {
        Message.error(t(texts.login.errorUserNotFoundForAccessCode));
        return true;
      }
      default:
        return false;
    }
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const emailParam = queryParameters.get("email") || "";
    const tokenParam = queryParameters.get("token") || "";
    const contractParam = queryParameters.get("contract") || "";
    const productParam = queryParameters.get("product") || "";

    setContract(contractParam);
    setProduct(productParam);
    setEmail(emailParam);
    setToken(tokenParam);
  }, []);

  useEffect(() => {
    if (email !== "" && token !== "" && contract !== "" && product !== "") {
      onRequestLogin();
    }
  }, [email, token, contract, product]);

  const handleLoginError = (error: RESTError) => {
    switch (error.constructor) {
      case UserNotFoundError: {
        Message.error(t(texts.login.errorUserNotFound));
        return true;
      }
      case TokenExpiredError: {
        Message.error(t(texts.login.errorTokenExpired));
        return true;
      }
      case InvalidAccessCodeError: {
        Message.error(t(texts.login.errorInvalidAccessCode));
        return true;
      }
      case MaxLoginFailError: {
        Message.error(t(texts.login.errorMaxLoginFails, { email: email }));
        return true;
      }
      default:
        return false;
    }
  };

  const onRequestAccessCode = async () => {
    setRequestAccessCode(true);
    request.send(
      async () => {
        const sent = await UserApi.requestAccessCode(email);
        if (toBoolean(sent)) {
          Message.info(t(texts.login.sendAccessCodeMessage));
        } else {
          Message.info(t(texts.login.sendAccessCodeWaitMessage));
        }
        setRequestAccessCode(false);
      },
      (error) => {
        setRequestAccessCode(false);
        return handleRequestAccessCodeError(error);
      }
    );
  };

  const onRequestLogin = async () => {
    setRequestLogin(true);
    request.send(
      async () => {
        const session = await UserApi.login(email, token);
        context.session.setValue(session);
        contract && product
          ? props.onLogin?.(contract, product)
          : props.onLogin?.();
        setRequestLogin(false);
      },
      (error) => {
        setRequestLogin(false);
        return handleLoginError(error);
      }
    );
  };

  const loginButtonEnabled =
    token.length > 0 && token !== undefined && isEmailValid(email);

  const onRequestAccessCodeKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key !== "Enter" || !isEmailValid(email)) {
      return;
    }
    onRequestAccessCode();
  };

  const onSendAccessCodeKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key !== "Enter" || !loginButtonEnabled) {
      return;
    }
    onRequestLogin();
  };

  return (
    <div className={styles.screenCenter}>
      <Section
        title={t(texts.general.swpoTitle)}
        subtitle={t(texts.login.authenticationRequired)}
        icon={<Icon icon={"lock"} />}
      >
        <SectionCard>
          <FormGroup
            helperText={t(texts.login.hint)}
            label={t(texts.login.labelEmail)}
            labelFor="email"
          >
            <InputGroup
              id="email"
              leftIcon={<Icon icon="envelope" />}
              placeholder={t(texts.login.placeholderEmail)}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onKeyUp={onRequestAccessCodeKeyUp}
            />
          </FormGroup>

          <LoadingSpinnerButton
            className={styles.button}
            disabled={!isEmailValid(email)}
            icon={<Icon icon="telegram-logo" />}
            intent={Intent.PRIMARY}
            onClick={onRequestAccessCode}
            showSpinner={requestAccessCode}
            text={t(texts.login.sendAccessCode)}
            fill={true}
          />

          <br />
          <FormGroup label={t(texts.login.labelToken)} labelFor="token">
            <InputGroup
              id="token"
              leftIcon={<Icon icon="key" />}
              onChange={(event) => setToken(event.target.value)}
              onKeyUp={onSendAccessCodeKeyUp}
              placeholder={t(texts.login.placeholderToken)}
              type="password"
              value={token}
            />
          </FormGroup>

          <LoadingSpinnerButton
            className={styles.button}
            disabled={!loginButtonEnabled}
            icon={<Icon icon="sign-in" />}
            intent={Intent.PRIMARY}
            onClick={onRequestLogin}
            showSpinner={requestLogin}
            text={t(texts.general.login)}
            fill={true}
          />
        </SectionCard>
      </Section>
    </div>
  );
};
