import { IFile } from "../../shared/model/IFile";
import { IFileService } from "./IFileService";

class FileServiceDefault implements IFileService {
  download(file: IFile) {
    //add element to download with
    const fileUrl = this.getSrc(file);
    const link = document.createElement("a");
    link.href = fileUrl;

    //set filename
    if (file.filename) {
      link.download = file.filename;
    }

    //download
    link.click();
    window.URL.revokeObjectURL(fileUrl);
  }
  getSrc(file: IFile): string {
    return window.URL.createObjectURL(file.blob);
  }
}
export const FileService = new FileServiceDefault();
