import { IContractSearchResult } from "./IContractSearchResult";
import { ProductIdMap } from "../types/ProductIdMap";

export class ContractSearchResult implements IContractSearchResult {
  private registry: Map<string, ProductIdMap> = new Map();

  add(contractId: string, productId: string): void {
    const entry = this.registry.get(contractId) ?? this.register(contractId);
    entry.set(productId, productId);
  }

  get(contractId: string): ProductIdMap | undefined {
    return this.registry.get(contractId);
  }

  private register(contractId: string) {
    this.registry.set(contractId, new Map());
    return this.registry.get(contractId)!;
  }

  getAllContracts(): Map<string, ProductIdMap> | undefined {
    return this.registry;
  } 
}
