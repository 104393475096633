import { Button } from "@snpjs/core";
import { Carousel } from "../../../components/carousel/carousel/Carousel";
import { LandingPageInfo } from "../landingPageInfo/LandingPageInfo";
import styles from "./LandingPageCarousel.module.scss";
import { texts } from "../../../i18n/texts";
import { useLogin } from "../../../hooks/useLogin";
import useTranslation from "../../../hooks/useTranslation";

export const LandingPageCarousel: React.FC = () => {
  const login = useLogin();
  const { t } = useTranslation();

  return (
    <Carousel width={"40rem"} height={"17rem"}>
      <LandingPageInfo
        title={t(texts.landingPage.carousel.yourOldData.title)}
        icon={"clock-counter-clockwise"}
        subTitle={t(texts.landingPage.carousel.yourOldData.subTitle)}
        content={t(texts.landingPage.carousel.yourOldData.content)}
      />

      <LandingPageInfo
        title={t(texts.landingPage.carousel.contractsAndProducts.title)}
        icon={"file-seal"}
        subTitle={t(texts.landingPage.carousel.contractsAndProducts.subTitle)}
        content={t(texts.landingPage.carousel.contractsAndProducts.content)}
      />

      <LandingPageInfo
        title={t(texts.landingPage.carousel.systemLandscape.title)}
        icon={"external"}
        subTitle={t(texts.landingPage.carousel.systemLandscape.subTitle)}
        content={t(texts.landingPage.carousel.systemLandscape.content)}
      />

      <LandingPageInfo
        title={t(texts.landingPage.carousel.downloadAndLicenses.title)}
        icon={"download-simple"}
        subTitle={t(texts.landingPage.carousel.downloadAndLicenses.subTitle)}
        content={t(texts.landingPage.carousel.downloadAndLicenses.content)}
      />

      <LandingPageInfo
        title={t(texts.landingPage.carousel.conflictManagement.title)}
        icon={"file-torn-cross"}
        subTitle={t(texts.landingPage.carousel.conflictManagement.subTitle)}
        content={t(texts.landingPage.carousel.conflictManagement.content)}
      />

      <LandingPageInfo
        title={t(texts.landingPage.carousel.invitingPeople.title)}
        icon={"users-three"}
        subTitle={t(texts.landingPage.carousel.invitingPeople.subTitle)}
        content={t(texts.landingPage.carousel.invitingPeople.content)}
      />
      <LandingPageInfo
        title={t(texts.landingPage.carousel.LoginToContinue.title)}
        icon={"user"}
        content={
          <div className={styles.login}>
            {t(texts.landingPage.carousel.LoginToContinue.content)}
            <div>
              <Button onClick={login}>{t(texts.landingPage.goToLogin)}</Button>
            </div>
          </div>
        }
      />
    </Carousel>
  );
};
