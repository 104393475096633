import { SpinnerSize, Spinner as SpinnerSnpJs } from "@snpjs/core";

import { ISpinnerProps } from "./ISpinnerProps";

export const Spinner: React.FC<ISpinnerProps> = (props) => (
  <SpinnerSnpJs
    role="spinbutton"
    size={props.size ?? SpinnerSize.SMALL}
    className={props.className}
  />
);
