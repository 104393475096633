import { Icon, IconSize } from "@snpjs/core";

import { ISolutionEffectProps } from "./ISolutionEffectProps";
import styles from "./SolutionEffect.module.scss";
import { texts } from "../../../i18n/texts";
import useTranslation from "../../../hooks/useTranslation";

export const SolutionEffect: React.FC<ISolutionEffectProps> = (props) => {
  const { t } = useTranslation();

  const proceedMessage =
    props.proceedMessage ?? t(texts.conflictWizard.solutions.okMessage);

  return (
    <>
      {props.solution && (
        <div>
          <div className={styles.title}>
            {props.solution?.isExpertSolution && (
              <Icon
                icon="warning-diamond"
                intent="danger"
                size={IconSize.LARGE}
              />
            )}
            {props.solution?.isExpertSolution && (
              <h1 className={styles.expertMode}>{`${t(
                texts.conflictWizard.solutions.expertMode
              )}: `}</h1>
            )}
            <h1>{t(props.solution.title)}</h1>
          </div>
          <div>{t(props.solution?.effect)}</div>
          <br />
          <div>{proceedMessage}</div>
        </div>
      )}
    </>
  );
};
