import { IEditIconProps } from "./IEditIconProps";
import { Icon } from "@snpjs/core";
import { style } from "../../utils/style";
import styles from "./EditIcon.module.scss";

export const EditIcon: React.FC<IEditIconProps> = (props) => {
  return (
    <Icon {...props} className={style(styles.editIcon, props.className)} />
  );
};
