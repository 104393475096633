import { IVersion } from "../../shared/types/IVersion";

/**
 * Flattens the given {@link version}.
 *
 * @example
 * 24.03 01 07 -> 24030107
 */
export const flattenVersion = (version: IVersion) => {
  return `${version.RELEASE}${version.SERVICE_PACK}${version.HOTFIX}`;
};
