/**
 * Color.
 */
export enum Color {
  /**
   * Danger.
   */
  DANGER = "red",
  /**
   * Primary.
   */
  PRIMARY = "blue",
  /**
   * Success.
   */
  SUCCESS = "green",
  /**
   * Warning.
   */
  WARNING = "orange",
  /**
   * SNP Blueshift.
   */
  SNP_BLUE = "#181E72",
}
