import { fetchBackup } from "../../App";

/**
 * Returns if the current request is an update request to trigger changes
 */
const isUpdateRequest = (path: string) => {
  return path.match(/main.*update.json/);
};

/**
 * This function is a mock for global function fetch, which can be used to simulate the backend
 */
export const fetchMock = async (
  input: RequestInfo | URL,
  requestInit?: RequestInit | undefined
): Promise<Response> => {
  const path = input.toString();
  if (isUpdateRequest(path)) {
    return fetchBackup(input, requestInit);
  }

  // Lazy load MockRouter
  const MockRouter = await import("./MockRouter");
  return MockRouter.MockRouter.handle(path, requestInit);
};
