import { Intent } from "@snpjs/core";
import { MessageType } from "./MessageType";
import { OverlayToaster } from "@snpjs/core";
import { Position } from "@snpjs/core";
import { Toaster } from "@snpjs/core";

import { style } from "../utils/style";

import styles from "./Message.module.scss";

class MessageDefault {
  private overlayToaster: Toaster | undefined = undefined;

  private async createToaster(): Promise<Toaster> {
    if (!this.overlayToaster) {
      this.overlayToaster = await OverlayToaster.createAsync({
        className: style(styles.overlayToaster, styles.extraClass),
        position: Position.TOP_RIGHT,
      });
    }
    return this.overlayToaster;
  }

  error(message: MessageType) {
    this.show(message, Intent.DANGER);
  }

  info(message: MessageType) {
    this.show(message, Intent.PRIMARY);
  }

  success(message: MessageType) {
    this.show(message, Intent.SUCCESS);
  }

  warning(message: MessageType) {
    this.show(message, Intent.WARNING);
  }

  async show(message: MessageType, intent: Intent) {
    const toaster = await this.createToaster();
    toaster.show({
      message,
      intent: intent,
      timeout: 60000,
    });
  }
}

/**
 * This service is responsible for displaying messages.
 */
export const Message = new MessageDefault();
