import { AppContext } from "../context/AppContext";
import { Routes } from "../routes/Routes";
import { UserApi } from "../api/UserApi";

import { useContext } from "react";
import { useHandleUnexpectedError } from "./useHandleUnexpectedError";
import { useNavigate } from "react-router-dom";

export let requestLogout = false;

/**
 * This custom hook is responsible for logging out the current user and navigating to the login page.
 */
export const useLogout = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const unexpectedErrorHandler = useHandleUnexpectedError();

  const logout = async () => {
    if (context.session.value) {
      try {
        requestLogout = true;
        await UserApi.logout();
        context.session.setValue(undefined);
        requestLogout = false;
      } catch (error) {
        unexpectedErrorHandler.handle(error);
      }
    }
    navigate(Routes.login.toPath());
  };

  return logout;
};
