import { PublicPage } from "../components/pages/publicPage/PublicPage";
import React from "react";
import styles from "./ErrorPage.module.scss";

/**
 * This page is responsible for displaying unexpected errors, which might occur.
 */
export const ErrorPage: React.FC = () => {
  return (
    <PublicPage className={styles.errorPage} iconName="bug">
      <section>
        <h1>Sorry, something went wrong :(</h1>
      </section>

      <section>
        Sometimes even for us things don&apos;t work as expected.
      </section>

      <section>Please refresh page or check the URL.</section>
    </PublicPage>
  );
};
