import { useCallback, useEffect, useState } from "react"; 

import { AsyncLoad } from "../../../components/asyncLoad/AsyncLoad";
import { IDownloadVersionProps } from "./IDownloadVersionProps";
import { IRelease } from "../../../shared/model/IRelease";
import { ProductApi } from "../../../api/ProductApi";
import { SupportTicketInfo } from "../../supportTicketInfo/SupportTicketInfo";
import { SystemActionLog } from "../systemActionLog/SystemActionLog";
import { ValidationError } from "../../../errors/ValidationError";
import { ValidationHandler } from "../../../types/ValidationHandler";
import { VersionSelection } from "../../versionSelection/VersionSelection";
import styles from "./DownloadVersion.module.scss";
import { texts } from "../../../i18n/texts";
import { useRequest } from "../../../hooks/useRequest";
import useTranslation from "../../../hooks/useTranslation";
import { useValidatorConsumer } from "../../../hooks/useValidatorConsumer";
import { useValidatorProvider } from "../../../hooks/useValidatorProvider";

/**
 * This component is responsible for displaying release, service pack and hotfix to set an alternative download version.
 * And addition the user has to provide add a reason for changing the version.
 */
export const DownloadVersion: React.FC<IDownloadVersionProps> = (props) => {
  const request = useRequest();
  const [releases, setReleases] = useState<IRelease[]>([]);
  const { t } = useTranslation();
  const versionValidator = useValidatorConsumer();
  const actionLogValidator = useValidatorConsumer();

  const onValidate: ValidationHandler = useCallback(() => {
    // Call both validators for printing error messages
    const versionValid = versionValidator.isValid();
    const actionLogValid = actionLogValidator.isValid();
    if (!versionValid || !actionLogValid) {
      throw new ValidationError();
    }
  }, [actionLogValidator, versionValidator]);

  const validator = useValidatorProvider(onValidate);  
  useEffect(() => {
    props.requestValidator?.(validator);
  }, [props.requestValidator, validator])

  return (
    <AsyncLoad
      load={async () => {
        await request.send(async () => {
          if (props.isVersionChangeable === false) {
            return;
          }

          let releases = await ProductApi.findReleases(props.productId);
          releases = props.filterReleases?.(releases) ?? releases;
          setReleases(releases);
        });
      }}
    >
      <div className={styles.downloadVersion}>
        <div>{t(texts.systemDownloadVersion.subTitle)}</div>
        {props.subTextTranslation && <div>{t(props.subTextTranslation)}</div>}
        <VersionSelection
          {...props}
          displayMode={props.isVersionChangeable !== undefined ? !props.isVersionChangeable : false}
          displayHotfix={true}
          releases={releases}
          requestValidator={versionValidator.onRequestValidator}
          onReleaseSelected={props.onReleaseSelected}
        />
        <div>
          {props.isOthersReleaseSelected ? (
            <SupportTicketInfo />
          ) : (
            <SystemActionLog
              isReasonRequired={true}
              label={t(texts.systemDownloadVersion.labelTextArea)}
              requestValidator={actionLogValidator.onRequestValidator}
              setText={props.setReason}
              text={props.reason}
            />
          )}
        </div>
      </div>
    </AsyncLoad>
  );
};
