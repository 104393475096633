import { AppContext } from "../context/AppContext";
import { IUserProfile } from "../model/IUserProfile";
import { IValue } from "../types/IValue";

import { useContext } from "react";

export const useUserProfile = (): IValue<IUserProfile> => {
  const context = useContext(AppContext);
  return context.userProfile;
};
