import { Intent, Tag } from "@snpjs/core";

import { Environment } from "../../shared/types/Environment";
import { texts } from "../../i18n/texts";
import { useConfig } from "../../hooks/useConfig";
import useTranslation from "../../hooks/useTranslation";

export const EnvironmentTag: React.FC = () => {
  const config = useConfig();
  const { t } = useTranslation();

  var intent;
  var environmentText;
  switch (config.SYSTEM_ENVIRONMENT) {
    case Environment.DEV: {
      intent = Intent.SUCCESS;
      environmentText = t(texts.general.development);
      break;
    }
    case Environment.TEST: {
      intent = Intent.WARNING;
      environmentText = t(texts.general.test);
      break;
    }
  }

  if (intent === undefined) {
    return <></>;
  }
  return (
    <Tag intent={intent} minimal={false} round={true}>
      {environmentText}
    </Tag>
  );
};
