import { ISystemHeaderFieldsProps } from "./ISystemHeaderFieldsProps";
import { Intent } from "@snpjs/core";
import { LabeledInput } from "../../../components/labeledInput/LabeledInput";
import styles from "./SystemHeaderFields.module.scss";
import { texts } from "../../../i18n/texts";
import { useSystemHeaderFieldsViewModel } from "./useSystemHeaderFieldsViewModel";
import useTranslation from "../../../hooks/useTranslation";

export const SystemHeaderFields: React.FC<ISystemHeaderFieldsProps> = (
  props
) => {
  const { t } = useTranslation();

  const intent = (errorMessage?: string): Intent => {
    if (viewModel.editMode) {
      return errorMessage ? "danger" : "primary";
    } else {
      return "none";
    }
  };

  const viewModel = useSystemHeaderFieldsViewModel(props);

  return (
    <form
      className={styles.systemHeaderFields}
      onSubmit={(event) => event.preventDefault()}
    >
      <LabeledInput
        autoFocus={true}
        errorMessage={viewModel.inputFieldErrorSid}
        intent={intent(viewModel.inputFieldErrorSid)}
        label={t(texts.systemHeaderFields.sid)}
        large={true}
        maxLength={3}
        onChange={(event) => viewModel.setSid?.(event.target.value)}
        readOnly={!viewModel.editMode}
        value={viewModel.systemId}
      />
      <LabeledInput
        errorMessage={viewModel.inputFieldErrorClient}
        intent={intent(viewModel.inputFieldErrorClient)}
        label={t(texts.systemHeaderFields.client)}
        large={true}
        maxLength={3}
        onChange={(event) => viewModel.setClient?.(event.target.value)}
        readOnly={!viewModel.editMode}
        value={viewModel.client}
      />
      <LabeledInput
        errorMessage={viewModel.inputFieldErrorInstallNumber}
        intent={intent(viewModel.inputFieldErrorInstallNumber)}
        label={t(texts.systemHeaderFields.installNumber)}
        large={true}
        maxLength={10}
        onChange={(event) => {
          const value = event.target.value.replace(/[^0-9]/gi, "");
          viewModel.setInstallNumber?.(value as any as string);
        }}
        readOnly={!viewModel.editMode}
        value={String(viewModel.installNumber)}
      />
      <LabeledInput
        errorMessage={viewModel.inputFieldErrorDescription}
        intent={intent(viewModel.inputFieldErrorDescription)}
        label={t(texts.systemHeaderFields.description)}
        large={true}
        maxLength={60}
        onChange={(event) => viewModel.setDescription?.(event.target.value)}
        readOnly={!viewModel.editMode}
        value={viewModel.description}
      />
    </form>
  );
};
