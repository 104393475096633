import { IValidator } from "../types/IValidator";

import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";

/**
 * This custom hook is responsible for providing functions for validation.
 * The corresponding validator is injected via function {@link onRequestValidator}.
 */
export const useValidatorConsumer = () => {
  const [validator, setValidator] = useState<IValidator | undefined>(undefined);

  /**
   * Provides a function to inject a validator
   */
  const onRequestValidator = useCallback((validator: IValidator) => {
    setValidator(validator);
  }, []);

  /**
   * Runs the validator which was provided by {@link onRequestValidator}.
   * Throws an error if the validation fails.
   */
  const validate = useCallback(() => {
    validator?.validate();
  }, [validator]);

  /**
   * Runs the validator which was provided by {@link onRequestValidator}.
   * Returns false if the validation fails otherwise true.
   */
  const isValid = useCallback((): boolean => {
    try {
      validate();
      return true;
    } catch (error) {
      return false;
    }
  }, [validate]);

  const validatorConsumer = useMemo(
    () => ({
      isValid,
      onRequestValidator,
      validate,
    }),
    [isValid, onRequestValidator, validate]
  );

  return validatorConsumer;
};
