import { Button, Icon, Menu, MenuItem, Popover, Tooltip } from "@snpjs/core";

import { ConfirmDialog } from "../confirmDialog/ConfirmDialog";
import { IEditableCardProps } from "./IEditableCardProps";
import { ITranslation } from "../../types/ITranslation";
import { LoadingSpinnerButton } from "../loadingSpinnerButton/LoadingSpinnerButton";
import { style } from "../../utils/style";
import styles from "./EditableCard.module.scss";
import { texts } from "../../i18n/texts";
import { useEditableCardViewModel } from "./useEditableCardViewModel";
import useTranslation from "../../hooks/useTranslation";

/**
 * This component is responsible for displaying a card an its content of type {@link T} which is editable.
 * The card provides actions to switch the view mode and buttons for confirming or withdrawing the changes.
 */
export function EditableCard<T extends object>(props: IEditableCardProps<T>) {
  const viewModel = useEditableCardViewModel(props);
  const { t } = useTranslation();

  const toText = (
    fallback: string,
    text?: ((object: T) => ITranslation) | string
  ) => {
    if (!text) {
      return t(fallback);
    }

    if (typeof text === "string") {
      return t(text);
    }

    return t(text(props.initialObject));
  };

  const menu = (
    <Menu>
      <Tooltip
        content={
          props.deleteTooltip
            ? props.deleteTooltip
            : t(texts.editableCard.deleteTitle)
        }
      >
        <MenuItem
          disabled={!viewModel.canDelete()}
          text={t(texts.editableCard.delete)}
          icon={<Icon icon={"trash"} intent="danger" />}
          onClick={viewModel.onDelete}
        />
      </Tooltip>
      {props.actionsChangeMode && <>{props.actionsChangeMode}</>}
    </Menu>
  );

  return (
    <div className={style(styles.editableCard, props.className)}>
      <>{props.children}</>
      {viewModel.showConfirm && (
        <ConfirmDialog
          isOpen={viewModel.showConfirm}
          onCancel={viewModel.onCancelDelete}
          onOkay={viewModel.onConfirmDelete}
          text={toText(texts.editableCard.deleteText, props.deleteText)}
          title={toText(texts.editableCard.deleteTitle, props.deleteTitle)}
        />
      )}

      {!props.hideActions && (
        <>
          {props.editMode ? (
            <div className={styles.actions}>
              <Tooltip content={t(texts.editableCard.tooltipConfirm)}>
                <LoadingSpinnerButton
                  icon={<Icon icon="check" />}
                  outlined
                  onClick={viewModel.onSave}
                  showSpinner={viewModel.saving}
                  spinnerClassName={styles.spinner}
                  buttonClassNames={style(
                    styles.confirmButton,
                    styles.extraClass
                  )}
                />
              </Tooltip>
              <Tooltip content={t(texts.editableCard.tooltipCancel)}>
                <Button
                  icon={<Icon icon="x" />}
                  outlined
                  onClick={viewModel.onRestore}
                  className={style(styles.cancelButton, styles.extraClass)}
                />
              </Tooltip>
              {props.onDelete && (
                <Tooltip content={t(texts.editableCard.tooltipMore)}>
                  <Popover content={menu} placement="bottom-start">
                    <Button
                      icon={<Icon icon="dots-three-outline" />}
                      outlined
                    />
                  </Popover>
                </Tooltip>
              )}
            </div>
          ) : (
            <div className={styles.actions}>
              <Tooltip
                content={
                  (viewModel.canEdit() && viewModel.isEditable())
                    ? t(texts.editableCard.tooltipEdit)
                    : !viewModel.isEditable() ?
                      t(texts.editableCard.tooltipUserCannotBeEdited)
                      : t(texts.editableCard.tooltipNotAuthorizedToEdit)
                }
              >
                <Button
                  disabled={!viewModel.isEditable() || !viewModel.canEdit()}
                  icon={<Icon icon="pencil" />}
                  outlined
                  onClick={() => props.setEditMode(true)}
                />
              </Tooltip>
              {props.onCopy && (
                <Tooltip content={t(texts.editableCard.tooltipCopy)}>
                  <Button
                    icon={<Icon icon="copy" />}
                    outlined
                    onClick={viewModel.onCopy}
                  />
                </Tooltip>
              )}
              {props.actionsDisplayMode && props.actionsDisplayMode}
            </div>
          )}
        </>
      )}
    </div>
  );
}
