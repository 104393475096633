import { ILabeledElementProps } from "./ILabeledElementProps";
import { Icon } from "@snpjs/core";
import { style } from "../../utils/style";
import styles from "./LabeledElement.module.scss";
import { useId } from "react";

/**
 * This generic component is responsible for displaying an element with label.
 */
export const LabeledElement: React.FC<ILabeledElementProps> = (props) => {
  const selectId = useId();

  return (
    <div className={style(styles.labeledElement, props.className)}>
      <div className={styles.header}>
        <label htmlFor={selectId}>{props.label}</label>
        {props.placeholder && (
          <div className={styles.placeholder}>{props.placeholder}</div>
        )}
      </div>
      <div>{props.children}</div>
      {props.errorMessage && (
        <div className={styles.errorMessage}>
          <Icon icon="warning" />
          {props.errorMessage}
        </div>
      )}
    </div>
  );
};
