import { CSSProperties } from "react";
import { IListProps } from "./IListProps";
import styles from "./List.module.scss";

/**
 * This component represents a various kind of list to display e.g. cards as elements.
 */
export const List: React.FC<IListProps> = (props) => {
  const style = {
    "--numberOfColumns": props.numberOfColumns ?? 1,
  } as CSSProperties;

  return (
    <div className={styles.list} style={style}>
      {props.children}
    </div>
  );
};
