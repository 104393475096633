import { ContractCard } from "../contract/ContractCard";
import { ContractSearch } from "../contractSearch/contractSearch/ContractSearch";
import { IContractListProps } from "./IContractListProps";
import { UnavailableMessage } from "../../../components/unavailableMessage/UnavailableMessage";
import styles from "./ContractList.module.scss";
import { texts } from "../../../i18n/texts";
import { useContractListViewModel } from "./useContractListViewModel";
import useTranslation from "../../../hooks/useTranslation";

/**
 * This component is responsible for displaying contracts
 */
export const ContractList: React.FC<IContractListProps> = (props) => {
  const { t } = useTranslation();
  const viewModel = useContractListViewModel(props);

  const items = viewModel.contracts?.map((contract) => (
    <ContractCard
      singleView={false}
      key={contract.OBJECT_ID}
      contract={contract}
      hideContractLink={false}
      highlightedProductIds={viewModel.contractSearchResult?.get(
        contract.OBJECT_ID
      )}
    />
  ));

  const content = () => {
    if (items && items.length > 0) {
      return items;
    } else {
      return (
        <UnavailableMessage
          icon="file-seal"
          title={t(texts.contracts.infoNoContractsFound)}
          description={t(texts.contracts.infoNoContracts)}
        />
      );
    }
  };

  return (
    <div className={styles.contractList}>
      {viewModel.needsDisplayContractSearch && (
        <div className={styles.search}>
          <ContractSearch
            contracts={props.contracts}
            onSearchCompleted={viewModel.onSearchCompleted}
          />
        </div>
      )}
      <div className={styles.section}>
        <div className={styles.list}>{content()}</div>
      </div>
    </div>
  );
};
