import { ContractBasedPage } from "../features/contract/contractBasedPage/ContractBasedPage";
import { InstallGuide } from "../features/installGuide/InstallGuide";
import { useRouteParam } from "../hooks/useRouteParam";

export const InstallGuidePage: React.FC = () => {
  const systemObjectId = useRouteParam<string>("systemId");

  return (
    <ContractBasedPage iconName={"book-info"} showBreadcrumbs>
      <InstallGuide systemObjectId={systemObjectId} />
    </ContractBasedPage>
  );
};
