import { IBreadcrumbNameLoader } from "./IBreadcrumbNameLoader";

/**
 * An instance of this class is responsible for providing a readable format for a breadcrumb
 * e.g. by mapping an id to a description.
 */
export abstract class BreadcrumbNameLoader implements IBreadcrumbNameLoader {
  constructor(public id?: string) {}
  abstract name: Promise<string> | string;
}
