import { BreadcrumbLoader } from "../../../../decorators/BreadcrumbLoader";
import { BreadcrumbNameLoader } from "../../../../services/breadcrumbs/breadcrumbLoaders/breadcrumbNameLoaders/BreadcrumbNameLoader";
import { Routes } from "../../../../routes/Routes";

import { texts } from "../../../../i18n/texts";

/**
 * This class is responsible for providing a name for the user roles breadcrumb.
 */
@BreadcrumbLoader(Routes.excerptUserAuthRoles.toPath())
export class UserRolesBreadcrumbNameLoader extends BreadcrumbNameLoader {
  name = texts.userManagement.breadcrumb;
}
