import { AsyncLoad } from "../components/asyncLoad/AsyncLoad";
import { ContractList } from "../features/contract/contractList/ContractList";
import { IContract } from "../shared/model/IContract";
import { ProtectedPage } from "../components/pages/protectedPage/ProtectedPage";
import { UserApi } from "../api/UserApi";
import { error } from "../utils/error/error";
import { texts } from "../i18n/texts";
import { useRequest } from "../hooks/useRequest";
import { useSession } from "../hooks/useSession";
import { useState } from "react";
import useTranslation from "../hooks/useTranslation";

/**
 * This page is responsible for showing all contracts of a user.
 */
export const ContractDashboardPage: React.FC = () => {
  const [contracts, setContracts] = useState<IContract[]>([]);
  const { t } = useTranslation();
  const session = useSession();
  const request = useRequest();

  const infoArea = (
    <>
      <br />
      <br />
      <p>{t(texts.contracts.infoArea.welcome)}</p>
      <p>{t(texts.contracts.infoArea.infoMessage)}</p>
    </>
  );

  return (
    <ProtectedPage showBreadcrumbs iconName="folder-open" infoArea={infoArea}>
      <AsyncLoad
        positionCentered
        load={async () => {
          await request.send(async () => {
            const contracts = await UserApi.getContracts(
              session ??
                error(
                  "Error while getting user contracts. Session is undefined."
                )
            );
            setContracts(contracts);
          });
        }}
      >
        <ContractList contracts={contracts} />
      </AsyncLoad>
    </ProtectedPage>
  );
};
