import { IMultiSelectionProps } from "./IMultiSelectionProps";
import { MultiSelectionButton } from "../multiSelectionButton/MultiSelectionButton";

/**
 * This component is responsible for displaying a selection of options,
 * which can be activated or deactivated from the user.
 */
export function MultiSelection<T>(props: IMultiSelectionProps<T>) {
  const items = props.objects
    .filter((object) => {
      return props.editMode || props.isSelected(object)})
    .map((object) => {
      const name = props.getName(object);
      const isImmutable = props.immutableObjects? props.immutableObjects.includes(object) : false;
      const tooltip = props.getTooltip?.(object);
      return (
        <MultiSelectionButton
          isImmutable={isImmutable}
          editMode={props.editMode}
          key={name}
          onActivate={() => props.onActivate?.(object)}
          onDeactivate={() => props.onDeactivate?.(object)}
          isSelected={props.isSelected(object)}
          isDisabled={props.isDisabled?.(object)}
          objectName={name}
          objectTooltip={tooltip}
        />
      );
    });

  return <>{items}</>;
}
